import { useQuery } from "@tanstack/react-query";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import apiClient from "@/modules/request.module.v2";

export default (ids = []) => {
	const companyId = useCompanyIdFromUrl();

	const query = useQuery({
		queryKey: ["shareholdersByIds", { companyId, ids }],
		queryFn: () => {
			return apiClient.post(
				"/shares/investments/array",
				{ ids },
				{
					headers: { "x-company-id": companyId },
				},
			);
		},
	});

	return query;
};
