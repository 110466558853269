import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";
import useNotifications from "@/hooks/useNotifications";

export default (companyId) => {
	const queryClient = useQueryClient();

	const { notify } = useNotifications();

	return useMutation({
		mutationKey: ["subscriptionRefillPrepaid", companyId],
		mutationFn: () => {
			return apiClient.post(`subscriptions/refill-prepaid`, undefined, {
				params: {
					companyId,
				},
			});
		},
		onSettled: (mutationResponse) => {
			queryClient.setQueryData(["subscription", companyId], mutationResponse);
			queryClient.invalidateQueries(["subscriptionHelper", companyId]);
		},
		onError: () => {
			notify(
				"error",
				"subscriptions.prepaid.title",
				"notifications.title.ERROR",
			);
		},
	});
};
