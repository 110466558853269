import React from "react";
import Icon from "../../icon/icon";

const UnselectableRenderer = ({ icon, iconSize, iconColor }) => {
	return <Icon icon={icon} color={iconColor} size={iconSize} />;
};

UnselectableRenderer.defaultProps = {
	iconColor: "orange",
};

export { UnselectableRenderer };
