import React, { PureComponent } from "react";
import { func } from "prop-types";
import DarkInfoPanel from "../../shared/dark-info-panel/dark-info-panel";

export default class ViaGuiPanel extends PureComponent {
	static propTypes = {
		headerLeftComponent: func,
		footerComponent: func,
	};

	render = () => {
		const { headerLeftComponent, footerComponent } = this.props;

		return (
			<>
				<DarkInfoPanel
					headerLeftComponent={headerLeftComponent()}
					footerComponent={footerComponent()}
				/>
			</>
		);
	};
}
