import React, { useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Copy from "copy-to-clipboard";

import InvestorsList from "./investors/investors-list";
import Text from "../../../dumb-components/shared/text/text";

import ShareholdersToolbarControlsContainer from "@/containers/shares/shareholders-toolbar-controls.container";
import ShareholdersAdvancedFilterContainer from "@/containers/shares/shareholders-advanced-filter.container";
import ShareholdersAdvancedFilterBtnContainer from "@/containers/shares/shareholders-advanced-filter-btn.container";
import ShareholdersFilterAndSortContainer from "@/containers/shares/shareholders-filter-and-sort.container";
import ShareholderAddShareholderBtnContainer from "@/containers/shares/shareholders-add-shareholder-btn.container";
import RemindShareholderAboutRegistrationDropdownItem from "@/containers/shares/remind-shareholder-about-registration-item.container";

import { getShareholderStatus } from "@/components/helpers/shares";

import InvestorsDetails from "./investors/investors-details";
import Alert from "@/components/alert";
import { List } from "immutable";
import { openModal } from "@/actions/modals.actions";
import { generateExcelFromTemplate } from "@/actions/shares.actions";
import { importShareholdersFromExcel } from "@/actions/investors.actions";
import {
	COMPANY_INVESTMENTS_LIMITATIONS_MODAL,
	MODALS_SHARES_ERROR_MODAL,
} from "../../../constants/modals";
import { ShareholderLimitationInfoModal } from "../../../containers/shares/modals/shareholder-limitation-info-modal";
import useSubscriptionHelper from "@/hooks/subscriptions/useSubscriptionHelper";
import DropdownMenuContainer from "@/containers/shared/dropdown-menu.container";
import DropdownIconItem from "@/dumb-components/shared/dropdown-item/dropdown-icon-item";
import Divider from "@/dumb-components/shared/divider/divider";
import Tooltip from "@/dumb-components/shared/tooltip/tooltip";
import Dropzone from "@/dumb-components/shared/dropzone/dropzone";

import investmentApi from "/shared/helpers/investment.helper";

import req from "@/modules/request.module";
import { validateEmail } from "@/modules/validation.module";

import ShareholderTemplateErrorContainer from "@/containers/shares/shareholder-template-error.container";
import useRestrictAccess from "@/hooks/subscriptions/useRestrictAccess";
import { Trans, useTranslation } from "react-i18next";
import useDialogModal from "@/hooks/useDialogModal";
import useMe from "@/hooks/users/useMe";
import { SHARES_POSSIBLE_INVESTOR_STATUSES } from "@/constants/shares";
import { Clipboard, Lock } from "lucide-react";
import { Button } from "@/components/ui/button";
import Breadcrumbs from "../../breadcrumbs";
import ExportOwnerReportModal from "../../shares/exportOwnerReportModal";

const TEXT_COMPONENT_FILTERED_BOLD = {
	filtered: (
		<Text tid="shareholders.toolbar.generic.helper.filtered" bold={600} />
	),
};

const PRINT_OWNER_REPORT_TOOLTIP_STATES = {
	default: {
		tid: "shares.shareholders.print_owner_report.tooltip.default",
	},
	sharebookNotInitialized: {
		tid: "shares.shareholders.details.toolbar.no_sharebook_initialized",
		delayShow: "instant",
	},
};

const COPY_SHAREHOLDERS_EMAIL_TOOLTIP_STATES = {
	noValidEmails: {
		tid: "tooltip.shares.shareholders.action.email_filtered.no_valid_email",
		delayShow: "instant",
	},
	shareholderLimitReached: {
		tid: "shares.shareholders.details.toolbar.shareholder_limit_reached",
		delayShow: "instant",
	},
};

const DOWNLOAD_TOOLTIP_STATES = {
	noShareholders: {
		tid: "shares.shareholders.details.toolbar.no_visible_shareholders",
		delayShow: "instant",
	},
	companyAccountIsFree: {
		tid: "shares.shareholders.details.toolbar.company_account_is_free",
		delayShow: "instant",
	},
};

/** Class representing the Shares component */
/** Function representing the Shares component */
const Investors = ({
	location,
	selectedInvestor,
	visibleInvestors,
	history,
	numOfInvestors,
	numOfActiveShareholders,
	match,
	sharesInitialized,
	companyAccountIsFree,
}) => {
	const { t } = useTranslation();
	const { restrict, restrictDialogElement } = useRestrictAccess();
	const { confirm, dialogElement } = useDialogModal();

	const [showExportOwnerReportModal, setShowExportOwnerReportModal] =
		useState(false);

	const dispatch = useDispatch();

	const { data: useMeQuery } = useMe();
	const user = useMeQuery?.data;

	const dropzoneRef = useRef(null);
	const downloadBtnRef = useRef(null);
	const sendEmailBtnRef = useRef(null);
	const printShareholdersRef = useRef(null);

	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [prevInvestor, setPrevInvestor] = useState(null);
	const [isGeneratingExcelExport, setIsGeneratingExcelExport] = useState(false);

	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	const maxNumberOfShareholders = subscriptionHelper?.maxNumberOfShareholders;

	const getComponentBasePath = () => {
		const pathNameArray = location.pathname.split("/");

		if (
			pathNameArray[pathNameArray.length - 1] !== "register-of-shareholders"
		) {
			pathNameArray.pop();
		}

		return pathNameArray.join("/");
	};

	const toggleAdvancedFilter = () => {
		const basePath = getComponentBasePath();

		if (prevInvestor) {
			const id = prevInvestor.get("id");

			const prevVisible =
				visibleInvestors &&
				visibleInvestors.find((investor) => {
					return investor.get("id") === id;
				});

			if (prevVisible) {
				history.push(`${basePath}/${prevInvestor.get("id")}`);
			} else {
				history.push(basePath);
			}

			setPrevInvestor(null);
		} else {
			history.push(basePath);
			setPrevInvestor(selectedInvestor);
		}

		setFilterIsOpen(!filterIsOpen);
	};

	const closeAdvancedFilter = () => {
		setFilterIsOpen(false);
		setPrevInvestor(null);
	};

	const openShareholderLimitationInfoModal = () => {
		dispatch(openModal(COMPANY_INVESTMENTS_LIMITATIONS_MODAL));
	};

	const renderButtons = () => {
		return (
			<div className="flex">
				<ShareholdersToolbarControlsContainer />
			</div>
		);
	};

	const getMailToVisibleInvestorsData = () => {
		if (!visibleInvestors || !user) {
			return null;
		}

		// Get email of visible shareholders
		const investors = visibleInvestors.map((investor) => {
			const inv = investor.toJS();
			return inv && investmentApi.getInvestorEmail(inv);
		});

		// Get only valid emails
		let validInvestorEmails = List();
		investors.forEach((email) => {
			if (validateEmail(email)) {
				validInvestorEmails = validInvestorEmails.push(email);
			}
		});

		// Create list with addresses
		const commaSeparatedEmails = validInvestorEmails.join(",");

		// Disable send email to visible button if there are no investors with emails
		const disabled =
			validInvestorEmails && validInvestorEmails.size > 0 ? false : true;

		const mailToVisibleData = {
			mailto: `mailto:${user.email}?bcc=${commaSeparatedEmails}`,
			emails: validInvestorEmails.join(";"),
			disabled,
		};

		return mailToVisibleData;
	};

	const onCopyEmailShareholders = async () => {
		sendEmailBtnRef?.current.onToggleMenu?.();

		let statuses = new Set();

		for (const investor of visibleInvestors) {
			const shareholderStatus = getShareholderStatus(investor);

			if (!shareholderStatus) {
				continue;
			}

			statuses = statuses.add(shareholderStatus);
			if (statuses.size === SHARES_POSSIBLE_INVESTOR_STATUSES.size) {
				break;
			}
		}
		const isCopyConfirmed = await confirm(
			t("shareholders.copy_email_confirm.modal.title"),
			<div>
				{t("shareholders.copy_email_confirm.modal.info3")}
				<ul className="list-disc pl-12 py-4 my-4 bg-accent rounded">
					{Array.from(statuses).map((status, index) => (
						<li key={index}>
							{t(`shareholders.copy_email_confirm.modal.${status}`)}
						</li>
					))}
				</ul>
				<div>
					<Trans
						i18nKey="shareholders.copy_email_confirm.modal.info1"
						values={{
							copycloseBtn: t(
								"shareholders.copy_email_confirm.modal.info1.btn.copy_and_close",
							),
						}}
					/>
				</div>
			</div>,
			{
				submitText: (
					<>
						<Clipboard size={16} />
						{t(
							"shareholders.copy_email_confirm.modal.info1.btn.copy_and_close",
						)}
					</>
				),
			},
		);

		if (!isCopyConfirmed) return;

		const mailToVisibleShareholdersData = getMailToVisibleInvestorsData();
		const { emails } = mailToVisibleShareholdersData;

		Copy(emails);
	};

	const uploadExcel = ({ preventOpenDropdown }) => {
		downloadBtnRef.current &&
			!preventOpenDropdown &&
			downloadBtnRef?.current.onToggleMenu();
		dropzoneRef.current.open();
	};

	const createExcel = () => {
		if (isGeneratingExcelExport) return;

		const ids = visibleInvestors.map((i) => {
			return i.get("id");
		});

		setIsGeneratingExcelExport(true);

		req
			.post(`/shares/excel/investments/`, { ids: ids?.toJS?.() })
			.then((response) => {
				window.open(
					`https://view.officeapps.live.com/op/view.aspx?src=${window.encodeURIComponent(
						response.data.downloadUrl,
					)}`,
					"_blank",
				);
				setIsGeneratingExcelExport(false);
			})
			.catch((e) => {
				console.error(e);
				setIsGeneratingExcelExport(false);
			});
	};

	const renderDownloadDropdown = () => {
		const noShareholders = !visibleInvestors || visibleInvestors.size === 0;
		let activeState;

		if (noShareholders) {
			activeState = "noShareholders";
		} else if (companyAccountIsFree) {
			activeState = "companyAccountIsFree";
		}

		return (
			<DropdownMenuContainer
				btnIcon="faFileSpreadsheet"
				halignMenu="right"
				tooltipStates={DOWNLOAD_TOOLTIP_STATES}
				tooltipActiveState={activeState}
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
				noMaxWidth
				ref={(ref) => {
					downloadBtnRef.current = ref;
				}}
			>
				{restrict(
					<DropdownIconItem
						title={
							noShareholders
								? t(
										"shares.shareholders.details.toolbar.no_visible_shareholders",
								  )
								: undefined
						}
						icon={isGeneratingExcelExport ? "faSpinner" : "faFileSpreadsheet"}
						iconSpin={isGeneratingExcelExport}
						onClick={createExcel}
						disabled={noShareholders || companyAccountIsFree}
					>
						{t("investors.download_excel")}
					</DropdownIconItem>,
					{
						requiredFeature: "shareholders-export-excel",
						onModalOpen: () => {
							downloadBtnRef.current?.onToggleMenu?.();
						},
					},
				)}
				<Divider />
				<Tooltip states={DOWNLOAD_TOOLTIP_STATES} activeState={activeState}>
					<DropdownIconItem
						tid="investors.toolbar.excel.create_template"
						icon="faArrowAltToBottom"
						onClick={() =>
							dispatch(generateExcelFromTemplate("addShareholders"))
						}
						disabled={companyAccountIsFree}
					/>
				</Tooltip>
				<Tooltip states={DOWNLOAD_TOOLTIP_STATES} activeState={activeState}>
					<DropdownIconItem
						tid="investors.toolbar.excel.upload_template"
						icon="faArrowAltFromBottom"
						onClick={uploadExcel}
						disabled={companyAccountIsFree}
					/>
				</Tooltip>
			</DropdownMenuContainer>
		);
	};

	const renderPrintOwnerReportDropdown = () => {
		const activeState = sharesInitialized
			? undefined
			: "sharebookNotInitialized";

		return (
			<DropdownMenuContainer
				openDirection="down"
				btnIcon="faPrint"
				halignMenu="right"
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
				tooltipStates={PRINT_OWNER_REPORT_TOOLTIP_STATES}
				tooltipActiveState={activeState}
				disabled={!!activeState}
				noMaxWidth
				ref={(ref) => {
					printShareholdersRef.current = ref;
				}}
			>
				<DropdownIconItem
					icon="faFileContract"
					onClick={() => setShowExportOwnerReportModal(true)}
				>
					{t("investments.print_shareholders")}
				</DropdownIconItem>
			</DropdownMenuContainer>
		);
	};

	const renderEmailDropdown = () => {
		const hasReachedShareholderLimit =
			numOfInvestors >= maxNumberOfShareholders;
		let copyShareholdersEmailsActiveState;
		let copyShareholdersEmailsDisabled = false;

		if (hasReachedShareholderLimit) {
			copyShareholdersEmailsActiveState = "shareholderLimitReached";
			copyShareholdersEmailsDisabled = true;
		}

		return (
			<DropdownMenuContainer
				btnIcon="faPaperPlane"
				halignMenu="right"
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
				withPortal
				noMaxWidth
				ref={(ref) => {
					sendEmailBtnRef.current = ref;
				}}
			>
				<Tooltip
					states={COPY_SHAREHOLDERS_EMAIL_TOOLTIP_STATES}
					activeState={copyShareholdersEmailsActiveState}
				>
					<DropdownIconItem
						icon="faFilePlus"
						tid="shareholders.email.copy_email_selected_shareholders"
						tidValues={TEXT_COMPONENT_FILTERED_BOLD}
						onClick={onCopyEmailShareholders}
						disabled={copyShareholdersEmailsDisabled}
					/>
				</Tooltip>
				<RemindShareholderAboutRegistrationDropdownItem
					onClick={() => {
						sendEmailBtnRef?.current.onToggleMenu?.();
					}}
				/>
			</DropdownMenuContainer>
		);
	};

	const renderLeftButtons = () => {
		const basePath = getComponentBasePath();

		return (
			<div className="min-h-[40px] flex justify-between items-center pl-0 max-w-screen-md">
				<div style={{ visibility: numOfInvestors ? "visible" : "hidden" }}>
					<Text
						tid="investments.shareholders.count_summary"
						values={{
							totalCount: numOfInvestors,
							currentCount: numOfActiveShareholders,
						}}
					/>
				</div>
				<div className="h-box">
					<ShareholderAddShareholderBtnContainer
						basePath={basePath}
						location={location}
						onClick={closeAdvancedFilter}
					/>
					{renderPrintOwnerReportDropdown()}
					{renderDownloadDropdown()}
					{renderEmailDropdown()}
					<ShareholdersAdvancedFilterBtnContainer
						onClick={toggleAdvancedFilter}
					/>
				</div>
			</div>
		);
	};

	const hasReachedShareholderLimit = numOfInvestors > maxNumberOfShareholders;

	const breadcrumbsItems = [
		{
			label: t("shares.shareholders.breadcrumbs.title"),
			onClick: () => {
				history.push(getComponentBasePath());
			},
		},
	];

	if (
		selectedInvestor &&
		!history.location.pathname.endsWith("register-of-shareholders")
	) {
		breadcrumbsItems.push({
			label: selectedInvestor.getIn(["investorInformation", "name"]),
		});
	}

	return (
		<div className="flex flex-col p-md h-full">
			<Breadcrumbs items={breadcrumbsItems} />
			{hasReachedShareholderLimit && (
				<>
					<Alert
						variant="warning"
						className="bg-background/50 my-6"
						icon={<Lock />}
						title={t("subscriptions.upgrade_your_plan")}
						message={
							<Trans
								i18nKey="investments.alert.reached_max_number_of_shareholders"
								components={[
									<Button
										key="learnMore"
										variant="link"
										className="p-0"
										onClick={openShareholderLimitationInfoModal}
									/>,
								]}
							/>
						}
					/>
					<ShareholderLimitationInfoModal />
				</>
			)}
			<div className="flex gap-4 overflow-hidden">
				<div className="flex flex-col flex-1" id="flex-1">
					{renderLeftButtons()}
					<div className="pr-3 max-w-screen-md overflow-hidden">
						<ShareholdersFilterAndSortContainer>
							<InvestorsList
								params={match.params}
								basePath={getComponentBasePath()}
								onInvestorClick={closeAdvancedFilter}
							/>
						</ShareholdersFilterAndSortContainer>
					</div>
				</div>
				{!filterIsOpen && match.params.id && (
					<div className="flex-1" id="r">
						<InvestorsDetails />
					</div>
				)}
				{filterIsOpen && (
					<div className="flex-1" id="r">
						{renderButtons()}
						<ShareholdersAdvancedFilterContainer
							isOpen={filterIsOpen}
							onClose={toggleAdvancedFilter}
						/>
					</div>
				)}
			</div>

			<ShareholderTemplateErrorContainer onAccept={uploadExcel} />

			<Dropzone
				hidden={true}
				onDrop={(files) => {
					dispatch(
						importShareholdersFromExcel(files[0], (errorData) =>
							dispatch(openModal(MODALS_SHARES_ERROR_MODAL, { errorData })),
						),
					);
				}}
				dropzoneRef={(ref) => {
					dropzoneRef.current = ref;
				}}
				size="full-screen"
			/>
			{showExportOwnerReportModal && (
				<ExportOwnerReportModal
					onCancel={() => setShowExportOwnerReportModal(false)}
				/>
			)}
			{restrictDialogElement}
			{dialogElement}
		</div>
	);
};

const mapStateToProps = (state) => ({
	company: state.company.company,
	visibleInvestors: state.investors.get("visibleInvestors"),
	selectedInvestor: state.investors.get("selectedInvestor"),
	hasAppliedFilters: state.investors.get("hasAppliedFilters"),
	numOfInvestors: (state.investors.get("list", List()) ?? List()).size,
	numOfActiveShareholders: state.investors.get("numOfActiveShareholders"),
	sharesInitialized: state.company.company?.metadata?.sharesInitialized,
});

/** Export */
export default connect(mapStateToProps)(Investors);
