import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import apiClient from "@/modules/request.module.v2";

export default () => {
	const params = useParams();
	const urlAlias = params.company;

	return useQuery({
		enabled: !!urlAlias,
		queryKey: ["companyByAlias", urlAlias],
		queryFn: () => {
			return apiClient.get(`companies/${urlAlias}?alias=true`);
		},
	});
};
