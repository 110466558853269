import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";

import { cn } from "../utils";
import { LoaderCircle } from "lucide-react";
import withTooltip from "@/hocs/withTooltip";

const buttonVariants = cva(
	"relative inline-flex items-center justify-center border border-transparent whitespace-nowrap rounded-md text-sm font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
	{
		variants: {
			variant: {
				default: "bg-primary text-primary-foreground hover:bg-primary/90",
				destructive:
					"bg-secondary text-destructive border-input hover:bg-secondary/80 hover:border-destructive/50",
				"outline-destructive":
					"border border-destructive text-destructive hover:bg-destructive/10",
				outline:
					"border border-input bg-background hover:bg-accent hover:text-accent-foreground",
				"outline-contrast":
					"border border-background bg-transparent text-background hover:bg-background/10",
				secondary:
					"bg-secondary text-secondary-foreground hover:bg-secondary/80 border border-input",
				ghost: "hover:text-accent-foreground",
				link: "text-primary underline-offset-4 hover:underline !px-0 !py-2",
			},
			size: {
				default: "h-10 px-4 py-7",
				sm: "h-9 rounded-md px-3",
				lg: "h-11 rounded-md px-8 py-10",
				icon: "h-10 w-10",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	},
);

let Button = React.forwardRef(
	(
		{
			className,
			variant,
			size,
			asChild = false,
			children,
			isLoading = false,
			disabled,
			...props
		},
		ref,
	) => {
		const Comp = asChild ? Slot : "button";
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				ref={ref}
				disabled={disabled || isLoading}
				{...props}
			>
				<div
					className={cn("inline-flex items-center gap-2", {
						invisible: isLoading,
					})}
				>
					{children}
				</div>
				{isLoading && (
					<div className="absolute inset-0 flex items-center justify-center">
						<LoaderCircle className="animate-spin" />
					</div>
				)}
			</Comp>
		);
	},
);

Button.displayName = "Button";

Button = withTooltip()(Button);

export { Button, buttonVariants };
