import { useQuery } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";

export default (userId) => {
	return useQuery({
		enabled: !!userId,
		queryKey: ["userCompanies", userId],
		queryFn: () => {
			return apiClient.get(`companies/users/${userId}`);
		},
	});
};
