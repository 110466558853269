import { useQuery } from "@tanstack/react-query";
import { create, windowScheduler, keyResolver } from "@yornaath/batshit";
import apiClient from "@/modules/request.module.v2";

const companiesBadger = create({
	fetcher: async (ids) => {
		const response = await apiClient.post("/companies/public/companies", {
			ids,
		});
		return response.data;
	},
	resolver: keyResolver("id"),
	scheduler: windowScheduler(10),
});

export default (companyId, queryOptions = {}) => {
	return useQuery({
		queryKey: ["publicCompany", companyId],
		queryFn: async () => {
			return companiesBadger.fetch(companyId);
		},
		staleTime: Infinity,
		...queryOptions,
	});
};
