import { fetchDefaultPermissions } from "@/actions/folders.actions";
import useDialogModal from "@/hooks/useDialogModal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FolderDetailsContainer from "../../containers/documents/folder-details.container";
import { Alert } from "@/components/ui/alert";
import DocumentsFolderPicker from "@/components/documents/documentsFolderPicker";
import { Button } from "@/components/ui/button";
import { Folder } from "lucide-react";
import {
	listDocumentsByFilter,
	transferDocument,
	updateDocumentLocal,
} from "@/actions/documents.actions";

const useMoveDocument = (document, basePath) => {
	const [createFolderModalElement, setCreateFolderElement] = useState(null);
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const isSharedFromCompanyId = document.get("isSharedFromCompanyId");
	const isSharedFromCompany = Boolean(isSharedFromCompanyId);
	const isSharedFromCompanyName = useSelector((state) =>
		state.folders.getIn(["folders", isSharedFromCompanyId, "translatedName"]),
	);

	const currentFolderId = useSelector((state) =>
		state.folders.get("selectedFolderId"),
	);

	const documentsFilter = useSelector((state) =>
		state.documents.get("filterBy"),
	);

	const { prompt, dialogElement } = useDialogModal();

	const promptCreateFolder = async (parentFolderId) => {
		await dispatch(fetchDefaultPermissions());

		return new Promise((resolve) => {
			setCreateFolderElement(
				<FolderDetailsContainer
					isOpen
					parentFolderId={parentFolderId}
					userMessage={
						<Alert
							title={t("documents.upload.folder_required.title")}
							message={t("documents.upload.folder_required_create.message")}
							className="mb-4"
						/>
					}
					mode="create"
					basePath={basePath}
					onClose={() => setCreateFolderElement(null)}
					onSubmit={resolve}
				/>,
			);
		});
	};

	const openMoveModal = async () => {
		let pickedFolder, parentFolderId;

		pickedFolder = await prompt(
			t("documents.move_file.title"),
			(props, setSubmitValue) => (
				<>
					<p>{t("documents.move_file.message")}</p>
					<DocumentsFolderPicker onChange={setSubmitValue} />
				</>
			),
			{
				size: "md",
				submitText: t("generic.move"),
				submitDisabled: (submitValue) => !submitValue,
				footerRenderer: (props, submitValue) => (
					<div className="flex justify-between w-full">
						<Button
							variant="secondary"
							onClick={async () => {
								parentFolderId = submitValue;
								props.onSubmit("CREATE_FOLDER");
							}}
						>
							{<Folder size={16} />}
							{t("documents.create.title")}
						</Button>
						<div className="flex gap-2">
							{props.cancelButtonRenderer(props, submitValue)}
							{props.submitButtonRenderer(props, submitValue)}
						</div>
					</div>
				),
			},
		);

		if (pickedFolder === "CREATE_FOLDER") {
			pickedFolder = await promptCreateFolder(parentFolderId);
		} else if (!pickedFolder) {
			return;
		}

		dispatch(updateDocumentLocal(document.set("folderId", pickedFolder)));
		await dispatch(transferDocument([document.get("id")], pickedFolder));
		await dispatch(listDocumentsByFilter(documentsFilter, currentFolderId));
	};

	const getTooltipData = () => {
		const canMoveDocument =
			document.get("ALLOW_UPDATE") && !isSharedFromCompany;
		const tooltipValues = {};
		let activeState;

		if (!canMoveDocument && isSharedFromCompany) {
			activeState = "hasNoPermissionsSharedCompany";
			tooltipValues.companyName = isSharedFromCompanyName;
		} else if (!canMoveDocument) {
			activeState = "hasNoPermissions";
		}

		return {
			activeState,
			tooltipValues,
			isDisabled: Boolean(activeState),
		};
	};

	const isDeleted = document.get("isDeleted");

	if (isDeleted) {
		return null;
	}

	const { TOOLTIP_STATES, activeState, tooltipValues, isDisabled } =
		getTooltipData();

	return {
		createFolderModalElement,
		dialogElement,
		tooltip: {
			states: TOOLTIP_STATES,
			activeState,
			isDisabled,
			values: tooltipValues,
		},
		isDisabled,
		openMoveModal,
	};
};

export default useMoveDocument;
