import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";

import { cn } from "@/components/utils";
import { bool, node, string } from "prop-types";
import withTooltip from "@/hocs/withTooltip";

let Switch = React.forwardRef(
	({ className, flipLabel, label, id, ...props }, ref) => (
		<div
			className={cn("flex items-center gap-2", {
				"flex-row-reverse": flipLabel,
			})}
		>
			<SwitchPrimitives.Root
				className={cn(
					"peer inline-flex h-[20px] w-[32px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
					className,
				)}
				{...props}
				id={id}
				ref={ref}
			>
				<SwitchPrimitives.Thumb
					className={cn(
						"pointer-events-none block h-[16px] w-[16px] rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0",
					)}
				/>
			</SwitchPrimitives.Root>
			{label && (
				<label className="mb-0" htmlFor={id}>
					{label}
				</label>
			)}
		</div>
	),
);

Switch.displayName = SwitchPrimitives.Root.displayName;

Switch.propTypes = {
	label: node,
	className: string,
	flipLabel: bool,
};

Switch = withTooltip()(Switch);

export { Switch };
