import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";
import {
	addErrorNotification,
	addInfoNotification,
} from "@/components/framework/notify";

export default (companyId) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ["subscriptionUndoScheduledPlanChange", companyId],
		mutationFn: () => {
			return apiClient.post(`/subscriptions/change-plan-reset`, undefined, {
				params: {
					companyId,
				},
			});
		},
		onSuccess: (mutationResponse) => {
			queryClient.setQueryData(["subscription", companyId], mutationResponse);
			queryClient.invalidateQueries(["subscriptionSchedule", companyId]);

			addInfoNotification({
				tid: "subscriptions.notifications.success.subscription_updated",
			});
		},
		onError: () => {
			addErrorNotification({ tid: "generic.unknown_error" });
		},
	});
};
