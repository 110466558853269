import React, { useCallback, useEffect, useRef, useState } from "react";
import { Map, fromJS } from "immutable";
import AgGrid from "../../../../dumb-components/shared/ag-grid/ag-grid";
import { useDispatch, useSelector } from "react-redux";
import { fetchMembersAndInvestors } from "../../../../actions/people.actions";
import { useTranslation } from "react-i18next";
import {
	INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE,
	INVESTOR_TYPE_OF_OWNER_PRIVATE,
	INVESTOR_TYPE_OF_OWNER_COMPANY,
} from "/shared/constants";
import { usePeopleModalContext } from "../people-modal.context";
import { TAB_TEAMS_AND_SHAREHOLDES } from "../../select-user-modal/select-user-modal.constants";
import { func, bool } from "prop-types";
import { validateEmail } from "../../../../modules/validation.module";
import { EmailCellRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/email.cell-renderer";
import { RolesTooltipComponent } from "../tooltips/RolesTooltipComponent";
import { PEOPLE_MODAL_FILTERS_SHAREHOLDERS_STATUS } from "../constants/filters";
import { CheckboxRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/checkbox.renderer";
import { UnselectableRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/unselectable.renderer";
import { TextOnlyTooltip } from "../../../../dumb-components/shared/ag-grid/tooltips/text-only-tooltip";
import { ProfileImageRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/profile-image.renderer";
import { CheckboxInHeaderRenderer } from "../../../../dumb-components/shared/ag-grid/renderers/checkbox-in-header.renderer";
import { makePersonAndAppendToPeopleList } from "../helpers";

function TeamsAndInvestorsListContainer({
	disableMemberFunction,
	disableInvestorFunction,
	includeUnregisteredUsers,
	initiallySelectAll,
	includeInvestorsWithoutShares,
	showOnlyInvestors,
	showOnlyMembers,
	showOnlyUnlinkedInvestors,
	initiallyShowOnlyShareholders,
	usePagination,
}) {
	const {
		selectedPeople,
		setSelectedPeople,
		activeTab,
		floatingFilter,
		singleMode,
		showGridInExtendedView,
		setShowColumnToggle,
		setIsFloatingFilterActive,
		setFilters,
		filters,
	} = usePeopleModalContext();
	const [people, setPeople] = useState();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const gridRef = useRef();
	const isMounting = useRef(true);
	const membersAndInvestors = useSelector((state) =>
		state.people.get("filteredMembersAndInvestors"),
	);

	useEffect(() => {
		dispatch(
			fetchMembersAndInvestors({
				includeUnregisteredUsers,
				includeInvestorsWithoutShares,
				showOnlyInvestors,
				showOnlyMembers,
				showOnlyUnlinkedInvestors,
			}),
		);

		return () => {};
	}, []);

	useEffect(() => {
		const parsedMembersAndInvestors = membersAndInvestors.toJS().map((item) => {
			let isSelectable = true;
			let tooltipTid;
			let restrictionIcon;
			let restrictionIconSize = 21;
			let restrictionIconColor;

			if (!validateEmail(item.email)) {
				isSelectable = false;
				tooltipTid = "people_modal.grid.selected.tooltip.email_is_missing";
				restrictionIcon = "faExclamationTriangle";
				restrictionIconSize = 17;
			} else {
				if (item.isCompanyMember && disableMemberFunction) {
					const result = disableMemberFunction(fromJS(item));

					if (typeof result === "boolean") {
						isSelectable = !result;
						tooltipTid =
							!isSelectable &&
							"people_modal.grid.selected.tooltip.not_selectable";
						restrictionIcon = "faExclamationSquare";
					} else if (typeof result === "object") {
						isSelectable = !result.isDisabled;
						tooltipTid =
							!isSelectable &&
							(result.tooltipTid ??
								"people_modal.grid.selected.tooltip.not_selectable");
						restrictionIcon = result.icon ?? "faExclamationSquare";
						restrictionIconColor = result.iconColor;
					}
				}

				if (item.isInvestor && disableInvestorFunction) {
					const result = disableInvestorFunction(fromJS(item));

					if (typeof result === "boolean") {
						isSelectable = !result;
						tooltipTid =
							!isSelectable &&
							"people_modal.grid.selected.tooltip.not_selectable";
						restrictionIcon = "faExclamationSquare";
					} else {
						isSelectable = !result.isDisabled;
						tooltipTid =
							!isSelectable &&
							(result.tooltipTid ??
								"people_modal.grid.selected.tooltip.not_selectable");
						restrictionIcon = result.icon ?? "faExclamationSquare";
						restrictionIconColor = result.iconColor;
					}
				}
			}

			item.selectable = isSelectable;
			item.tooltipTidOnSelectedColumn = tooltipTid;
			item.restrictionIcon = restrictionIcon;
			item.restrictionIconSize = restrictionIconSize;
			item.restrictionIconColor = restrictionIconColor;

			return item;
		});

		setPeople(parsedMembersAndInvestors);

		return () => {};
	}, [membersAndInvestors]);

	useEffect(() => {
		if (
			initiallyShowOnlyShareholders &&
			!filters[PEOPLE_MODAL_FILTERS_SHAREHOLDERS_STATUS] &&
			membersAndInvestors?.size > 0
		) {
			setFilters({
				[PEOPLE_MODAL_FILTERS_SHAREHOLDERS_STATUS]: "SHOW_ALL",
			});
		}
	}, [initiallyShowOnlyShareholders, membersAndInvestors, filters]);

	useEffect(() => {
		if (activeTab === TAB_TEAMS_AND_SHAREHOLDES && showOnlyMembers) {
			setTimeout(() => setShowColumnToggle(false));
		}
	}, [activeTab]);

	const setSelectedRows = () => {
		let peopleToAddInitially = Map();

		gridRef.current.api.forEachNode((node) => {
			if (
				isMounting.current === true &&
				node.selectable === true &&
				initiallySelectAll
			) {
				node.setSelected(true, false, true);
				node.setDataValue("selected", true);
				peopleToAddInitially = makePersonAndAppendToPeopleList(
					peopleToAddInitially,
					node.data,
				);
			} else {
				const rowIsSelected =
					selectedPeople && selectedPeople.has(node.data.id);
				node.setSelected(rowIsSelected, false, true);
				node.setDataValue("selected", rowIsSelected);
			}
		});

		if (peopleToAddInitially.size > 0 && initiallySelectAll) {
			setSelectedPeople(peopleToAddInitially);
		}

		isMounting.current =
			gridRef.current.api.getModel().getRowCount() > 0 ? false : true;
	};

	const onSelectionChanged = ({ api }) => {
		let newPeople = selectedPeople;

		api.forEachNode((node) => {
			const isSelected = node.isSelected();
			const { id } = node.data;
			const isAlreadyASelectedPerson = selectedPeople.has(id);

			if (isSelected) {
				if (!isAlreadyASelectedPerson) {
					newPeople = makePersonAndAppendToPeopleList(newPeople, node.data);
				}
			} else {
				newPeople = newPeople.remove(id);
			}
		});

		setSelectedPeople(newPeople);

		const newMembersAndShareholders = people.map((person) => {
			const node = api.getRowNode(person.id);
			person.selected = node.selected;
			return person;
		});
		setPeople(newMembersAndShareholders);
	};

	const isRowSelectable = (rowNode) => {
		if (!rowNode.data) {
			return true;
		}

		return rowNode.data.selectable;
	};

	const getInvestorTypeOfOwner = ({ data: { investorTypeOfOwner } }) => {
		let text;
		switch (investorTypeOfOwner) {
			case INVESTOR_TYPE_OF_OWNER_PRIVATE:
				text = t("people_modal.investor_type_of_owner.private");
				break;
			case INVESTOR_TYPE_OF_OWNER_COMPANY:
				text = t("people_modal.investor_type_of_owner.company");
				break;
			case INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE:
				text = t("people_modal.investor_type_of_owner.capital_insurance");
				break;
		}

		return text;
	};

	const getInvestorStatus = ({ data: { investorStatus } }) => {
		let text;
		switch (investorStatus) {
			case "NON_SHAREHOLDER":
				text = t("people_modal.grid.investor_status.non_shareholder");
				break;
			case "CURRENT":
				text = t("people_modal.grid.investor_status.current");
				break;
			case "PAST":
				text = t("people_modal.grid.investor_status.past");
				break;
		}

		return text;
	};

	const columnDefs = () => {
		let columns = [
			{
				field: "selected",
				headerName: "",
				cellRendererSelector: ({ node, data }) => {
					if (!node.selectable) {
						return {
							component: UnselectableRenderer,
							params: {
								icon: data.restrictionIcon,
								iconSize: data.restrictionIconSize,
								iconColor: data.restrictionIconColor,
							},
						};
					}

					return {
						component: CheckboxRenderer,
					};
				},
				headerComponent: !singleMode ? CheckboxInHeaderRenderer : undefined,
				headerComponentParams: {
					filteredOnly: true,
				},
				lockVisible: true,
				minWidth: 38,
				maxWidth: 38,
				tooltipField: "selected",
				tooltipComponent: TextOnlyTooltip,
				tooltipComponentParams: {
					tooltipTid: ({ data }) => {
						return data.tooltipTidOnSelectedColumn;
					},
				},
				cellClass: "checkbox-cell",
				headerClass: "checkbox-cell",
				filter: false,
			},
			{
				field: "image",
				headerName: "",
				minWidth: 47,
				maxWidth: 47,
				width: 47,
				cellClass: "profile-image-cell",
				headerClass: "profile-image-cell",
				cellRenderer: ProfileImageRenderer,
				filter: false,
			},
			{
				field: "reference",
				headerName: t("people_modal.grid.headers.reference"),
				hide: !showOnlyMembers && !showGridInExtendedView,
				minWidth: 150,
			},
			{
				field: "name",
				headerName: t("people_modal.grid.headers.name"),
				flex: 1,
				minWidth: showGridInExtendedView ? 350 : showOnlyMembers ? 350 : 220,
				filter: "agTextColumnFilter",
			},
			{
				field: "phone",
				headerName: t("generic.telephone"),
				minWidth: 150,
				filter: "agTextColumnFilter",
				hide: !showOnlyMembers && !showGridInExtendedView,
			},
			{
				field: "email",
				headerName: t("people_modal.grid.headers.email"),
				minWidth: showGridInExtendedView ? 320 : showOnlyMembers ? 330 : 241,
				cellRenderer: EmailCellRenderer,
				filter: "agTextColumnFilter",
			},
		];

		if (!showOnlyMembers) {
			columns = [
				...columns,
				{
					field: "isCompanyMember",
					headerName: t("people_modal.grid.headers.is_member"),
					minWidth: 162,
					valueGetter: ({ data: { isCompanyMember } }) =>
						isCompanyMember === true ? t("generic.yes") : t("generic.no"),
					filter: "agSetColumnFilter",
					tooltipComponent: RolesTooltipComponent,
					tooltipField: "isCompanyMember",
				},
				{
					field: "investorTypeOfOwner",
					headerName: t("people_modal.grid.headers.investor_type_of_owner"),
					minWidth: 170,
					valueGetter: getInvestorTypeOfOwner,
					filter: "agSetColumnFilter",
				},
				{
					field: "investorStatus",
					headerName: t("people_modal.grid.headers.investor_status"),
					minWidth: 188,
					valueGetter: getInvestorStatus,
					filter: "agTextColumnFilter",
				},
			];
		}

		if (includeUnregisteredUsers) {
			columns = [
				...columns,
				{
					field: "accountStatus",
					headerName: t("investors.filter.label.registered_user"),
					minWidth: 100,
					valueGetter: ({ data: { accountStatus } }) =>
						accountStatus === "ACTIVE" ? t("generic.yes") : t("generic.no"),
					filter: "agTextColumnFilter",
				},
			];
		}

		return columns;
	};

	const getRowId = useCallback((params) => params.data.id, []);

	if (activeTab !== TAB_TEAMS_AND_SHAREHOLDES) {
		return null;
	}

	return (
		<AgGrid
			gridRef={gridRef}
			rowData={people}
			columnDefs={columnDefs()}
			mode="panel"
			rowSelection={singleMode ? "single" : "multiple"}
			onRowDataChanged={setSelectedRows}
			isRowSelectable={isRowSelectable}
			forcedHeight="100%"
			hideColumnMenu={true}
			floatingFilter={floatingFilter}
			suppressRowClickSelection={true}
			rowsPerPage={50}
			noPagination={!usePagination}
			onSelectionChanged={onSelectionChanged}
			// onRowSelected={({ node }) => node.setDataValue('selected', node.selected)}
			suppressContextMenu
			onFilterChanged={({ api }) => {
				// Why  are we doing a refreshHeader on a onFilterChange? It onfucuses the advanced filter.
				//api.refreshHeader();

				if (Object.keys(api.getFilterModel()).length > 0) {
					setIsFloatingFilterActive(true);
				} else if (Object.keys(api.getFilterModel()).length === 0) {
					setIsFloatingFilterActive(false);
				}
			}}
			getRowId={getRowId}
			enableCopyCellText
		/>
	);
}

TeamsAndInvestorsListContainer.propTypes = {
	disableMemberFunction: func,
	disableInvestorFunction: func,
	includeUnregisteredUsers: bool,
	initiallySelectAll: bool,
	includeInvestorsWithoutShares: bool,
	showOnlyInvestors: bool,
	showOnlyMembers: bool,
	showOnlyUnlinkedInvestors: bool,
	initiallyShowOnlyShareholders: bool,
};

TeamsAndInvestorsListContainer.defaultProps = {
	includeUnregisteredUsers: false,
	initiallySelectAll: false,
	includeInvestorsWithoutShares: true,
	showOnlyInvestors: false,
	showOnlyMembers: false,
	showOnlyUnlinkedInvestors: false,
	initiallyShowOnlyShareholders: false,
};

export { TeamsAndInvestorsListContainer };
