import React, { useState } from "react";
import SubscriptionCheckoutModal from "../../components/subscriptions/subscriptionCheckoutModal";
import { Trans, useTranslation } from "react-i18next";

import PlansPanel from "../../dumb-components/subscriptions/plans-panel";

import { PRODUCT_ACCOUNT_PLAN_FREE_ID } from "/shared/constants";

import useUpdateSubscription from "@/hooks/subscriptions/useUpdateSubscription";
import useDialogModal from "@/hooks/useDialogModal";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import useChangeSubscriptionPlan from "@/hooks/subscriptions/useChangeSubscriptionPlan";
import useDocumentsHelper from "@/hooks/documents/useDocumentsHelper";

function PlansContainer({ subscription, plans, isSharingFolders }) {
	const { t } = useTranslation();

	const currentAccountPlanId = subscription.accountPlanId;

	const companyId = useCompanyIdFromUrl();

	const { mutate: updateSubscriptionPlan } =
		useChangeSubscriptionPlan(companyId);

	const { data: documentsHelperRequest } = useDocumentsHelper();
	const documentsHelper = documentsHelperRequest?.data;

	const [showCheckout, setShowCheckout] = useState(false);
	const [selectedAccountPlanId, setSelectedAccountPlanId] = useState();

	const { mutateAsync: updateSubscription } = useUpdateSubscription(companyId);

	const isFreeAccountPlanSelected =
		selectedAccountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID;

	const { confirm, alert, dialogElement } = useDialogModal();

	const isDowngrading = () => {
		// No current subscription so we are not downgrading
		if (!currentAccountPlanId) {
			return false;
		}

		// No new account plan selected so we are not downgrading
		if (!selectedAccountPlanId) {
			return false;
		}

		const currentAccountPlan = plans.mapPlanIdToPlan?.[currentAccountPlanId];
		const selectedAccountPlan = plans.mapPlanIdToPlan?.[selectedAccountPlanId];

		// We are downgrading if level of the current account plan is greater than the selected one
		if (
			parseInt(currentAccountPlan?.metadata?.level) >
			parseInt(selectedAccountPlan?.metadata?.level)
		) {
			return true;
		}

		return false;
	};

	const selectedPlanStorageLimitExceeded = () => {
		// No current subscription so accept
		if (!currentAccountPlanId) {
			return true;
		}

		// No new account plan selected so we are not downgrading
		if (!selectedAccountPlanId) {
			return false;
		}

		const currentAccountPlan = plans.mapPlanIdToPlan?.[currentAccountPlanId];
		const selectedAccountPlan = plans.mapPlanIdToPlan?.[selectedAccountPlanId];

		const newStorageSpace = parseFloat(selectedAccountPlan?.metadata?.storage);
		// We are downgrading if level of the current account plan is greater than the selected one
		// but the company have used too much storage space to allow downgrading.
		return !(
			parseInt(currentAccountPlan?.metadata?.level) >
				parseInt(selectedAccountPlan?.metadata?.level) &&
			documentsHelper.usedStorageSpace > newStorageSpace
		);
	};

	const showMessageAboutFolderSharing = () => {
		const currentAccountPlanId = subscription.accountPlanId;

		if (!currentAccountPlanId) {
			return false;
		}

		// No new account plan selected so we are not downgrading
		if (!selectedAccountPlanId) {
			return false;
		}
		const currentAccountPlan = plans.mapPlanIdToPlan?.[currentAccountPlanId];
		const selectedAccountPlan = plans.mapPlanIdToPlan?.[selectedAccountPlanId];
		const folderSharingEnabledForCurrentAccountPlan =
			currentAccountPlan?.metadata?.folderSharingEnabled === "true";
		const folderSharingEnabledForSelectedAccountPlan =
			selectedAccountPlan?.metadata?.folderSharingEnabled === "true";

		if (
			folderSharingEnabledForCurrentAccountPlan &&
			!folderSharingEnabledForSelectedAccountPlan &&
			isSharingFolders
		) {
			return true;
		}

		return false;
	};

	const onSaveSubscription = () => {
		updateSubscription({});
	};

	const onClickPurchase = async () => {
		if (!isDowngrading()) return setShowCheckout(true);

		if (!selectedPlanStorageLimitExceeded()) {
			return await alert(
				t("subscriptions.alert_downgrading.title"),
				<Trans i18nKey="subscriptions.alert_downgrading.body" />,
				{ variant: "warning" },
			);
		}

		if (isFreeAccountPlanSelected) {
			return (
				(await confirm(
					t("subscriptions.button.cancel_subscription"),
					t("subscriptions.cancel_subscription_confirm.message"),
					{
						variant: "destructive",
						submitText: t("subscriptions.button.cancel_subscription"),
					},
				)) && updateSubscriptionPlan({ planId: PRODUCT_ACCOUNT_PLAN_FREE_ID })
			);
		}

		setShowCheckout(true);
	};

	if (!subscription || !plans) {
		return null;
	}

	const subscribedAccountPlanId =
		subscription?.accountPlanId ?? PRODUCT_ACCOUNT_PLAN_FREE_ID;

	const subscribedAccountPlan =
		plans["mapPlanIdToPlan"]?.[subscribedAccountPlanId];

	const selectedAccountPlan =
		selectedAccountPlanId && plans["mapPlanIdToPlan"]?.[selectedAccountPlanId];

	const confirmPurchaseMessages = [
		showMessageAboutFolderSharing() &&
			t("subscriptions.confirm_purchase.question.sharing_folders"),
	].filter(Boolean);

	return (
		<React.Fragment>
			<PlansPanel
				selectedAccountPlanId={selectedAccountPlanId || subscribedAccountPlanId}
				selectedAccountPlan={selectedAccountPlan}
				subscribedAccountPlan={subscribedAccountPlan}
				isLoading={showCheckout}
				onSelectAccountPlan={setSelectedAccountPlanId}
				onClickPurchase={onClickPurchase}
				onSaveSubscription={onSaveSubscription}
			/>
			{showCheckout && (
				<SubscriptionCheckoutModal
					confirmationMessageRenderer={() =>
						confirmPurchaseMessages.map((message) => (
							<p key={message}>{message}</p>
						))
					}
					onCancel={() => setShowCheckout(null)}
					selectedAccountPlanId={selectedAccountPlanId}
					onChangeSelectedAccountPlanId={setSelectedAccountPlanId}
					onPurchaseComplete={() => {
						window.location.reload();
					}}
				/>
			)}
			{dialogElement}
		</React.Fragment>
	);
}

export default PlansContainer;
