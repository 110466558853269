import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import MuiCheckbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import { styled } from "@mui/system";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import IconButton from "@mui/material/IconButton";
import NotesModal from "./notes-modal";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import {
	getInvitees,
	getNotes,
	updateContactPerson,
	updateInterested,
} from "../../../../actions/emissions.actions";
import { useDispatch, useSelector } from "react-redux";
import FlagIcon from "@mui/icons-material/Flag";
import Typography from "@mui/material/Typography";
import InviteeContextMenu from "./invitee-context-menu";
import { emissionConsts } from "../../../../constants/emissions";
import { SET_EMISSION_INVITEE_NOTES } from "../../../../actions/types";
import { useInvitedAsFilter } from "../../common/invited-as-filter-hook";
import { closeModal, openModal } from "../../../../actions/modals.actions";
import { GRID_MODAL } from "../../../../constants/modals";
import GridPanelRightButtons from "../../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons";
import {
	GRID_MODE_PANEL,
	GRID_MODE_MODAL,
	INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE,
	INVESTOR_TYPE_OF_OWNER_COMPANY,
	INVESTOR_TYPE_OF_OWNER_PRIVATE,
} from "/shared/constants";
import GridModal from "../../../../dumb-components/shares/ag-grid/grid-modal";
import ExportExcelButton from "../../../../dumb-components/investment/shareholders/export-excel-button";
import { TextEditorWithValidation } from "../../../../dumb-components/shared/ag-grid/editors/TextEditorWithValidation";
import { validateEmail } from "../../../../modules/validation.module";
import { ComboBox } from "../../../../dumb-components/shared/ag-grid/editors/ComboBox";
import { editCompanyContact } from "../../../../actions/people.actions";
import { updateUsersMetaData } from "../../../../actions/company.actions";
import {
	addCompanyInvestmentContact,
	editCompanyInvestmentContact,
	patchContactInformation,
} from "../../../../actions/investments.actions";
import getTranslations from "../../../../dumb-components/shared/ag-grid/locale";
import Moment from "@/modules/moment.module";
import { Button } from "@/components/ui/button";
import { Mail } from "lucide-react";

const { interestedOption } = emissionConsts.invitation;

const AgGridStyle = styled("div")(({ theme }) => ({
	height: "100%",
	"& .ag-header": {
		borderRadius: 5,
		backgroundColor: theme.palette.blue["100"],
	},
	"& .ag-cell": {
		display: "flex",
		alignItems: "center",
	},
}));

const InvitedPeopleGrid = ({ invitees, preventChanges }) => {
	const { t } = useTranslation();
	const { notes, current } = useSelector((state) => state.emissions);
	const [name, setName] = useState("");
	const [floatingFilter, setFloatingFilter] = useState(false);
	const companyId = useSelector((state) => state.company.company.id);

	const dispatch = useDispatch();
	const invitedAsFilter = useInvitedAsFilter();

	const gridOption = "invitedPeople";
	const isGridModalOpen = useSelector((state) => {
		return state.modals.getIn(["activeModal", "options"]) === gridOption;
	});
	const openGridModal = useCallback(() => {
		dispatch(openModal(GRID_MODAL, gridOption));
	});

	const onCloseGridModal = () => {
		dispatch(closeModal());
	};

	const displayFloatingFilter = useCallback(() => {
		if (floatingFilter) {
			setFloatingFilter(false);
		} else {
			setFloatingFilter(true);
		}
	});

	const canEditPersonName = ({ data }) => {
		if (preventChanges) {
			return false;
		}

		if (data.type === INVESTOR_TYPE_OF_OWNER_COMPANY) {
			return true;
		} else {
			return false;
		}
	};

	const canEditTelephoneNumber = ({ data }) => {
		if (preventChanges) {
			return false;
		}

		if (
			data.type === INVESTOR_TYPE_OF_OWNER_PRIVATE ||
			data.type === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE
		) {
			return !data.phoneFromProfile;
		} else if (data.type === INVESTOR_TYPE_OF_OWNER_COMPANY) {
			return !!data.contactPerson;
		} else if (data.type === "company-member") {
			return !data.phoneFromProfile;
		} else if (data.type === "company-contact") {
			return true;
		} else {
			return false;
		}
	};

	const canEditEmailAddress = ({ data }) => {
		if (preventChanges) {
			return false;
		}
		if (
			data.type === INVESTOR_TYPE_OF_OWNER_PRIVATE ||
			data.type === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE
		) {
			return !data.isRegisteredUser;
		} else if (data.type === INVESTOR_TYPE_OF_OWNER_COMPANY) {
			return !!data.contactPerson;
		} else if (data.type === "company-contact") {
			return true;
		} else {
			return false;
		}
	};

	const onTelephoneChanged = ({ data, newValue }) => {
		if (
			(data.type === INVESTOR_TYPE_OF_OWNER_PRIVATE ||
				data.type === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE) &&
			!data.phoneFromProfile
		) {
			dispatch(
				patchContactInformation({ telephone: newValue }, data.investmentId),
			);
		} else if (
			data.type === INVESTOR_TYPE_OF_OWNER_COMPANY &&
			!!data.contactPerson
		) {
			dispatch(
				editCompanyInvestmentContact(
					{ phone: newValue },
					data.contactPerson.id,
					data.investmentId,
				),
			);
		} else if (data.type === "company-member" && !data.phoneFromProfile) {
			dispatch(updateUsersMetaData(data.userId, { telephone: newValue }));
		} else if (data.type === "company-contact") {
			dispatch(editCompanyContact(data.userId, { phone: newValue }));
		}
	};

	const onEmailChanged = ({ data, newValue }) => {
		if (
			(data.type === INVESTOR_TYPE_OF_OWNER_PRIVATE ||
				data.type === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE) &&
			!data.isRegisteredUser
		) {
			dispatch(patchContactInformation({ email: newValue }, data.investmentId));
		} else if (
			data.type === INVESTOR_TYPE_OF_OWNER_COMPANY &&
			!!data.contactPerson
		) {
			dispatch(
				editCompanyInvestmentContact(
					{ email: newValue },
					data.contactPerson.id,
					data.investmentId,
				),
			);
		} else if (data.type === "company-contact") {
			dispatch(editCompanyContact(data.userId, { email: newValue }));
		}
	};

	const onContactPersonChanged = async ({ data, newValue }) => {
		console.log("newValue", newValue);
		if (typeof newValue === "string" && newValue.length > 0) {
			const contact = (data.contacts ?? []).find(
				({ name }) => name === newValue,
			);

			if (!contact) {
				dispatch(
					addCompanyInvestmentContact(
						{ name: newValue },
						data.investmentId,
						async (contact) => {
							await dispatch(
								updateContactPerson(companyId, current.id, data.id, contact.id),
							);
							await dispatch(getInvitees(companyId, current.id));
						},
					),
				);
			} else {
				await dispatch(
					updateContactPerson(companyId, current.id, data.id, contact.id),
				);
				await dispatch(getInvitees(companyId, current.id));
			}
		}
	};

	const renderAgGrid = () => {
		return (
			<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
				<NotesModal
					name={name}
					open={!!notes}
					close={() => {
						dispatch({
							type: SET_EMISSION_INVITEE_NOTES,
							payload: { notes: null },
						});

						dispatch(getInvitees(current.companyId, current.id));
					}}
				/>
				<Box sx={{ display: "flex" }}>
					{invitedAsFilter.renderFilterButtons()}
					{!isGridModalOpen && (
						<div
							style={{ justifyContent: "flex-end", flex: 1, display: "flex" }}
						>
							<GridPanelRightButtons
								openGridModal={openGridModal}
								displayFloatingFilter={displayFloatingFilter}
								floatingFilter={floatingFilter}
							/>
						</div>
					)}
				</Box>

				<Box sx={{ flex: 1 }}>
					<AgGridStyle className={"ag-theme-material"}>
						<AgGridReact
							gridOptions={{
								onGridReady(event) {
									invitedAsFilter.setGridApi(event.api);
									event.api.sizeColumnsToFit();
								},
								onGridSizeChanged(event) {
									event.api.sizeColumnsToFit();
								},
							}}
							rowHeight={56}
							localeText={getTranslations(t)}
							defaultColDef={{
								suppressMovable: true,
								filter: "agMultiColumnFilter",
								floatingFilter: floatingFilter,
								suppressMenu: true,
							}}
							suppressCellFocus
							components={{
								checkboxRenderer: Checkbox,
								actionsRenderer: ActionsCell,
								interestedRenderer: InterestedCell,
								nameRenderer: NameCell,
							}}
							rowData={invitees}
							mode={isGridModalOpen ? GRID_MODE_MODAL : GRID_MODE_PANEL}
							isGridModalOpen={isGridModalOpen}
							getContextMenuItems={true}
							stopEditingWhenCellsLoseFocus={true}
							columnDefs={[
								{
									field: "type",
									headerName: t("generic.type"),
									suppressMenu: true,
									valueGetter: (params) => {
										switch (params.data.type) {
											case INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE:
												return t(
													"emissions.invitation.invited_people_grid.types.capitalInsurance",
													{
														state: t(
															params.data.isRegisteredUser
																? "emissions.invitation.invited_people_grid.states.registered"
																: "emissions.invitation.invited_people_grid.states.not_registered",
														),
													},
												);
											case INVESTOR_TYPE_OF_OWNER_COMPANY:
												return t(
													"emissions.invitation.invited_people_grid.types.company",
													{
														state: t(
															params.data.isRegisteredCompany
																? "emissions.invitation.invited_people_grid.states.registrerat"
																: "emissions.invitation.invited_people_grid.states.ej_registrerat",
														),
													},
												);
											case INVESTOR_TYPE_OF_OWNER_PRIVATE:
												return t(
													"emissions.invitation.invited_people_grid.types.private",
													{
														state: t(
															params.data.isRegisteredUser
																? "emissions.invitation.invited_people_grid.states.registered"
																: "emissions.invitation.invited_people_grid.states.not_registered",
														),
													},
												);
											case "company-member":
												return t(
													"emissions.invitation.invited_people_grid.types.company-member",
													{
														state: t(
															params.data.isRegisteredUser
																? "emissions.invitation.invited_people_grid.states.registered"
																: "emissions.invitation.invited_people_grid.states.not_registered",
														),
													},
												);
											case "company-contact":
												return t(
													"emissions.invitation.invited_people_grid.types.company-contact",
													{
														state: t(
															"emissions.invitation.invited_people_grid.states.not_registered",
														),
													},
												);
										}
									},
								},
								{
									field: "reference",
									headerName: t(
										"emissions.invitation.invited_people_grid.column_header.ssn",
									),
								},
								{
									field: "company",
									headerName: t(
										"emissions.invitation.invited_people_grid.column_header.company",
									),
								},
								{
									field: "name",
									headerName: t(
										"emissions.invitation.invited_people_grid.column_header.person",
									),
									suppressMenu: true,
									cellRenderer: "nameRenderer",
									suppressSizeToFit: true,
									editable: canEditPersonName,
									singleClickEdit: true,
									cellEditor: ComboBox,
									cellEditorParams: {
										options: (params) => {
											if (Array.isArray(params.data.contacts)) {
												return params.data.contacts.map((contact) => ({
													label: contact.name,
												}));
											} else {
												return [];
											}
										},
									},
									onCellValueChanged: onContactPersonChanged,
								},
								...(isGridModalOpen
									? [
											{
												field: "phone",
												headerName: t("generic.telephone"),
												suppressMenu: true,
												editable: canEditTelephoneNumber,
												singleClickEdit: true,
												cellEditor: TextEditorWithValidation,
												cellEditorParams: {
													validate: () => true,
												},
												onCellValueChanged: onTelephoneChanged,
											},
									  ]
									: []),
								...(isGridModalOpen
									? [
											{
												field: "email",
												headerName: t(
													"emissions.invitation.invited_people_grid.column_header.email",
												),
												editable: canEditEmailAddress,
												singleClickEdit: true,
												cellEditor: TextEditorWithValidation,
												cellEditorParams: {
													validate: (value) => validateEmail(value),
												},
												onCellValueChanged: onEmailChanged,
											},
									  ]
									: []),
								//! This feild needs to be here for the filter by invited as (tabs above grid) to work
								{
									field: "invitedAs",
									filter: "agSetColumnFilter",
									hide: true,
								},
								!preventChanges
									? {
											field: "interested",
											headerName: t("generic.interested"),
											suppressMenu: true,
											cellRenderer: "interestedRenderer",
											valueGetter: ({ data }) =>
												t(getInterestedLangKey(data.interested)),
									  }
									: {},
								{
									field: "signedSubmission",
									headerName: t("generic.applied"),
									suppressMenu: true,
									valueGetter: ({ data, column: { colId } }) =>
										data[colId] === true
											? t(
													"emissions_module.invitation.invited_people_grid.applied.yes",
											  )
											: t(
													"emissions_module.invitation.invited_people_grid.applied.no",
											  ),
									valueFormatter: ({ data, column: { colId } }) => {
										const value = data[colId];
										if (value === true) {
											return t(
												"emissions_module.invitation.invited_people_grid.applied.yes",
											);
										} else {
											return "";
										}
									},
								},
								{
									field: "reminded",
									headerName: t("generic.reminders"),
									cellStyle: { lineHeight: "normal" },
									cellRenderer: ({ data }) => {
										if (!data?.reminders?.length) return t("none");

										const title = data.reminders.map((reminder) => {
											return (
												<div
													className="flex gap-2 items-center"
													key={reminder.createdAt}
												>
													<Mail size={12} />
													{Moment(reminder.createdAt).format("llll")}
												</div>
											);
										});

										const latestReminder =
											data.reminders[data.reminders.length - 1];

										const latestReminderFromNow = Moment(
											latestReminder.createdAt,
										).fromNow();

										return (
											<Button
												className="cursor-default text-wrap text-left"
												variant="link"
												title={title}
											>
												{latestReminderFromNow}
											</Button>
										);
									},
								},
								!preventChanges
									? {
											suppressMenu: true,
											filter: false,
											cellRenderer: "actionsRenderer",
											pinned: "right",
											width: 150,
											cellRendererParams: {
												setName,
											},
									  }
									: {},
							]}
						/>
					</AgGridStyle>
				</Box>
			</Box>
		);
	};

	return (
		<>
			{isGridModalOpen ? (
				<GridModal
					onCancel={onCloseGridModal}
					isOpen={isGridModalOpen}
					rightHeaderComponent={() => {
						return (
							<ExportExcelButton
								floatingFilter={floatingFilter}
								displayFloatingFilter={displayFloatingFilter}
							/>
						);
					}}
					leftHeader={{
						tid: "emissions.invitations.header.invited",
					}}
				>
					<div style={{ height: "100%", width: "100%" }}>{renderAgGrid()}</div>
				</GridModal>
			) : (
				<>{renderAgGrid()}</>
			)}
		</>
	);
};

const Checkbox = (props) => {
	return <MuiCheckbox checked={props.value} />;
};

const NameCell = (props) => {
	return props.data.important ? (
		<Box sx={{ display: "flex" }}>
			<FlagIcon sx={{ mr: 1 }} color={"error"} />
			<Typography>{props.value}</Typography>
		</Box>
	) : (
		<Typography>{props.value}</Typography>
	);
};

const getInterestedLangKey = (value) => {
	return `emissions.invitations.interested.${value}`;
};

const InterestedCell = ({ column: { colId }, data, setValue }) => {
	const value = data[colId];
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const emission = useSelector((state) => state.emissions.current);
	const inviteeId = data.id;

	const bgcolor = value === interestedOption.yes ? "success.light" : "blue.100";
	const color = value === interestedOption.yes ? "success.400" : null;

	return (
		<Select
			size={"small"}
			sx={{
				minWidth: 84,
				"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
					border: (theme) => `1px solid ${theme.palette.divider}`,
				},
				bgcolor,
				color,
				"& legend": {
					display: "none",
				},
				"& fieldset": { top: 0 },
			}}
			value={value}
			onChange={(event) => {
				dispatch(
					updateInterested(
						emission.companyId,
						emission.id,
						inviteeId,
						event.target.value,
					),
				);
				setValue(event.target.value);
			}}
		>
			<MenuItem value={interestedOption.doNotKnow}>
				{t(getInterestedLangKey(interestedOption.doNotKnow))}
			</MenuItem>
			<MenuItem value={interestedOption.yes}>
				{t(getInterestedLangKey(interestedOption.yes))}
			</MenuItem>
			<MenuItem value={interestedOption.no}>
				{t(getInterestedLangKey(interestedOption.no))}
			</MenuItem>
		</Select>
	);
};

const ActionsCell = (props) => {
	const chatBubble = (
		<ChatOutlinedIcon color={props.data.important ? "error" : ""} />
	);
	const emission = useSelector((state) => state.emissions.current);
	const dispatch = useDispatch();
	const inviteeId = props.data.id;

	return (
		<>
			<Box sx={{ display: "flex", flex: "1 1 auto", width: "100%" }}>
				<IconButton
					onClick={() => {
						props.setName(props.data.name);
						dispatch(getNotes(emission.companyId, emission.id, inviteeId));
					}}
				>
					{props.data.important ? (
						<Badge overlap="circular" color={"error"} variant="dot">
							{chatBubble}
						</Badge>
					) : (
						chatBubble
					)}
				</IconButton>
				<InviteeContextMenu
					companyId={emission.companyId}
					emissionId={emission.id}
					inviteeId={inviteeId}
					email={props.data.email}
				/>
			</Box>
		</>
	);
};

export default InvitedPeopleGrid;
