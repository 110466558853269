import React from "react";
import { connect } from "react-redux";
import { object } from "prop-types";
import PublishOrEsignProtocolPanel from "../../../../dumb-components/meetings/publish-or-esign-protocol-panel/publish-or-esign-protocol-panel";
import { changePublishingType } from "../../../../actions/meetings.actions";
import {
	PROTOCOL_PUBLISHING_TYPE_ESIGNING,
	PROTOCOL_PUBLISHING_TYPE_WITHOUT_ESIGNING,
	MEETING_TYPE_STANDARD,
} from "/shared/constants";
import useSubscriptionHelper from "@/hooks/subscriptions/useSubscriptionHelper";

const PublishOrEsignProtocolPanelContainer = ({
	signProtocolPanelComponent,
	publishProtocolPanelComponent,
	publishingType,
	protocolMetadata,
	isModerator,
	isStandardMeeting,
	meetingId,
	changePublishingType,
}) => {
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	const eSigningEnabled = subscriptionHelper?.eSigningEnabled;

	const changeView = (publishingType) => {
		changePublishingType(meetingId, publishingType);
	};

	const renderPublishOrEsignProtocolPanel = !publishingType;

	return (
		<>
			{publishingType === PROTOCOL_PUBLISHING_TYPE_ESIGNING &&
				signProtocolPanelComponent}
			{publishingType === PROTOCOL_PUBLISHING_TYPE_WITHOUT_ESIGNING &&
				publishProtocolPanelComponent}
			{renderPublishOrEsignProtocolPanel && (
				<PublishOrEsignProtocolPanel
					eSigningEnabled={eSigningEnabled}
					protocolMetadata={protocolMetadata}
					onChangeView={changeView}
					isStandardMeeting={isStandardMeeting}
					isModerator={isModerator}
				/>
			)}
		</>
	);
};

PublishOrEsignProtocolPanelContainer.propTypes = {
	signProtocolPanelComponent: object,
	publishProtocolPanelComponent: object,
};

const mapStoreToProps = (store) => {
	return {
		meetingId: store.meetings.getIn(["meeting", "id"]),
		publishingType: store.meetings.getIn(["meeting", "publishingType"], null),
		protocolPublished: Boolean(
			store.meetings.getIn(["meeting", "computedValues", "protocolPublished"]),
		),
		protocolMetadata: store.meetings.getIn(["meeting", "protocolData"]),
		isModerator: store.meetings.getIn([
			"meeting",
			"computedValues",
			"isModerator",
		]),
		isStandardMeeting:
			store.meetings.getIn(["meeting", "meetingType"]) ===
			MEETING_TYPE_STANDARD,
	};
};

const mapActionsToProps = {
	changePublishingType,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(PublishOrEsignProtocolPanelContainer);
