import React from "react";
import useDocumentsFolders from "@/hooks/documents/useDocumentsFolders";
import LoadingPanel from "../loadingPanel";
import TargetFolderTreeView from "@/dumb-components/personal-documents/choose-target-folder-tree-view";
import { fromJS } from "immutable";
import { func } from "prop-types";

const DocumentsFolderPicker = ({ onChange }) => {
	const { data: foldersQuery, isLoading: isLoadingFolders } =
		useDocumentsFolders();

	const folders = foldersQuery?.data;

	return (
		<div className="flex flex-col max-h-[calc(100vh-500px)] overflow-auto gap-6">
			<div className="border rounded-lg p-6">
				{isLoadingFolders ? (
					<LoadingPanel />
				) : (
					<TargetFolderTreeView
						folders={fromJS(folders)}
						onSelectFolder={onChange}
						excludeRootFolder
					/>
				)}
			</div>
		</div>
	);
};

DocumentsFolderPicker.propTypes = {
	onChange: func.isRequired,
};

export default DocumentsFolderPicker;
