import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";
import {
	addErrorNotification,
	addInfoNotification,
} from "@/components/framework/notify";

export default (companyId) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ["updateSubscriptionInvoiceReceiver", companyId],
		mutationFn: async ({ invoiceReceiver, externalInvoiceDetails }) => {
			const response1 = await apiClient.put(
				// Note: the typo in the endpoint
				`subscriptions/invoice-reciever`,
				// Note: Corrects for typo made in backend code `reciever` -> `receiver`
				{ invoiceReciever: invoiceReceiver },
				{ params: { companyId } },
			);

			if (externalInvoiceDetails) {
				// Note: the typo in the endpoint
				return await apiClient.put(
					`subscriptions/invoice-reciever/details`,
					externalInvoiceDetails,
					{ params: { companyId } },
				);
			}

			return response1;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["subscription", companyId],
			});

			addInfoNotification({
				tid: "subscriptions.notifications.success.subscription_updated",
			});
		},
		onError: () => {
			addErrorNotification({ tid: "generic.unknown_error" });
		},
	});
};
