import React from "react";
import { string } from "prop-types";
import Panel from "../../shared/panel/panel";
import BillingDetails from "@/components/subscriptions/billingDetails";
import BillingInvoiceReceiverDetails from "@/components/subscriptions/billingInvoiceReceiverDetails";

const PaymentDetailsPanel = () => (
	<>
		<Panel title="subscriptions.billing_details" marginBottom>
			<div className="grid gap-6 p-6">
				<BillingDetails />
				<BillingInvoiceReceiverDetails />
			</div>
		</Panel>
	</>
);

PaymentDetailsPanel.propTypes = {
	companyId: string,
};

export default PaymentDetailsPanel;
