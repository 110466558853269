import React, { useState } from "react";
import { Mail, MailPlus, User } from "lucide-react";

import useUserComms from "@/hooks/notifications/useUserComms";
import useCreateUserComm from "@/hooks/notifications/useCreateUserComm";

import { Button } from "@/components/ui/button";
import ToolbarMenu from "@/components/toolbarMenu";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import LoadingPanel from "@/components/loadingPanel";
import { ItemList, ItemListColumn, ItemListRow } from "@/components/itemList";
import UserCommEdit from "@/components/userComms/userCommEdit";
import { UserCommStatusBadge } from "./userCommEdit";
import Breadcrumbs from "../breadcrumbs";
import i18n from "@/i18n";

const { t } = i18n;

const UserCommsList = () => {
	const companyId = useCompanyIdFromUrl();

	const { data: userCommsQuery } = useUserComms();
	const userComms = userCommsQuery?.data;

	const [selectedUserComm, setSelectedUserComm] = useState();
	const { mutateAsync: createUserComm, isPending: isCreatingUserComm } =
		useCreateUserComm(companyId);

	const onClickWriteNew = async () => {
		const response = await createUserComm({});
		setSelectedUserComm(response.data.id);
	};

	if (!companyId) {
		return <LoadingPanel />;
	}

	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between">
				<Breadcrumbs
					items={[
						{
							label: (
								<div className="flex items-center gap-2">
									<Mail /> {t("user_comms.title")}
								</div>
							),
						},
					]}
				/>
				<ToolbarMenu>
					<Button isLoading={isCreatingUserComm} onClick={onClickWriteNew}>
						<MailPlus /> {t("user_comms.actions.write_new_user_comm.title")}
					</Button>
				</ToolbarMenu>
			</div>
			<ItemList
				columns={[
					{ id: "subject", label: "Subject" },
					{ id: "status", label: "Status" },
					{ id: "recipients", label: "Recipients" },
					{
						id: "createdAt",
						label: t("esign.advanced_modal.attachents.table.header.date"),
					},
				]}
				columnSizing="1fr 90px 90px 160px"
				rightAlignLastColumn
				zeroStateRenderer={() => "You have no user communications"}
			>
				{userComms?.map((userComm) => (
					<ItemListRow
						isSelected={userComm.id === selectedUserComm}
						key={userComm.id}
						onClick={() => setSelectedUserComm(userComm.id)}
					>
						<ItemListColumn truncate>{userComm.subject}</ItemListColumn>
						<ItemListColumn className="flex items-center gap-2">
							<UserCommStatusBadge status={userComm?.status} size="sm" />
						</ItemListColumn>
						<ItemListColumn className="flex items-center gap-2">
							<User size={16} />
							{userComm.recipients?.length ?? 0}
						</ItemListColumn>
						<ItemListColumn.Date
							displayRelativeFromNow
							className="flex-row-reverse"
						>
							{userComm.createdAt}
						</ItemListColumn.Date>
					</ItemListRow>
				))}
			</ItemList>
			{selectedUserComm && (
				<UserCommEdit
					id={selectedUserComm}
					onCancel={() => setSelectedUserComm(null)}
					onChangeSelectedUserComm={setSelectedUserComm}
				/>
			)}
		</div>
	);
};

export default UserCommsList;
