import React, { Component } from "react";
import { bool, func, string, oneOf, object, oneOfType } from "prop-types";
import DropdownMenu from "../../dumb-components/shared/dropdown-menu/dropdown-menu";
import Box from "@mui/material/Box";

class DropdownMenuContainer extends Component {
	mouseOutTimeout;

	state = {
		isOpen: false,
		inline: false,
		realOpeningDirection: this.props.openDirection,
	};

	static propTypes = {
		btnTid: string,
		btnText: string,
		btnIcon: string,
		onToggleMenu: func,
		onClose: func,
		disabled: bool,
		inline: bool,
		scrollbars: bool,
		halignMenu: oneOf(["left", "right"]),
		btnMode: oneOf(["primary", "transparent-icon", "link"]),
		transparentIconButtonSize: oneOf(["sm", "sml", "normal"]),
		menuNoTop: bool,
		buttonNoHorizontalPadding: bool,
		noDropdownPaddingTop: bool,
		tooltipTid: string,
		tooltipStates: object,
		tooltipActiveState: oneOfType([string, bool]),
		tooltipDelayShow: string,
		noMaxWidth: bool,
		openOnMouseOver: bool,
		withPortal: bool,
		alwaysRenderDropdownItems: bool,
		renderRaw: object,
		btnIconType: oneOf(["solid", "light"]),
		btnIconSize: oneOf(["xs", "sm", "sml"]),
		btnIconColor: string,
		nakedStyle: bool,
		openDirection: oneOf(["up", "down"]),
		onClickOverride: func,
		notificationBadge: bool,
	};

	static defaultProps = {
		disabled: false,
		halignMenu: "left",
		openOnMouseOver: true,
	};

	componentDidMount = () => {
		const { withPortal } = this.props;

		document.addEventListener("mousedown", this.handleMouseDown, false);
		document.addEventListener("mousemove", this.handleMouseMove, false);
		this.node.addEventListener("mouseenter", this.handleMouseEnter, false);
		this.node.addEventListener("mouseover", this.handleMouseOver, false);

		if (withPortal) {
			document.addEventListener("scroll", this.handleScroll, true);
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { onClose } = this.props;

		// Menu Was Closed
		if (
			onClose &&
			prevState.isOpen === true &&
			prevState.isOpen !== this.state.isOpen
		) {
			onClose();
		}
	};

	componentWillUnmount = () => {
		const { withPortal } = this.props;

		document.removeEventListener("mousedown", this.handleMouseDown, false);
		document.removeEventListener("mousemove", this.handleMouseMove, false);
		this.node.removeEventListener("mouseenter", this.handleMouseEnter, false);
		this.node.removeEventListener("mouseover", this.handleMouseOver, false);

		if (withPortal) {
			document.removeEventListener("scroll", this.handleScroll, true);
		}
	};

	handleMouseDown = (e) => {
		const { withPortal } = this.props;

		clearTimeout(this.mouseOutTimeout);
		this.mouseOutTimeout = undefined;

		if (
			withPortal &&
			this.node &&
			!this.node.contains(e.target) &&
			this.menuRef &&
			!this.menuRef.contains(e.target)
		) {
			this.setState({ isOpen: false });
		} else if (!withPortal && this.node && !this.node.contains(e.target)) {
			this.setState({ isOpen: false });
		}
	};

	handleMouseMove = (e) => {
		const { withPortal } = this.props;

		if (!this.state.isOpen || this.mouseOutTimeout) {
			return null;
		}

		this.mouseOutTimeout = setTimeout(() => {
			this.mouseOutTimeout = undefined;

			if (
				withPortal &&
				this.node &&
				!this.node.contains(e.target) &&
				this.menuRef &&
				!this.menuRef.contains(e.target)
			) {
				this.setState({ isOpen: false });
			} else if (!withPortal && this.node && !this.node.contains(e.target)) {
				this.setState({ isOpen: false });
			}
		}, 100);
	};

	// Only attached when withPortal is true
	handleScroll = () => {
		if (this.state.isOpen) {
			this.setState({ isOpen: false });
		}
	};

	handleMouseEnter = ({ target }) => {
		const { children } = this.props;

		let padding = 16;
		let childHeight = 36;
		let amount = children?.props?.children?.length;
		let sum = padding + childHeight * amount;

		if (window.innerHeight - target.getClientRects()[0].bottom < sum) {
			this.setState({ realOpeningDirection: "up" });
		} else {
			this.setState({ realOpeningDirection: "down" });
		}
	};

	handleMouseOver = (e) => {
		const { disabled, openOnMouseOver } = this.props;

		if (
			!disabled &&
			!this.state.isOpen &&
			this.node &&
			this.node.contains(e.target) &&
			openOnMouseOver
		) {
			this.setState({ isOpen: true });
		}
	};

	onToggleMenu = (e) => {
		const { onToggleMenu } = this.props;

		e && e.stopPropagation();
		e && e.preventDefault();

		this.setState((prevState) => {
			onToggleMenu && onToggleMenu(!prevState.isOpen);
			return { isOpen: !prevState.isOpen };
		});
	};

	render = () => {
		const {
			children,
			tooltipActiveState,
			tooltipStates,
			btnTid,
			btnText,
			btnIcon,
			disabled,
			inline,
			halignMenu,
			btnMode,
			transparentIconButtonSize,
			menuNoTop,
			buttonNoHorizontalPadding,
			noDropdownPaddingTop,
			buttonMinWidth,
			tooltipTid,
			tooltipDelayShow,
			noMaxWidth,
			withPortal,
			openDirection,
			alwaysRenderDropdownItems,
			renderRaw,
			btnIconType,
			btnIconSize,
			btnIconColor,
			nakedStyle,
			onClickOverride,
			notificationBadge,
			scrollbars,
		} = this.props;
		const { isOpen, realOpeningDirection } = this.state;

		return (
			<Box sx={{ display: "inline-block" }}>
				<DropdownMenu
					onSetRef={(node) => (this.node = node)}
					onSetMenuRef={withPortal && ((menuRef) => (this.menuRef = menuRef))}
					onToggleMenu={onClickOverride ? onClickOverride : this.onToggleMenu}
					isOpen={isOpen}
					btnTid={btnTid}
					btnText={btnText}
					btnIcon={btnIcon}
					btnIconSize={btnIconSize}
					btnIconType={btnIconType}
					btnIconColor={btnIconColor}
					transparentIconButtonSize={transparentIconButtonSize}
					noDropdownPaddingTop={noDropdownPaddingTop}
					disabled={disabled}
					inline={inline}
					halignMenu={halignMenu}
					btnMode={nakedStyle ? "transparent-icon" : btnMode}
					menuNoTop={menuNoTop}
					buttonNoHorizontalPadding={buttonNoHorizontalPadding}
					buttonMinWidth={buttonMinWidth}
					tooltipTid={tooltipTid}
					tooltipStates={tooltipStates}
					tooltipDelayShow={tooltipDelayShow}
					noMaxWidth={noMaxWidth}
					tooltipActiveState={tooltipActiveState}
					direction={openDirection ?? realOpeningDirection}
					renderRaw={renderRaw}
					position={withPortal ? "fixed" : "absolute"}
					parentRects={withPortal && this.node && this.node.getClientRects()[0]}
					clientRects={
						withPortal && this.menuRef && this.menuRef.getClientRects()[0]
					}
					alwaysRenderDropdownItems={alwaysRenderDropdownItems}
					withPortal={withPortal}
					notificationBadge={notificationBadge}
					scrollbars={scrollbars}
				>
					{children}
				</DropdownMenu>
			</Box>
		);
	};
}

export default DropdownMenuContainer;
