import { useQuery } from "@tanstack/react-query";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import apiClient from "@/modules/request.module.v2";

export default (queryOptions) => {
	const companyId = useCompanyIdFromUrl();

	const query = useQuery({
		...queryOptions,
		queryKey: ["shareholders", companyId],
		queryFn: () => {
			return apiClient.get(
				"shares/company/investments?excludeOwnCompany=false=false",
				{
					headers: {
						"x-company-id": companyId,
					},
				},
			);
		},
	});

	return query;
};
