// @ts-check
import React, { useState } from "react";
import { cloneElement, isValidElement } from "react";
import useSubscriptionHelper from "./useSubscriptionHelper";
import UpgradePlanDialog from "@/components/upgradePlanDialog";
import { Lock } from "lucide-react";
import useCountInvestors from "../shares/useCountInvestors";
import useSubscriptionPlans from "@/hooks/subscriptions/useSubscriptionPlans";

// This should eventually use Stripe's Entitlements API
const FREE_PLAN = [
	"documents-basic",
	"share-register-basic",
	"share-register-basic",
];
const LITE_PLAN = [
	...FREE_PLAN,
	"shareholders-export-excel",
	"shareholders-export-owner-report",
	"share-register-export-excel",
	"share-register-export-pdf",
	"advertising-basic",
	"e-signing-basic",
];
const STANDARD_PLAN = [...LITE_PLAN, "meetings-basic", "meetings-agm"];
const PREMIUM_PLAN = [...STANDARD_PLAN, "meetings-smart", "share-issues-basic"];

const plansByFeature = PREMIUM_PLAN.reduce(
	(acc, feature) => ({
		...acc,
		[feature]: {
			// The key order is important here
			free: FREE_PLAN.includes(feature),
			lite: LITE_PLAN.includes(feature),
			standard: STANDARD_PLAN.includes(feature),
			premium: true,
		},
	}),
	{},
);

const useRestrictAccess = (companyId) => {
	const [countInvestorsEnabled, setCountInvestorsEnabled] = useState(false);

	const { data: countInvestorsQuery, isLoading: isLoadingInvestorsCounter } =
		useCountInvestors({ enabled: countInvestorsEnabled, retry: false });
	const currentNumberOfInvestors = countInvestorsQuery?.data;

	const { data: subscriptionPlansQuery } = useSubscriptionPlans();
	const subscriptionPlans = subscriptionPlansQuery?.data;

	const {
		data: subscriptionHelperQuery,
		isLoading: isLoadingSubscriptionHelper,
	} = useSubscriptionHelper(companyId);
	const subscriptionHelper = subscriptionHelperQuery?.data;

	const [restrictDialogElement, setRestrictDialogElement] = useState(null);

	/**
	 *
	 * @param {JSX.Element} subject
	 * @param {object} options
	 * @param {string} options.requiredFeature
	 * @param {function} [options.onModalOpen]
	 * @returns {JSX.Element}
	 */
	const restrict = (subject, options) => {
		// Don't render until we can determine if the user has access
		if (isLoadingSubscriptionHelper) {
			return null;
		}

		if (
			options?.requiredFeature === "share-register-basic" &&
			!isLoadingInvestorsCounter &&
			!countInvestorsEnabled
		) {
			setCountInvestorsEnabled(true);
		}

		const userPlan = subscriptionHelper?.account;

		let hasAccess = true;
		let requiredPlan = "free";

		if (options?.requiredFeature) {
			hasAccess = plansByFeature[options.requiredFeature][userPlan];

			requiredPlan =
				(plansByFeature[options.requiredFeature].free && "free") ||
				(plansByFeature[options.requiredFeature].lite && "lite") ||
				(plansByFeature[options.requiredFeature].standard && "standard") ||
				(plansByFeature[options.requiredFeature].premium && "premium");
		}

		if (
			options?.requiredFeature === "share-register-basic" &&
			!!currentNumberOfInvestors &&
			!!subscriptionPlans
		) {
			hasAccess =
				currentNumberOfInvestors?.totalNumOfInvestors <
				subscriptionHelper?.maxNumberOfShareholders;

			requiredPlan =
				subscriptionPlans?.account?.month?.find?.(
					(p) =>
						Number(p.metadata?.maxNumberOfShareholders) >
						currentNumberOfInvestors?.totalNumOfInvestors,
				)?.metadata?.plan ?? "premium";
		}

		if (hasAccess) return subject;

		if (isValidElement(subject)) {
			// clone the element and add the parameters
			return cloneElement(
				subject,
				{
					onClick: async (evt) => {
						evt.preventDefault(); // Used for components that may have additional onClick handlers attached

						options?.onModalOpen?.();

						setRestrictDialogElement(
							<UpgradePlanDialog
								feature={options.requiredFeature}
								requiredPlan={requiredPlan}
								onCancel={() => setRestrictDialogElement(null)}
							/>,
						);
					},
				},
				<div className="flex gap-2 items-center">
					{subject.props.children}
					<Lock size={16} className="text-orange-300" />
				</div>,
			);
		}
	};

	return { restrict, restrictDialogElement };
};

export default useRestrictAccess;
