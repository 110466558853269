import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";
import {
	addErrorNotification,
	addInfoNotification,
} from "@/components/framework/notify";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";

export default (referenceType, referenceId) => {
	const companyId = useCompanyIdFromUrl();
	const queryClient = useQueryClient();

	if (
		!["userComm"].includes(referenceType) ||
		typeof referenceId !== "string"
	) {
		throw new Error(
			`useCreateSendEmailJobs: Invalid referenceType or referenceId (${referenceType}, ${referenceId})`,
		);
	}

	return useMutation({
		mutationKey: ["createSendEmailJobs", { referenceType, referenceId }],
		mutationFn: (jobs) => {
			return apiClient.post(
				`notifications/send-email-jobs/${referenceType}/${referenceId}`,
				{ jobs },
				{ headers: { "x-company-id": companyId } },
			);
		},
		onSuccess: () => {
			switch (referenceType) {
				case "userComm":
					queryClient.invalidateQueries(["userComm", referenceId]);
					break;
				default:
					break;
			}

			addInfoNotification({
				tid: "success",
			});
		},
		onError: () => {
			addErrorNotification({ tid: "generic.unknown_error" });
		},
	});
};
