import React from "react";
import { connect } from "react-redux";
import { string } from "prop-types";
import { createInvestor } from "../../actions/investors.actions";
import history from "../../interfaces/history";
import useRestrictAccess from "@/hooks/subscriptions/useRestrictAccess";
import { UserPlus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";

const ShareholdersAddShareholderBtnContainer = ({
	basePath,
	onClick,
	createInvestor,
	history,
	hasAppliedFilters,
	location,
}) => {
	const { restrict, restrictDialogElement } = useRestrictAccess();
	const { t } = useTranslation();

	const handleOnClickNewInvestor = () => {
		onClick && onClick();
		createInvestor({}, (e, investor) => {
			if (!e) {
				history.push(`${basePath}/${investor.get("id")}`);
			}
		});
	};

	const isAlreadyActive = location.pathname.endsWith("/new");
	let tooltip = t("tooltip.shares.actions.add_shareholder");

	if (hasAppliedFilters) {
		tooltip = t(
			"tooltip.shares.shareholders.action.add_shareholder.active_filters",
		);
	} else if (isAlreadyActive) {
		tooltip = t(
			"tooltip.shares.shareholders.action.add_shareholder.already_active",
		);
	}

	return (
		<>
			{restrict(
				<Button
					variant="ghost"
					disabled={hasAppliedFilters || isAlreadyActive}
					title={tooltip}
					onClick={handleOnClickNewInvestor}
				>
					<UserPlus className="text-gray-600" strokeWidth={1.5} />
				</Button>,
				{
					requiredFeature: "share-register-basic",
				},
			)}
			{restrictDialogElement}
		</>
	);
};

ShareholdersAddShareholderBtnContainer.propTypes = {
	basePath: string,
};

const mapStoreToProps = (store) => {
	return {
		history: history,
		hasAppliedFilters: store.investors.get("hasAppliedFilters"),
	};
};

const mapActionsToProps = {
	createInvestor,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ShareholdersAddShareholderBtnContainer);
