import React from "react";
import { loadStripe } from "@stripe/stripe-js";

import { Elements } from "@stripe/react-stripe-js";

import useStripePublicApiKey from "@/hooks/subscriptions/useStripePublicApiKey";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const style = getComputedStyle(document.body);

const appearance = {
	variables: {
		fontFamily: style.getPropertyValue("--font-sans"),
		borderRadius: "0px",
		spacingUnit: "6px",
		colorText: `hsl(${style.getPropertyValue("--foreground")})`,
	},
	rules: {
		".Input": {
			boxShadow: "none",
			borderColor: `hsl(${style.getPropertyValue("--input")})`,
			padding: "10px",
			fontSize: "13px",
		},
		".Input:focus": {
			boxShadow: "none",
			borderRadius: "1px",
			outline: `hsl(${style.getPropertyValue("--ring")}) solid 2px`,
			outlineOffset: "2px",
			borderColor: `hsl(${style.getPropertyValue("--input")})`,
		},
		".Label": {
			fontSize: "13px",
		},
	},
};
const StripeElements = ({ setupIntent, children }) => {
	const [stripePromise, setStripePromise] = useState(null);

	const { data: stripeApiKeyRequest } = useStripePublicApiKey();
	const stripeApiKey = stripeApiKeyRequest?.data;

	useEffect(() => {
		stripeApiKey?.length > 0 && setStripePromise(loadStripe(stripeApiKey));
	}, [stripeApiKey]);

	const { i18n } = useTranslation();
	const locale = i18n.language?.split("-").shift(); // format the language to match the stripe locale format (https://docs.stripe.com/js/appendix/supported_locales)

	return (
		<Elements
			stripe={stripePromise}
			options={{
				// passing the client secret obtained from the server
				clientSecret: setupIntent.client_secret,
				appearance,
				locale,
			}}
		>
			{children}
		</Elements>
	);
};

export default StripeElements;
