import React from "react";
import { connect } from "react-redux";
import { func } from "prop-types";
import { closeModal } from "../../actions/modals.actions";
import { INSUFFICIENT_FUNDS_DOCUMENT_ESIGN_MODAL } from "../../constants/modals";

import history from "../../interfaces/history";
import NoFundsEsignModal from "../../dumb-components/documents/no-funds-esign-modal/no-funds-esign-modal";

import useSubscriptionHelper from "@/hooks/subscriptions/useSubscriptionHelper";

const NoFundsEsignModalContainer = ({
	onSignWithEmail,
	closeModal,
	companyUrlAlias,
	history,
	isOpen,
}) => {
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	const isSubscriptionAdmin = !!subscriptionHelper?.isSubscriptionAdmin;

	const onGoToSubscription = () => {
		closeModal();
		history.push(`/${companyUrlAlias}/subscriptions/`);
	};

	const onSignWithEmailHandler = () => {
		closeModal();
		onSignWithEmail && onSignWithEmail();
	};

	const onCancel = () => {
		closeModal();
	};

	// Don't render if not needed
	if (!isOpen) {
		return null;
	}

	return (
		<NoFundsEsignModal
			isOpen
			onSignWithEmail={onSignWithEmailHandler}
			onGoToSubscription={onGoToSubscription}
			onCancel={onCancel}
			isSubscriptionAdmin={isSubscriptionAdmin}
		/>
	);
};

NoFundsEsignModalContainer.propTypes = {
	onSignWithEmail: func,
};
const mapStoreToProps = (store) => {
	return {
		history: history,
		isOpen:
			store.modals.getIn(["activeModal", "name"]) ===
			INSUFFICIENT_FUNDS_DOCUMENT_ESIGN_MODAL,
		companyUrlAlias: store.company.company && store.company.company.urlAlias,
	};
};

const mapActionsToProps = {
	closeModal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(NoFundsEsignModalContainer);
