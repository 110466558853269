import React from "react";
import { bool, func, string } from "prop-types";
import styled from "styled-components";
import Dropzone from "../../shared/dropzone/dropzone";
import Text from "../../shared/text/text";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { CloudUpload } from "lucide-react";

const DocumentUploadWrapper = styled.div``;

const FullScreenWrapper = styled.div`
	top: 0;
	left: 0;
	z-index: 100;
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: ${(props) => props.theme.colors.java};
	opacity: 0.8;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
`;

const DocumentUpload = ({
	isOpen,
	onClickBtnOpen,
	name,
	multiple,
	onDrop,
	dropzoneRef,
	hasPermissionsToUpload,
	hasAppliedFilters,
	isSharedFolder,
	sharedByCompanyName,
	isRootSharedFolder,
}) => {
	const { t } = useTranslation();

	const dropzoneProps = {
		name,
		multiple,
		onDrop,
		dropzoneRef,
	};

	const fullscreenMessageTid = hasAppliedFilters
		? "documents.fullscreen-upload.info.disabled-by-filters"
		: "documents.fullscreen-upload.info";
	const uploadBtnDisabled = !hasPermissionsToUpload || hasAppliedFilters;

	let tooltipText;

	if (isRootSharedFolder) {
		tooltipText = t(
			"tooltip.documents.action.upload_document.folder_is_root_shared_folder",
		);
	} else if (!hasPermissionsToUpload && isSharedFolder) {
		tooltipText = t("tooltip.default.has_no_permissions_shared_company", {
			companyName: sharedByCompanyName,
		});
	} else if (!hasPermissionsToUpload) {
		tooltipText = t("tooltip.default.has_no_permissions");
	} else if (hasAppliedFilters) {
		tooltipText = t("tooltip.documents.cant_upload_with_filters");
	}

	return (
		<DocumentUploadWrapper id="intro-step2">
			<Button
				title={tooltipText}
				className="mx-2"
				onClick={onClickBtnOpen}
				disabled={uploadBtnDisabled}
			>
				<CloudUpload />
				{t("upload")}
			</Button>

			<Dropzone {...dropzoneProps} size="full-screen" hidden={true} />

			{isOpen && hasPermissionsToUpload && (
				<Dropzone
					{...dropzoneProps}
					size="full-screen"
					readOnly={hasAppliedFilters}
				>
					<FullScreenWrapper>
						<Text tag="h1" tid={fullscreenMessageTid} color="white" />
					</FullScreenWrapper>
				</Dropzone>
			)}
		</DocumentUploadWrapper>
	);
};

DocumentUpload.defaultProps = {
	isOpen: false,
	hasPermissionsToUpload: false,
	hasAppliedFilters: false,
};

DocumentUpload.propTypes = {
	isOpen: bool,
	onClickBtnOpen: func,
	dropzoneRef: func,
	hasPermissionsToUpload: bool,
	hasAppliedFilters: bool,
	isSharedFolder: bool,
	sharedByCompanyName: string,
	isRootSharedFolder: bool,
};

export default DocumentUpload;
