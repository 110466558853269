import { useQuery } from "@tanstack/react-query";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import apiClient from "@/modules/request.module.v2";

export default (id) => {
	const companyId = useCompanyIdFromUrl();

	return useQuery({
		enabled: !!id,
		queryKey: ["userComm", id],
		queryFn: () => {
			return apiClient.get(`notifications/user-comms/${id}`, {
				headers: {
					"x-company-id": companyId,
				},
			});
		},
	});
};
