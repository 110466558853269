import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";
import {
	addErrorNotification,
	addInfoNotification,
} from "@/components/framework/notify";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";

export default (userCommId) => {
	const companyId = useCompanyIdFromUrl();
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ["updateUserComm", userCommId],
		mutationFn: (payload) => {
			return apiClient.put(`notifications/user-comms/${userCommId}`, payload, {
				headers: { "x-company-id": companyId },
			});
		},
		onSuccess: (mutationResponse) => {
			queryClient.setQueryData(["userComm", userCommId], {
				data: mutationResponse.data,
			});

			queryClient.setQueryData(["userComms", companyId], (oldData) => ({
				data: oldData.data.map((userComm) =>
					userComm.id === userCommId ? mutationResponse.data : userComm,
				),
			}));

			addInfoNotification({
				tid: "user_comms.notifications.user_comm_saved",
			});
		},
		onError: () => {
			addErrorNotification({ tid: "generic.unknown_error" });
		},
	});
};
