import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";
import {
	addErrorNotification,
	addInfoNotification,
} from "@/components/framework/notify";

export default () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ["updateUnsubscribeGroups"],
		mutationFn: (payload) => {
			return apiClient.put(
				`notifications/marketing/unsubscribe-groups`,
				payload,
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries("unsubscribeGroups");

			addInfoNotification({
				tid: "user.notification_preferences.notify.update.success",
			});
		},
		onError: () => {
			addErrorNotification({ tid: "generic.unknown_error" });
		},
	});
};
