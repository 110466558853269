import { useQuery } from "@tanstack/react-query";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import apiClient from "@/modules/request.module.v2";

export default (sendEmailJobId) => {
	const companyId = useCompanyIdFromUrl();

	return useQuery({
		enabled: !!sendEmailJobId && !!companyId,
		queryKey: ["sendEmailJob", sendEmailJobId],
		queryFn: () => {
			return apiClient.get(`notifications/send-email-jobs/${sendEmailJobId}`, {
				headers: {
					"x-company-id": companyId,
				},
			});
		},
	});
};
