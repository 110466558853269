import React from "react";
import { connect } from "react-redux";
import { func, string } from "prop-types";
import Button from "../../../../../dumb-components/shared/button/button";
import Text from "../../../../../dumb-components/shared/text/text";
import history from "../../../../../interfaces/history";
import useSubscriptionHelper from "@/hooks/subscriptions/useSubscriptionHelper";

const ButtonGoToBillingContainer = ({ onDone, tid, companyUrlAlias }) => {
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	const isSubscriptionAdmin = !!subscriptionHelper?.isSubscriptionAdmin;

	const goToSubscription = () => {
		history.push(`/${companyUrlAlias}/subscriptions/`);
		onDone && onDone();
	};

	if (!isSubscriptionAdmin) {
		return null;
	}

	return (
		<Button mode="primary" onClick={goToSubscription}>
			<Text tid={tid} color="white" nowrap />
		</Button>
	);
};

ButtonGoToBillingContainer.propTypes = {
	onDone: func,
	tid: string,
};

ButtonGoToBillingContainer.defaultProps = {
	tid: "onboarding.modals.shared.button.go_to_billing",
};

const mapStoreToProps = (store) => {
	return {
		history: history,
		companyUrlAlias: store.company.company?.urlAlias,
	};
};

export default connect(mapStoreToProps, {})(ButtonGoToBillingContainer);
