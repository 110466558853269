import { useQuery } from "@tanstack/react-query";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import apiClient from "@/modules/request.module.v2";

export default () => {
	const companyId = useCompanyIdFromUrl();

	return useQuery({
		queryKey: ["userComms", companyId],
		queryFn: () => {
			return apiClient.get(`notifications/user-comms`, {
				headers: {
					"x-company-id": companyId,
				},
			});
		},
	});
};
