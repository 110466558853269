import { useQuery } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";

export default (companyId) => {
	return useQuery({
		queryKey: ["company", companyId],
		queryFn: () => {
			return apiClient.get(`companies/${companyId}`);
		},
	});
};
