import React, { PureComponent } from "react";
import { func, number, bool, array, shape, string } from "prop-types";
import styled from "styled-components";

import ScrollView from "../../shared/layout/scroll-view/scroll-view";
import Text from "../../shared/text/text";
import Checkbox from "../../shared/checkbox/checkbox";
import Box from "../../shared/layout/box/box";
import Button from "../../shared/button/button";
import SkipAuthorise from "./skip-authorise";
import RepresentativesTable from "../../../components/user/account-wizard/representatives-table";

import i18n from "@/i18n";

const StyledWrapper = styled.div`
	height: 100%;
`;

const StyledStep = styled.div`
	opacity: ${(props) => (props.visible ? 1 : 0)};
	transition: opacity 0.3s ease-in;
	padding-bottom: ${(props) => props.theme.spacing[5]};
`;

const NumberingWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${(props) => props.theme.spacing[4]};

	h3 {
		margin-top: 0;
		margin-bottom: 0;
	}
`;

const NumberingNumber = styled.div`
	height: 40px;
	width: 40px;
	border-radius: 50%;
	background: ${(props) => props.theme.colors.java};
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: ${(props) => props.theme.spacing[4]};
`;

const SectionWrapper = styled.div`
	padding-left: 56px;
`;

const StyledWrapperInner = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]}
		${(props) => props.theme.spacing[6]};
`;

const RepresentativesTableWrapper = styled.div`
	display: flex;
	gap: ${(props) => props.theme.spacing[4]};
	flex-direction: column;
`;

class CompanyWizard extends PureComponent {
	static propTypes = {
		isInvonoAdmin: bool,
		adminOverrideData: shape({
			companyName: string,
			companyOrgNumber: string,
			companyCountry: string,
		}),
		step: number,
		renderSearch: func,
		onChange: func,
		ToS: bool,
		onAuthorize: func,
		onSetScrollbarRef: func,
		allowedRepresentativesToSign: array,
		onSkipAuthorise: func,
		onVerifyToCustomer: func,
	};

	static defaultProps = {
		step: 1,
	};

	renderStepNumber = (number, titleTid) => {
		return (
			<NumberingWrapper>
				<NumberingNumber>
					<Text tag="h3" color="lightestGrey">
						{number}
					</Text>
				</NumberingNumber>
				<Text tag="h3" tid={titleTid} />
			</NumberingWrapper>
		);
	};

	renderToS = () => {
		const { onChange, ToS } = this.props;

		const agreementUrl = !i18n?.language?.startsWith("sv")
			? "https://invono.se/en-US/legal/company-account-agreement"
			: "https://invono.se/sv/legal/company-account-agreement";

		return (
			<Box valign="center">
				<Checkbox
					mode="modern-big"
					onChange={onChange}
					fieldName="ToS"
					checked={ToS}
				/>
				&nbsp;&nbsp;
				<Text tid="account.wizard.accept.agreement.part.1" />
				&nbsp;
				<a href={agreementUrl} target="_blank" rel="noopener noreferrer">
					<Text
						color="muted"
						tid="account.wizard.accept.agreement.part.2"
						hasUnderline
					/>
				</a>
			</Box>
		);
	};

	renderStep = (stepNumber, titleTid, component) => {
		const { onSetStepRef, step } = this.props;

		return (
			<StyledStep
				visible={step >= stepNumber}
				ref={onSetStepRef.bind(null, stepNumber)}
			>
				<NumberingWrapper>
					<NumberingNumber>
						<Text tag="h3" color="lightestGrey">
							{stepNumber}
						</Text>
					</NumberingNumber>
					<Text tag="h3" tid={titleTid} />
				</NumberingWrapper>

				<SectionWrapper>{component}</SectionWrapper>
			</StyledStep>
		);
	};

	render = () => {
		const {
			isInvonoAdmin,
			onSetScrollbarRef,
			renderSearch,
			onAuthorize,
			allowedRepresentativesToSign,
			onSkipAuthorise,
			onVerifyToCustomer,
		} = this.props;

		return (
			<StyledWrapper>
				<ScrollView
					scrollbarRef={onSetScrollbarRef}
					noRightMargin
					noLeftMargin
					fillContent
					alwaysShow
				>
					<StyledWrapperInner>
						{this.renderStep(1, "company_wizard.step.1.title", renderSearch())}

						{this.renderStep(
							2,
							"company_wizard.step.3.title",
							this.renderToS(),
						)}
						{this.renderStep(
							3,
							"company_wizard.step.4.title",
							<>
								<RepresentativesTableWrapper>
									<Text tid="company_wizard.step.4.description" />
									{allowedRepresentativesToSign?.length && (
										<RepresentativesTable
											companyRepresentatives={allowedRepresentativesToSign}
										/>
									)}
								</RepresentativesTableWrapper>
								<Button
									mode="primary"
									tid="company_wizard.step.4.btn.verify_with_bankid"
									onClick={onAuthorize}
								/>
								{isInvonoAdmin && (
									<SkipAuthorise
										onSkipAuthorise={onSkipAuthorise}
										onVerifyToCustomer={onVerifyToCustomer}
										marginTop={true}
									/>
								)}
							</>,
						)}
					</StyledWrapperInner>
				</ScrollView>
			</StyledWrapper>
		);
	};
}

export default CompanyWizard;
