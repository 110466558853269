import { createTheme } from "@mui/material/styles";
//  https://mui.com/customization/default-theme/

//! MISC _________________________________________________________________________________

/* START STORYBOOK
 * 	 Go to platform/services/web-client and run: npm run start:storybook
 */

/* ICONS GENERAL
 * 	 Font Awesome version 5!
 ** 	 OBS! Not able to use icons from version 6
 */

/* BUTTONS GENERAL
 * 	 "Positive" (Next step, Continue, Confirm) button placed to the left of f.ex. "Cancel" or "Close"
 **	 If tooltip is needed for an disabled Mui-button you need to use our own button in app/mui-components/button
 */

/* FILE ICONS
 * 	 We use react-file-icon to render correct extension and icon for files
 **	 Check out modern-file-preview.js
 *	 file-preview.js also uses the modern-file-preview but without the wrapping gui
 **	 implementation example in publicListItem.js, (search the document for " FilePreview ")
 */

const fontWeights = {
	normal: 400,
	semiBold: 500,
	bold: 600,
};

const breakpoints = {
	values: {
		xxs: 0,
		xs: 320,
		sm: 576,
		md: 768,
		lg: 992,
		xl: 1200,
	},
};

//  CUSTOM COLORS
const palette = {
	//	(dark blue) used on nav
	primary: {
		main: "#1F3239",
	},
	//	(java) branding color
	secondary: {
		main: "#339999",
	},
	//	(orange) used for warningblockas & meeting/task status
	warning: {
		main: "#D7A741",
	},
	//	(red)
	error: {
		main: "#F02809", //#EF5350 instead?
	},
	//	(green)
	success: {
		main: "#00B651",
		mint: "#B7F7C9",
	},
	//	(bootstrap blue)
	positive: {
		main: "#369FF4",
		hover: "#1690f3",
	},
	default: {
		main: "#464646",
		hover: "#464646",
	},
	dark: {
		card: {
			//	(dark.card) used for dark info blocks
			main: "#41585F",
		},
	},
	//	LEGACY
	grey: {
		light: "#f9f9f9",
		main: "var(--foreground)",
		mid: "#A5A5A5",
	},
	button: {
		main: "#f9f9f9",
		hover: "#e8e8e8",
		disabled: "#CED1D6",
		hbgStyle: "#205F92",
	},
	solitude: {
		light: "#ecf0f5",
		main: "#e3ebf1",
		dark: "#d9e1e7",
		midLight: "#FAFAFA",
		darker: "#E2EBF1",
	},
	chart: {
		primary: "#339C9C",
		secondary: "#C253A4",
		tertiary: "#3F8CFB",
		quaternary: "#967359",
		complementary: "#C7A228",
	},
	highlight: {
		off: "transparent",
		yellow: {
			main: "#FBFBEA",
			dark: "#F5F5E4",
		},
		blue: {
			main: "#D9EDFD",
		},
	},
	//  COLOR TEXT
	text: {
		//	(gray) used almost everywhere
		primary: "hsl(212deg 18.52% 31.76%)", // should equal var(--foreground)
		//	(white) used on dark/warning cards
		secondary: "#FFFFFF",
		//	(green)
		success: "#00B651",
		//	(bootstrap blue) used as button text
		positive: "#369FF4",
		// Used on input value. To highlight that is filled out.
		primaryDarker: "#333",
	},
	border: {
		grey: "#c3c3c3",
	},
};

/* lineHeights: {
		default: 1.43,
		h1: 1,
		h2: 1.29,
		h3: 'normal',
		h4: 'normal'
	}, */

//	Normally only uses h4, body1 and body2
const typography = {
	fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
	htmlFontSize: 10, //base fontSize
	fontSize: 13,

	h1: {
		fontSize: "3.6rem", //36px
		lineHeight: 1,
		fontWeight: "600",
	},
	h2: {
		fontSize: "2.8rem", //28px
		lineHeight: 1.29,
		fontWeight: "600",
	},
	h3: {
		fontSize: "2.2rem", //22px
		lineHeight: "normal",
		fontWeight: "600",
	},
	h4: {
		fontSize: "1.8rem", //18px	used for headers
		lineHeight: "normal",
		letterSpacing: "0.0175em",
		fontWeight: "600",
	},
	h5: {
		fontSize: "1.8rem", //18px
		lineHeight: "1.2em",
		letterSpacing: "0.02938em",
		fontWeight: "600",
	},
	h6: {
		fontSize: "1.4rem", //14px
		lineHeight: "1.2em",
		letterSpacing: "0.02938em",
		fontWeight: "600",
	},
	subtitle1: {
		fontSize: "1.4rem", //14px
		fontWeight: "600",
	},
	subtitle2: {
		fontSize: "1.4rem", //14px
	},
	subtitle3: {
		fontSize: "1.4rem", //14px
		fontWeight: "700",
	},
	body1: {
		fontSize: "1.3rem", //13px	(bold body text) used on body
		letterSpacing: "0.02938em",
		fontWeight: "600",
	},
	body2: {
		fontSize: "1.3rem", //13px	(normal body text) used on body
	},
	body2Italic: {
		fontSize: "1.3rem", //13px	(normal body text) used on body
		letterSpacing: "0.05071em",
		fontStyle: "italic",
	},
	button: {
		fontSize: "1.6rem !important", //16px
		fontWeight: "600",
		lineHeight: "1.43",
		textTransform: "none",
	},
	caption: {},
	overline: {
		fontSize: "1rem", //10px
		fontWeight: "400",
	},
	small: {
		fontSize: "1.2rem",
		fontWeight: "400",
	},
	hbgStyle: {
		margin: 0,
		fontSize: "1.8rem",
		lineHeight: "1.2em",
		letterSpacing: "0.02938em",
		fontWeight: "600",
	},
	link: {
		fontSize: "1.3rem",
		color: palette.positive.main,
		textDecoration: "underline", //13px	(normal body text) used on body
	},
};

//  https://mui.com/customization/theme-components/#adding-new-component-variants
//  OVERRIDES
const components = {
	/*  Card
	 *   variants: dark/warning
	 **	The warningCard has a warning-triangle icon added as background rendering import of icon redundant
	 *   ex: <Card variant="dark">
	 */
	//	!notice that if you add borderRadius as prop you get borderRadius on the Card
	MuiCard: {
		styleOverrides: {
			root: () => ({
				border: "1px solid hsl(var(--input) / 0.75)",
				boxShadow: "rgba(0, 0, 0, 0.1) 0 2px 2px",
				overflow: "visible", // needed to show the ag-menu
				borderRadius: "var(--radius)",
			}),
		},
		variants: [
			//	(dark background) used to explain currently shown content or meaning of wrapped buttons
			{
				props: { variant: "dark" },
				style: {
					backgroundColor: palette.dark.card.main,
					color: palette.text.secondary,
					boxShadow: "none",
					border: "none",
				},
			},
			//	(orange background) used to show warnings
			{
				props: { variant: "warning" },
				style: {
					backgroundColor: palette.warning.main,
					color: palette.text.secondary,
					backgroundImage: `url(/assets/build/icons/warning.svg)`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "top 16px left 16px",
					paddingLeft: "40px",
				},
			},
			//	(no background) used on the investment-general.container.js
			{
				props: { variant: "naked" },
				style: {
					backgroundColor: "transparent",
					color: palette.text.primary,
				},
			},
			//	(background solitude) used on the investment-general.container.js
			{
				props: { variant: "solitude" },
				style: {
					backgroundColor: palette.solitude.main,
					color: palette.text.primary,
				},
			},
			{
				props: { variant: "yellow" },
				style: {
					backgroundColor: palette.highlight.yellow.main,
					color: palette.text.primary,
				},
			},
			{
				props: { variant: "blue" },
				style: {
					backgroundColor: palette.highlight.blue.main,
					color: palette.text.primary,
				},
			},
		],
	},
	// Card with CardHeader are ment to replace our legacy "Panel". example: company-board-grid.container.js
	MuiTypography: {
		styleOverrides: {
			root: {
				"&.MuiCardHeader-title > *": {
					fontSize: "18px",
					fontWeight: "600",
					letterSpacing: "normal",
					lineHeight: 1.55,
				},
			},
			gutterBottom: {
				marginBottom: "10px",
			},
		},
	},
	/*  CardHeader
	 **   Needs to be used with our "Text" component to recieve the right height if it is used without buttonicons on the right
	 */
	MuiCardHeader: {
		styleOverrides: {
			root: (props) => ({
				borderBottom: props.noBorder ? undefined : "1px solid rgba(0,0,0,.07)",
				padding:
					props.extraPadding || props.extrapadding
						? "24px 24px 0 24px"
						: "10.5px 24px",
			}),
		},
		//!created a new variant feels wrong with a prop thats named extraPadding which removes one of them
		variants: [
			{
				props: { variant: "noBorder" },
				style: {
					borderBottom: "none",
					padding: "24px 24px 0 24px",
				},
			},
		],
	},
	/*  CardContent
	 *   variants: flex/panel
	 **  Made for cards with icons with standardized margins
	 *	variant panel is made for Cards used to display AG-Grid and only got inline padding
	 **   ex: <CardContent variant="flex">
	 */
	MuiCardContent: {
		styleOverrides: {
			root: {
				padding: "24px !important",
			},
		},
		variants: [
			{
				props: { variant: "flex" },
				style: {
					display: "flex",
					gap: "16px",
					justifyContent: "space-between",
				},
			},
			{
				props: { variant: "noBottomPadding" },
				style: {
					padding: "24px 24px 0 24px !important",
				},
			},
			{
				props: { variant: "form" },
				style: {
					display: "flex",
					flexDirection: "column",
					gap: "16px",
				},
			},
			{
				props: { variant: "hbgPadding" },
				style: {
					padding: "32px 40px 32px 40px !important",
				},
			},
		],
	},
	/*  CardMedia
	 *   variants: flex
	 **  If CardMedia is used and flex-properties is desired it should be wrapped by CardContent of variant flex.
	 *   You should also give the variant flex to the CardMedia to disable the width of 100%
	 **  Typography inside CardMedia may be contained by Mui's "Box" to acquire block behavior
	 *   ex: <CardContent variant='flex'><CardMedia variant='flex'></CardContent>
	 */
	MuiCardMedia: {
		variants: [
			{
				props: { variant: "flex" },
				style: (props) => ({
					width: "auto",

					"&.MuiCardMedia-img": {
						height: props.height,
					},
				}),
			},
		],
	},
	MuiCardActions: {
		styleOverrides: {
			root: {
				padding: "0 24px 24px 24px",
				gap: "8px",
			},
		},
	},
	/*  Button
	 *   variants: positive/underline/simple/panel	(text)
	 *   variants: primary/default	(contained)

	 **  If you want an simple icon or just an X character as close-button to darkCards,
	 **  For buttons that need the ability to disable you need to import it from button.js under /mui-components
	 *  ex: <CardContent variant='flex'><Button variant='simple'></CardContent>
	 */
	MuiButton: {
		styleOverrides: {
			root: {
				height: "36px",
				padding: "0 16px",
				margin: "0 !important",
				borderRadius: "4px",
				boxShadow: "none",

				"&.MuiButton-text > span::first-letter": {
					textTransform: "capitalize",
				},
				"&.MuiButton-textPrimary": {
					color: palette.text.primary,
				},
				"&:hover": {
					background: "transparent",
					boxShadow: "none",
				},
			},
		},
		variants: [
			//	(contained bootstrap blue) used for "call to action" on panels for example
			{
				props: { variant: "primary" },
				style: {
					background: palette.positive.main,
					color: palette.text.secondary,
					fontSize: "13px !important",

					"&:hover": {
						background: palette.positive.hover,
					},
				},
			},
			//	(contained gray) used for "call to action" on panels for example
			{
				props: { variant: "default" },
				style: {
					background: palette.button.main,
					fontSize: "13px !important",

					"&:hover": {
						background: palette.button.hover,
					},
				},
			},
			//	(bootstrap blue) used for "positive buttons" in modals for example
			{
				props: { variant: "positive" },
				style: {
					color: palette.positive.main,
				},
			},
			//	(underlined) used for buttons on panels to distinguish from normal text
			{
				props: { variant: "underline" },
				style: {
					textDecoration: "underline",
					fontSize: "13px !important",
				},
			},
			//	(simple) used for panels that only use the letter X or equivalent as button to close
			{
				props: { variant: "simple" },
				style: {
					minWidth: "auto",
					height: "fit-content",
					padding: 0,
					lineHeight: 1,
				},
			},
			{
				props: { variant: "panel" },
				style: {
					minWidth: "auto",
					height: "34px",
					padding: "0 20px 0 0",
					lineHeight: 1,
				},
			},
			{
				props: { variant: "hbgStyle" },
				style: {
					minWidth: "auto",
					height: "unset",
					padding: "1rem 2rem 1rem 2rem",
					lineHeight: 1,
					background: palette.positive.main,
					color: palette.text.secondary,
					"&:hover": {
						background: palette.button.hbgStyle,
						textDecoration: "none",
						boxShadow:
							"0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
					},
					"&.Mui-disabled": {
						background: palette.button.disabled,
						color: palette.text.secondary,
					},
				},
			},
		],
	},
	MuiInputLabel: {
		//	used to override MUIś default inputlabel text color
		styleOverrides: {
			root: {
				color: palette.grey.main,
				fontWeight: fontWeights.semiBold,
				"&.MuiInputLabel-root": {
					color: palette.grey.main,
				},
				"&.Mui-disabled": {
					"&.MuiInputLabel-root": {
						color: palette.text.primary,
					},
				},
			},
		},
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				backgroundColor: palette.text.secondary,
				"&.Mui-disabled": {
					"&.MuiOutlinedInput-root": {
						backgroundColor: palette.grey.light,
					},
				},
				"&.Mui-focused": {
					fieldset: {
						borderColor: `${palette.grey.main} !important`,
						borderWidth: "1px !important",
					},
				},
			},
			input: {
				fontWeight: fontWeights.semiBold,
				color: palette.text.primaryDarker,
				"&.Mui-disabled": {
					"&.MuiOutlinedInput-input": {
						WebkitTextFillColor: `${palette.text.primary}`,
						color: `${palette.text.primary}`,
					},
				},
			},
		},
	},
	MuiSelect: {
		styleOverrides: {
			root: {
				backgroundColor: palette.text.secondary,
			},
		},
	},
	MuiFormControl: {
		styleOverrides: {
			root: {
				backgroundColor: "transparent !important", //!testat för att få bort den vita bakgrunden bakom felmeddelandet under datepickern i AddTransactionsModalen
			},
		},
	},
	MuiFormControlLabel: {
		styleOverrides: {
			root: (props) => ({
				marginLeft: props.nomargin && 0,
			}),
		},
	},
	MuiPopper: {
		//	used to override MUIś default MuiAutocomplete text color
		styleOverrides: {
			root: {
				"&.MuiAutocomplete-popper *": {
					color: palette.text.primary,
				},
			},
		},
	},
	/* Radio
	 * 	 variants: invono
	 **	 Radio that changes icon on "checked" to an svg circle with the invono tick/check
	 *	 ex: <FormControlLabel value='example' control={<Radio variant='invono' />} label="example" />
	 */
	MuiRadio: {
		//	used to override MUIś "checked" color on all radio
		styleOverrides: {
			root: {
				color: palette.primary.main,
				"&.Mui-checked": {
					color: palette.success.main,
				},
			},
		},
		variants: [
			//	(invono) replace the "checked" radio icon with a radio-container with the invono tick inside
			{
				props: { variant: "invono" },
				style: {
					"&.Mui-checked > span > svg": {
						color: "transparent",
						backgroundImage: "url(/assets/build/icons/tickWithRing.svg)",
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
					},
				},
			},
		],
	},
	/* Checkbox
	 **	 Checkbox that changes icon on "checked" to an svg square with the invono tick/check
	 *	 ex: <Checkbox value='example' />
	 */
	MuiCheckbox: {
		styleOverrides: {
			root: {
				padding: "11px",
				color: palette.primary.main,
				"&.Mui-checked rect": {},
			},
		},
	},
	// STEPPER used to make the stepper resemble modals in the rest of the app
	MuiPaper: {
		styleOverrides: {
			root: {
				borderRadius: "var(--radius)",
				border: "1px solid hsl(var(--input) / 0.75)",
			},
		},
	},
	MuiGrid: {
		styleOverrides: {
			item: {
				"> .MuiPaper-root": {
					height: "100%",
				},
			},
		},
	},
	MuiDialogTitle: {
		styleOverrides: {
			root: {
				fontSize: "18px",
				padding: "23.5px 64px",
				borderBottom: "1px solid rgb(0,0,0,.07)",
				borderRadius: "16px 16px 0 0",
				"&+.MuiDialogContent-root": {
					paddingTop: "32px !important",
				},
			},
		},
	},
	MuiDialogContent: {
		styleOverrides: {
			root: {
				padding: "32px 64px",
				overflowX: "hidden",
				height: "min(83vh, 499px)",
			},
		},
		variants: [
			//	Used when our ScrollView is needed inside dialog to suppress default scrollbar (Chrome)
			{
				props: { variant: "scrollview" },
				style: {
					padding: "32px 64px !important",
					overflowY: "hidden",
				},
			},
		],
	},
	MuiDialogContentText: {
		styleOverrides: {
			root: {
				color: palette.text.primary,
			},
		},
	},
	MuiStepper: {
		styleOverrides: {
			root: {
				padding: "16px 0px",
				color: "red",
			},
		},
	},
	MuiStepIcon: {
		styleOverrides: {
			root: {
				width: "40px",
				height: "40px",
				"&.Mui-completed": {
					color: palette.success.main,
				},
			},
		},
	},
	MuiDialogActions: {
		styleOverrides: {
			root: {
				// padding: '0 56px',
				padding: "0 64px",
				borderTop: "1px solid rgb(0,0,0,.07)",
				borderRadius: "0 0 16px 16px",
				height: "69px",
			},
		},
	},
	// TABS used to make the Mui Tabs/Tab resemble the rest of the tabs in the app
	MuiTabs: {
		styleOverrides: {
			root: {
				minHeight: 0,
				marginBottom: "8px",

				"& .MuiTabScrollButton-root": {
					opacity: 1,
					"&.Mui-disabled": {
						opacity: 0.3,
					},
				},
			},
		},
	},
	MuiTab: {
		styleOverrides: {
			root: {
				background: palette.grey.light,
				border: "1px solid hsl(var(--input) / 0.75)",
				borderRadius: "2px",
				marginRight: "4px",
				padding: "0 16px 0 24px",
				"&:before": {
					position: "absolute",
					inset: 0,
					width: "8px",
					content: '""',
					background: "gray", //This should be changed locally with an styled component since we might want to change it with props.highlightcolor. Examle: investment-tabs.container.js
				},
				"&.MuiButtonBase-root": {
					minHeight: "34px",
					fontSize: "13px !important",
					textTransform: "none",
				},
				"&:hover": {
					background: "#e8e8e8",
				},
				"&.Mui-selected": {
					background: "white",
					border: "2px solid hsl(var(--primary))",
				},
				"&.MuiTab-textColorPrimary": {
					color: palette.text.primary,
				},
			},
		},
	},
	MuiChip: {
		styleOverrides: {
			root: {
				"&.MuiChip-colorSuccess": {
					color: "white",
				},
			},
		},
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				"&.MuiButtonBase-root": {
					fontWeight: 400,
				},
				"& .MuiListItemIcon-root": {
					minWidth: "28px",
				},
			},
		},
	},
	MuiListItemIcon: {
		styleOverrides: {
			root: {
				color: palette.text.primary,
			},
		},
	},
	// MuiSwitch: {
	// 	styleOverrides: {
	// 		root: {
	// 			padding: '0'
	// 		},
	// 		sizeMedium: {
	// 			width: '45px',
	// 			height: '24px'
	// 		},
	// 		switchBase: {
	// 			padding: '0',
	// 			paddingTop: '1px',
	// 			'&.Mui-checked': {
	// 				transform: 'translateX(22px)',
	// 				color: 'white'
	// 			}
	// 		},
	// 		thumb: {
	// 			width: '22px',
	// 			height: '22px'
	// 		},
	// 		track: ({ownerState, theme}) => {
	// 			return {
	// 				borderRadius: '12px',
	// 				backgroundColor: theme.palette.success.main
	// 			}
	// 		}
	// 	}
	// }
};

export const MUItheme = createTheme({
	zIndex: {
		modal: 1000,
	},
	breakpoints,
	palette,
	typography,
	components,
});
