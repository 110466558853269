import React, { Component } from "react";
import { connect } from "react-redux";
import { oneOf, func, bool } from "prop-types";
import { Map, List } from "immutable";
import bankIdLogo from "@/assets/images/bankid.svg";

import SignUpBankId from "../../dumb-components/credentials/middle-components/sign-up-bankid";
import SignUpEmail from "../../dumb-components/credentials/middle-components/sign-up-email";

import withResolvedProps from "@/hocs/withResolvedProps";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import useDialogModal from "@/hooks/useDialogModal";

import {
	authBankId,
	resetCredentials,
} from "../../actions/credentials.actions";

import {
	validateEmail,
	validatePassword,
	validateAuthCode,
	validatePhoneNumber,
} from "../../modules/validation.module";

import {
	NAME_FIELD,
	EMAIL_FIELD,
	TOS_FIELD,
	PASSWORD_FIELD,
	PASSWORD_VERIFY_FIELD,
	CODE_FIELD,
	PHONE_FIELD,
} from "../../constants/credentials";
import Alert from "../../components/alert";
import i18n from "../../i18n";
import { Trans } from "react-i18next";
import { Mail } from "lucide-react";

const { t } = i18n;

class SignUpContainer extends Component {
	state = {
		formData: Map(),
		hasError: Map(),
		actionBtnLoading: false,
	};

	static propTypes = {
		authMode: oneOf([0, 1]),
		onChange: func,
		referredBySignin: bool,
	};

	componentDidMount = () => {
		document.title = "INVONO One™ | Registrera med BankID";
	};

	componentDidUpdate = (prevProps) => {
		const { authMode, resetCredentials } = this.props;

		if (prevProps.authMode !== authMode) {
			resetCredentials();
			this.resetState();
		}
	};

	resetState = (resetAll) => {
		let { formData } = this.state;

		if (resetAll === true) {
			formData = Map();
		} else {
			formData = formData.set(TOS_FIELD, false);
			formData = formData.set(PASSWORD_FIELD, "");
			formData = formData.set(PASSWORD_VERIFY_FIELD, "");
		}

		this.setState({
			formData,
			hasError: Map(),
			actionBtnLoading: false,
			FieldDisabled: false,
		});
	};

	onChange = (fieldName, value) => {
		const { onChange } = this.props;
		let { formData } = this.state;

		if (fieldName === CODE_FIELD) {
			value = value.trim();
		}

		formData = formData.set(fieldName, value);
		this.setState({ formData });

		onChange && onChange(fieldName, value);
	};

	onSignUpBankId = () => {
		const { formData } = this.state;
		const { authBankId, sessionId } = this.props;

		if (this.validateSignUpBankId()) {
			this.setState({ actionBtnLoading: true });

			// Action to send verification code somewhere here.
			authBankId("SIGNUP", sessionId, "SIGNUP-BANKID", formData, () => {
				this.setState({ actionBtnLoading: false });
			});
		}
	};

	onSignUpEmail = () => {
		const { formData } = this.state;
		const { authBankId, sessionId } = this.props;
		if (this.validateSignUpEmail()) {
			this.setState({ actionBtnLoading: true });

			// Action to send verification code somewhere here.
			authBankId("SIGNUP", sessionId, "SIGNUP-BASIC", formData, () => {
				this.setState({ actionBtnLoading: false });
			});
		}
	};

	validateSignUpBankId = () => {
		const { formData } = this.state;
		let hasError = Map();
		let isValid = true;

		// Validate Email
		if (!validateEmail(formData.get(EMAIL_FIELD))) {
			hasError = hasError.set(
				EMAIL_FIELD,
				List(["credentials.validation.email"]),
			);
			isValid = false;
		}

		if (!validatePhoneNumber(formData.get(PHONE_FIELD))) {
			hasError = hasError.set(
				PHONE_FIELD,
				List(["credentials.validation.phone"]),
			);
			isValid = false;
		}

		// Validate ToS
		if (!formData.get(TOS_FIELD)) {
			hasError = hasError.set(TOS_FIELD, List(["credentials.validation.tos"]));
			isValid = false;
		}

		this.setState({ hasError });

		return isValid;
	};

	validateSignUpEmail = () => {
		const { formData } = this.state;
		let hasError = Map();
		let isValid = true;

		// Validate Name
		if (formData.get(NAME_FIELD, "").length === 0) {
			hasError = hasError.set(
				NAME_FIELD,
				List(["credentials.validation.name"]),
			);
			isValid = false;
		}

		// Validate Email
		if (!validateEmail(formData.get(EMAIL_FIELD))) {
			hasError = hasError.set(
				EMAIL_FIELD,
				List(["credentials.validation.email"]),
			);
			isValid = false;
		}

		// Validate password Regex
		if (!validatePassword(formData.get(PASSWORD_FIELD))) {
			hasError = hasError.set(
				PASSWORD_FIELD,
				List(["credentials.validation.password"]),
			);
			isValid = false;
			// Validate Password Match
		} else if (
			formData.get(PASSWORD_FIELD) !== formData.get(PASSWORD_VERIFY_FIELD)
		) {
			hasError = hasError.set(
				PASSWORD_VERIFY_FIELD,
				List(["credentials.validation.password_not_equal"]),
			);
			isValid = false;
		}

		if (!validatePhoneNumber(formData.get(PHONE_FIELD))) {
			hasError = hasError.set(
				PHONE_FIELD,
				List(["credentials.validation.phone"]),
			);
			isValid = false;
		}

		// Validate ToS
		if (!formData.get(TOS_FIELD)) {
			hasError = hasError.set(TOS_FIELD, List(["credentials.validation.tos"]));
			isValid = false;
		}

		this.setState({ hasError });

		return isValid;
	};

	validateCode = () => {
		const { formData } = this.state;
		let hasError = Map();
		let isValid = true;
		const codeValue = formData.get(CODE_FIELD);

		// Validate Code
		if (!validateAuthCode(codeValue)) {
			hasError = hasError.set(
				CODE_FIELD,
				List(["credentials.validation.code"]),
			);
			isValid = false;
		}

		this.setState({ hasError });

		return isValid;
	};

	onClickVerify = () => {
		const { formData } = this.state;
		const { authBankId, sessionId, authMode } = this.props;

		const strategy = authMode === 0 ? "SIGNUP-BASIC" : "SIGNUP-BANKID";
		if (this.validateCode()) {
			this.setState({ actionBtnLoading: true });

			authBankId("SIGNUP", sessionId, strategy, formData, (response) => {
				this.setState({ actionBtnLoading: false });

				// Conflic / User Exists
				if (response.httpStatus === 409) {
					this.resetState(true); // Reset everything
				}
			});
		}
	};

	onCancelVerify = () => {
		this.props.resetCredentials();
		this.resetState(true);
	};

	onOpenTos = () => {
		const { i18n } = this.props;
		const url = `https://invono.se/${
			i18n.language.startsWith("sv") ? "sv/" : "en-US/"
		}legal/terms-of-service`;

		window.open(url, "_blank");
	};

	render = () => {
		const { formData, hasError, actionBtnLoading } = this.state;
		const {
			step,
			translationId,
			referredBySignin,
			authMode,
			onChangeAuthMode,
			dialogElement,
			confirm,
			status,
		} = this.props;

		return (
			<div className="flex flex-col">
				<h1 className="text-2xl mb-10">
					<Trans i18nKey="credentials_sign_up_title" />
				</h1>
				{translationId && (
					<Alert
						className="mb-4"
						title={t(
							status === "failed"
								? "notifications.title.ERROR"
								: "notifications.title.INFO",
						)}
						message={t(translationId)}
						variant={status === "failed" ? "destructive" : undefined}
					/>
				)}
				<Tabs
					value={authMode}
					onValueChange={async (value) => {
						if (value === 0) {
							if (
								!(await confirm(
									t("auth.signup_with_email_confirm.title"),
									t("auth.signup_with_email_confirm.body"),
									{
										variant: "warning",
										submitText: (
											<div className="flex gap-4 items-center">
												<Mail size={16} />
												{t("auth.signup_with_email_confirm.submitText")}
											</div>
										),
										cancelText: (
											<div className="flex gap-4 items-center">
												<img src={bankIdLogo} width={24} />
												{t("auth.signup_with_bankid")}
											</div>
										),
									},
								))
							)
								return;
						}
						onChangeAuthMode(value);
					}}
				>
					<TabsList className="grid w-full grid-cols-2 mb-8">
						<TabsTrigger value={1}>
							<img src={bankIdLogo} width={24} />
							{t("auth.signup_with_bankid")}
						</TabsTrigger>
						<TabsTrigger value={0}>
							<Mail size={16} />
							{t("auth.signup_with_email")}
						</TabsTrigger>
					</TabsList>
					<TabsContent value={0}>
						<SignUpEmail
							onClickSignUp={this.onSignUpEmail}
							onChange={this.onChange}
							isVerifyMode={step > 0}
							onClickVerify={this.onClickVerify}
							name={formData && formData.get(NAME_FIELD)}
							email={formData && formData.get(EMAIL_FIELD)}
							password={formData && formData.get(PASSWORD_FIELD)}
							passwordVerify={formData && formData.get(PASSWORD_VERIFY_FIELD)}
							tos={formData && formData.get(TOS_FIELD)}
							code={formData && formData.get(CODE_FIELD)}
							phone={formData && formData.get(PHONE_FIELD)}
							translationId={translationId}
							onOpenTos={this.onOpenTos}
							actionBtnLoading={actionBtnLoading}
							onClickCancel={this.onCancelVerify}
							hasError={hasError}
						/>
					</TabsContent>
					<TabsContent value={1}>
						<SignUpBankId
							onClickSignUp={this.onSignUpBankId}
							onChange={this.onChange}
							onClickVerify={this.onClickVerify}
							isVerifyMode={step > 0}
							email={formData && formData.get(EMAIL_FIELD)}
							tos={formData && formData.get(TOS_FIELD)}
							code={formData && formData.get(CODE_FIELD)}
							phone={formData && formData.get(PHONE_FIELD)}
							hasError={hasError}
							translationId={translationId}
							renderBankIdInfoContainer={this.renderBankIdInfoContainer}
							onOpenTos={this.onOpenTos}
							actionBtnLoading={actionBtnLoading}
							onClickCancel={this.onCancelVerify}
							referredBySignin={referredBySignin}
						/>
					</TabsContent>
				</Tabs>
				{dialogElement}
			</div>
		);
	};
}

function mapStoreToProps(store) {
	return {
		step: store.credentials.get("step"),
		translationId: store.credentials.get("translationId"),
		sessionId: store.credentials.get("sessionId"),
		status: store.credentials.get("status"),
		i18n: store.i18n,
	};
}

const mapActionsToProps = {
	authBankId,
	resetCredentials,
};

const SignUpContainerConnected = connect(
	mapStoreToProps,
	mapActionsToProps,
)(SignUpContainer);

export default withResolvedProps(() => {
	const { dialogElement, confirm } = useDialogModal();

	return {
		dialogElement,
		confirm,
	};
})(SignUpContainerConnected);
