import React from "react";

import Autoplay from "embla-carousel-autoplay";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from "@/components/ui/carousel";
import { CarouselDots } from "@/components/ui/carousel";
import CredentialsCarouselConsulting from "./items/consulting";

export function CredentialsCarousel() {
	return (
		<div className="mt-[118px] mb-[50px] flex flex-col justify-center p-20 w-full h-full">
			<Carousel className="w-full h-full" plugins={[Autoplay({ delay: 7000 })]}>
				<CarouselContent className="h-full">
					<CarouselItem>
						<CredentialsCarouselConsulting />
					</CarouselItem>
				</CarouselContent>
				<CarouselDots className="p-8 rounded-full [&_button]:bg-white/50 [&_button]:hover:bg-white" />
			</Carousel>
		</div>
	);
}

export default CredentialsCarousel;
