import React from "react";
import { number } from "prop-types";
import Alert from "@/components/alert";
import { useTranslation } from "react-i18next";

const NewEmissionAlert = ({ sharesRemaining }) => {
	const { t } = useTranslation();

	let variant = "success";
	let message = t("shares.transactions.emissions.warning.all_distributed");

	if (sharesRemaining < 0) {
		message = t("shares.transactions.emissions.warning.all_distributed_over");
		variant = "destructive";
	} else if (sharesRemaining > 0) {
		message = t("shares.transactions.emissions.warning.all_distributed_not");
		variant = "warning";
	}

	return (
		<Alert
			variant={variant}
			title={t("shares.transactions.emissions.warning.title")}
			message={message}
		/>
	);
};

NewEmissionAlert.propTypes = {
	sharesRemaining: number,
};

export default NewEmissionAlert;
