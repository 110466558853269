import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Map } from "immutable";
import { string, object } from "prop-types";
import DropdownMenuContainer from "../shared/dropdown-menu.container";
import DropdownIconItem from "../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import DropdownItem from "../../dumb-components/shared/dropdown-item/dropdown-item";
import { ButtonTransparentIcon } from "../../dumb-components/shared/button";
import Tooltip from "../../dumb-components/shared/tooltip/tooltip";

import DocumentRequestSignButton from "./toolbar-items/document-request-sign-button";
import OpenOrDownloadComponent from "./generic/open-or-download-file-component.container";

import DeleteFileItem from "./generic/dropdown-items/delete-file-item.container";
import AddToCollectionComponent from "./generic/add-document-to-collection-component.container";
import RecoverFileDropDownItem from "./generic/dropdown-items/recover-file.container";
import { CopyFileToPersonalDocuments } from "./generic/dropdown-items/copy-file-to-personal-documents";
import useMoveDocument from "@/hooks/documents/useMoveDocument";

const ToolbarContainer = ({ basePath, querystr, params }) => {
	const document = useSelector(
		(state) => state.documents.get("document", Map()) || Map(),
	);
	const {
		createFolderModalElement,
		dialogElement,
		tooltip: moveDocumentTooltip,
		isDisabled: moveDocumentIsDisabled,
		openMoveModal,
	} = useMoveDocument(document, basePath);
	const moreActionBtnRef = useRef(null);

	const closeMoreActionsDropdown = () => {
		moreActionBtnRef.current?.onToggleMenu();
	};

	if (!params.id) {
		return <div />;
	}

	if (document.get("isMeeting")) {
		return <div />;
	}

	const isDeleted = document.get("isDeleted");

	return (
		<div className="flex h-16 justify-end">
			{createFolderModalElement}
			{dialogElement}
			<DocumentRequestSignButton mode="toolbar" disabled={isDeleted} />
			<OpenOrDownloadComponent document={document} mode="OPEN">
				{({ TOOLTIP_STATES, activeState, isDisabled, openOrDownload }) => (
					<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
						<ButtonTransparentIcon
							onClick={openOrDownload}
							icon="faExpandArrowsAlt"
							size="sml"
							disabled={isDisabled}
						/>
					</Tooltip>
				)}
			</OpenOrDownloadComponent>
			<OpenOrDownloadComponent document={document} mode="DOWNLOAD">
				{({ TOOLTIP_STATES, activeState, isDisabled, openOrDownload }) => (
					<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
						<ButtonTransparentIcon
							onClick={openOrDownload}
							icon="faArrowAltToBottom"
							size="sml"
							disabled={isDisabled}
						/>
					</Tooltip>
				)}
			</OpenOrDownloadComponent>
			<DropdownMenuContainer
				btnIcon="faEllipsisH"
				halignMenu="right"
				btnMode="transparent-icon"
				noMaxWidth={true}
				ref={moreActionBtnRef}
			>
				<AddToCollectionComponent document={document}>
					{({ TOOLTIP_STATES, activeState, isDisabled, addToCollection }) => (
						<Tooltip
							states={TOOLTIP_STATES}
							activeState={activeState}
							active={isDisabled}
						>
							<DropdownIconItem
								tid="documents.list.dropdown.item.add_to_collection"
								icon="faReceipt"
								disabled={isDisabled}
								onClick={() => {
									closeMoreActionsDropdown();
									addToCollection();
								}}
							/>
						</Tooltip>
					)}
				</AddToCollectionComponent>

				<CopyFileToPersonalDocuments
					document={document}
					onClick={closeMoreActionsDropdown}
				/>

				<Tooltip
					states={moveDocumentTooltip.TOOLTIP_STATES}
					activeState={moveDocumentTooltip.activeState}
					active={moveDocumentTooltip.isDisabled}
					values={moveDocumentTooltip.tooltipValues}
				>
					<DropdownIconItem
						tid="documents.document.toolbar.item.move_document"
						icon="faCodeBranch"
						disabled={moveDocumentIsDisabled}
						onClick={async () => {
							closeMoreActionsDropdown();
							await openMoveModal();
						}}
					/>
				</Tooltip>

				{!isDeleted && <DropdownItem divider />}

				<DeleteFileItem
					document={document}
					basePath={basePath}
					querystr={querystr}
					onClick={closeMoreActionsDropdown}
				/>

				<RecoverFileDropDownItem
					document={document}
					basePath={basePath}
					querystr={querystr}
					onClick={closeMoreActionsDropdown}
				/>
			</DropdownMenuContainer>
		</div>
	);
};

ToolbarContainer.propTypes = {
	basePath: string,
	querystr: string,
	params: object,
};

export default ToolbarContainer;
