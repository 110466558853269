import React, { Component } from "react";
import { string, object, bool, func, number } from "prop-types";
import styled, { css } from "styled-components";
import Text from "../../../shared/text/text";
import { Switch } from "../../../../components/ui/switch";
import Tooltip from "../../../shared/tooltip/tooltip";
import {
	MEETING_INTERNAL_TYPES,
	MEETINGS_ITEM_TYPES,
} from "../../../../constants/meetings";

import DndDragHandlers, {
	hoverStyling,
} from "../../../shared/dnd-drag-handlers/dnd-drag-handlers";

const StyledAgendaItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	height: 50px;
	cursor: pointer;
	border-top: 1px solid ${(props) => props.theme.colors.solitudeDark};
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeDark};
`;

const StyledDndDragHandlers = styled(DndDragHandlers)`
	border-right: 1px solid ${(props) => props.theme.colors.solitudeDark};
	background: none;

	${StyledAgendaItem}:hover & {
		${hoverStyling}
	}
`;

const StyledContent = styled.div`
	flex: 1;
	padding: ${(props) => props.theme.spacing[3]}
		${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[3]}
		${(props) => props.theme.spacing[4]};
	height: 100%;
	display: flex;
	flex-direction: column;
	width: ${(props) => 316 - props.indent}px;
`;

const StyledTitle = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
`;

const StyledInputWrapper = styled.div`
	display: flex;
	flex: 1;
`;

const StyledToggleWrapper = styled.div`
	display: flex;
`;

const StyledDropdownWrapper = styled.div`
	display: flex;
	justfy-content: flex-end;

	${(props) =>
		!props.showDropdown &&
		css`
			margin-right: 44px;
		`}
`;

const StyledSubtitle = styled.div`
	display: flex;
`;

export default class TemplateItem extends Component {
	static propTypes = {
		id: string,
		outcome: string,
		duration: number,
		name: string,
		itemType: string,
		isDragDisabled: bool,
		active: bool,
		dragHandleProps: object,
		onClick: func.isRequired,
		renderDuration: func,
		renderEditor: func,
		parentIndex: number,
		itemIndex: number,
		inEditMode: bool,
		level: number,
		onChange: func,
		renderDropdownMenu: func,
		fieldName: string,
		toggleSwitchTooltipTid: string,
		forceShowToggleSwitch: bool,
		forceRightMargin: bool,
	};

	static defaultProps = {
		fieldName: "active",
		toggleSwitchTooltipTid: "meetings.templates.modal.toggle.tooltip",
	};

	onClick = () => {
		const { id, onClick } = this.props;
		onClick && onClick(id);
	};

	render = () => {
		const {
			name,
			itemType,
			isDragDisabled,
			dragHandleProps,
			renderDuration,
			renderEditor,
			inEditMode,
			level,
			active,
			onChange,
			locked,
			internalType,
			renderDropdownMenu,
			fieldName,
			toggleSwitchTooltipTid,
			forceShowToggleSwitch,
			forceRightMargin,
		} = this.props;
		const isCategory = itemType === "category";
		const styledContentCategoryProps = {
			indent: level > 1 ? (level - 1) * 50 : 0,
		};
		const isSpecialInternalType =
			internalType === "previousMeeting" || internalType === "nextMeeting";
		const showToggleSwitch =
			forceShowToggleSwitch === true || (!isCategory && !locked);
		const showDropdown = !locked && !isSpecialInternalType;
		let typeStatusTid;

		if (internalType) {
			typeStatusTid = MEETING_INTERNAL_TYPES.get(internalType);
		} else if (itemType && itemType !== "category") {
			typeStatusTid = MEETINGS_ITEM_TYPES.find(
				(item) => item.get("value") === itemType,
			).get("label");
		}

		return (
			<StyledAgendaItem>
				<StyledDndDragHandlers
					isDragDisabled={isDragDisabled}
					dragHandleProps={dragHandleProps}
				/>

				{itemType !== "category" && renderDuration && renderDuration()}

				<StyledContent onClick={this.onClick} {...styledContentCategoryProps}>
					{!inEditMode && (
						<StyledTitle>
							<Text singleLine>{name ? name : "-"}</Text>
						</StyledTitle>
					)}
					{inEditMode && renderEditor && (
						<StyledTitle>
							<StyledInputWrapper>{renderEditor()}</StyledInputWrapper>
						</StyledTitle>
					)}
					{typeStatusTid && (
						<StyledSubtitle>
							<Text singleLine color="muted" tid={typeStatusTid} />
						</StyledSubtitle>
					)}
				</StyledContent>

				<StyledToggleWrapper>
					{showToggleSwitch && (
						<Tooltip tid={toggleSwitchTooltipTid}>
							<Switch
								onCheckedChange={(val) => onChange(fieldName, val)}
								checked={active}
							/>
						</Tooltip>
					)}
				</StyledToggleWrapper>

				<StyledDropdownWrapper
					showDropdown={forceRightMargin === true ? false : showDropdown}
				>
					{showDropdown && renderDropdownMenu && renderDropdownMenu()}
				</StyledDropdownWrapper>
			</StyledAgendaItem>
		);
	};
}
