import { useQuery } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";

export default () => {
	const companyId = useCompanyIdFromUrl();

	return useQuery({
		enabled: !!companyId,
		queryKey: ["documentsFolders", companyId],
		queryFn: () => {
			return apiClient.get(
				`gateway/personal-documents/companies/${companyId}/folders`,
				{ params: { companyId } },
			);
		},
	});
};
