import React from "react";

import {
	Alert as AlertBase,
	AlertTitle,
	AlertDescription,
} from "@/components/ui/alert";

import { string, node, oneOf } from "prop-types";
import { Info, TriangleAlert, Check } from "lucide-react";

const Alert = ({ className, icon, title, message, variant }) => {
	let _icon = <Info />;

	if (icon) {
		_icon = icon;
	} else if (variant === "destructive") {
		_icon = <TriangleAlert className="!text-destructive" />;
	} else if (variant === "warning") {
		_icon = <TriangleAlert className="!text-warning" />;
	} else if (variant === "success") {
		_icon = <Check className="!text-success" />;
	}

	return (
		<AlertBase variant={variant} className={className}>
			{_icon}
			<AlertTitle>{title}</AlertTitle>
			<AlertDescription>{message}</AlertDescription>
		</AlertBase>
	);
};

Alert.propTypes = {
	className: string,
	icon: node,
	title: node,
	message: node.isRequired,
	variant: oneOf(["default", "warning", "destructive"]),
};

Alert.defaultProps = {
	variant: "default",
};

export default Alert;
