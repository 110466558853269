import React, { cloneElement, useState } from "react";

import { string, node, arrayOf, shape, number } from "prop-types";
import Separator from "./separator";
import { cn } from "./utils";
import { Check } from "lucide-react";

const StepFlow = ({ steps, initialStepIndex }) => {
	const [currentStepIndex, setCurrentStepIndex] = useState(initialStepIndex);
	const currentStep = steps[currentStepIndex];

	return (
		<div className="flex flex-col gap-24 overflow-auto h-full">
			<div className="flex">
				{steps.map((step, index) => (
					<div key={step.name} className="flex flex-col flex-1">
						<Separator
							hideLeft={index === 0}
							hideRight={index === steps.length - 1}
						>
							<div
								className={cn(
									"flex text-gray-400 bg-gray-100 rounded-full h-12 w-12 items-center justify-center font-bold text-md",
									{ "bg-primary text-white": index === currentStepIndex },
								)}
							>
								{currentStepIndex > index ? (
									<Check size={14} strokeWidth={4} />
								) : (
									index + 1
								)}
							</div>
						</Separator>
						<div
							className={cn("flex self-center px-4 text-center max-w-[200px]", {
								"font-bold": index === currentStepIndex,
							})}
						>
							{step.title}
						</div>
					</div>
				))}
			</div>
			<div>
				{cloneElement(currentStep.component, {
					jumpToStep: setCurrentStepIndex,
				})}
			</div>
		</div>
	);
};

StepFlow.propTypes = {
	initialStepIndex: number,
	steps: arrayOf(
		shape({
			name: string.isRequired,
			title: string.isRequired,
			component: node.isRequired,
		}),
	),
};

StepFlow.defaultProps = {
	initialStepIndex: 0,
};

export default StepFlow;
