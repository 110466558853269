import { Map, List } from "immutable";
import moment from "../../modules/moment.module";
import {
	MEETINGS_STATUS_DRAFT,
	MEETINGS_STATUS_NOTIFIED_NO_PUB,
	MEETINGS_STATUS_PUBLISHED,
	MEETINGS_STATUS_CHANGED,
	MEETINGS_STATUS_STARTED,
	MEETINGS_STATUS_FINISHED,
	MEETINGS_STATUS_PROTOCOL_NEEDS_FEEDBACK,
	MEETINGS_STATUS_PROTOCOL_NEEDS_SIGNING,
	MEETINGS_STATUS_PROTOCOL_SIGNED,
	MEETINGS_STATUS_PROTOCOL_PUBLISHED,
} from "../../constants/meetings";
import permissionsHelper from "./permissions.helper";
import isEqual from "lodash/isEqual";
import documentsHelper from "./documents.helper";
import { getShareholderStatus } from "./shares";

const filterFunctions = {
	tag: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;
			values.forEach((val) => {
				const tags = item.get("tags");
				if (tags && tags.includes(val)) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	task: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;
			values.forEach((val) => {
				const title = item.get("title", "") || "";
				val = val && val.trim();
				if (title.trim() === val) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	project: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				if (item.get("projectId") === val) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	document: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;
			values.forEach((val) => {
				const title = item.get("title", "") || "";
				val = val && val.trim();
				if (title.trim() === val) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	folder: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				if (item.get("folderId") === val) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	dateProp: (items, values, excludedItems, dateProp) => {
		return items.filter((item) => {
			if (dateProp === "startDate") {
				return true;
			}

			if (dateProp === "lastModified") {
				return true;
			}

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return item.get(dateProp) !== undefined && item.get(dateProp) !== null;
		});
	},
	date: (items, values, excludedItems, dateProp) => {
		const val = values.get(0);
		const minDate = val.get("minDate");
		const maxDate = val.get("maxDate");

		return items.filter((item) => {
			let found = false;

			if (item.get(dateProp)) {
				const dateValue = moment(item.get(dateProp));

				if (minDate && maxDate) {
					if (
						moment(minDate).isSameOrBefore(dateValue) &&
						moment(maxDate).isSameOrAfter(dateValue)
					) {
						found = true;
					}
				} else if (
					(minDate && moment(minDate).isSameOrBefore(dateValue)) ||
					(maxDate && moment(maxDate).isSameOrAfter(dateValue))
				) {
					found = true;
				}
			}

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	status: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				if (val === "show_all" || item.get("status") === val) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	docStatus: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				const isInvalid =
					item.get("reminderSet") &&
					item.get("validTo") &&
					moment(item.get("validTo")).diff(
						moment().hour(0).minutes(0).seconds(0).milliseconds(0),
						"days",
					) <= 0;
				const isWarning =
					item.get("reminderSet") &&
					item.get("reminderDate") &&
					moment(item.get("reminderDate")).diff(
						moment().hour(0).minutes(0).seconds(0).milliseconds(0),
						"days",
					) <= 0;
				if (val === "show_all") {
					found = true;
				} else if (val === "none" && !isInvalid && !isWarning) {
					found = true;
				} else if (isInvalid && val === "INVALID") {
					found = true;
				} else if (isWarning && val === "WARNING") {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	createdBy: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				if (item.get("createdBy") === val) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	assigne: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				if (
					(val === "none" && !item.get("assigne")) ||
					item.get("assigne") === val ||
					val === "show_all"
				) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	investorStatus: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				if (
					(val === "current" && getShareholderStatus(item) === "CURRENT") ||
					(val === "archived" && getShareholderStatus(item) === "ARCHIVED") ||
					(val === "new" && getShareholderStatus(item) === "NEW")
				) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	investorTypeOfOwner: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				if (item.get("investorTypeOfOwner") === val) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	registeredUser: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				if (
					(val === "yes" &&
						(item.get("isActiveInvonoUser") ||
							item.get("isActiveInvonoCompany"))) ||
					(val === "no" &&
						!(
							item.get("isActiveInvonoUser") ||
							item.get("isActiveInvonoCompany")
						))
				) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},

	extension: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				if (item.getIn(["file", "ext"]) === val) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},

	signedDoc: (items, values, excludedItems) => {
		return items.filter((item) => {
			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			let found = false;

			values.forEach((val) => {
				switch (val) {
					case "show_all":
						found = true;
						break;
					case "pending":
						found =
							item.get("eSigning") === true &&
							item
								.get("eSignees")
								.every((obj) => obj.get("status") === "NOT-SIGNED");
						break;
					case "ongoing":
						found =
							item.get("eSigning") === true &&
							!documentsHelper.getDocumentAllHaveSigned(item);
						break;
					case "completed":
						found = documentsHelper.getDocumentIsSigned(item);
						break;
					case "unsigned":
						found = !item.get("eSigning");
						break;
				}
			});

			return found;
		});
	},

	meetingStatus: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				switch (val) {
					case "meetings":
						if (
							item.get("status") === MEETINGS_STATUS_DRAFT ||
							item.get("status") === MEETINGS_STATUS_NOTIFIED_NO_PUB ||
							item.get("status") === MEETINGS_STATUS_PUBLISHED ||
							item.get("status") === MEETINGS_STATUS_CHANGED ||
							item.get("status") === MEETINGS_STATUS_STARTED ||
							item.get("status") === MEETINGS_STATUS_FINISHED
						) {
							found = true;
						}
						break;
					case "protocols":
						if (
							item.get("status") === MEETINGS_STATUS_PROTOCOL_NEEDS_FEEDBACK ||
							item.get("status") === MEETINGS_STATUS_PROTOCOL_NEEDS_SIGNING ||
							item.get("status") === MEETINGS_STATUS_PROTOCOL_SIGNED ||
							item.get("status") === MEETINGS_STATUS_PROTOCOL_PUBLISHED
						) {
							found = true;
						}
						break;
					default:
						found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	meeting: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;
			values.forEach((val) => {
				const title = item.get("name", "") || "";
				val = val && val.trim();
				if (title.trim() === val) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	attendeeNotified: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				if (
					((val === "yes" &&
						item.getIn(["computedValues", "attendeesWarning"]) === false) ||
						(val === "no" &&
							item.getIn(["computedValues", "attendeesWarning"]) === true)) &&
					item.get("attendees", List()) &&
					item.get("attendees", List()).size > 0
				) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	templateId: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				if (item.get("templateId") === val) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	meetingType: (items, values, excludedItems) => {
		return items.filter((item) => {
			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			let found = false;
			values.forEach((val) => {
				if (item.get("meetingType") === val) {
					found = true;
				}
			});

			return found;
		});
	},
	attendees: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				if (
					(item.get("attendees") && item.get("attendees").has(val)) ||
					val === "show_all"
				) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},
	group: (items, values, excludedItems) => {
		return items.filter((item) => {
			let found = false;

			values.forEach((val) => {
				if (item.get("groupId") === val) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},

	permissions: (
		items,
		values,
		excludedItems,
		dateProp,
		usersCache,
		usersInRoleCache,
	) => {
		return items.filter((item) => {
			let found = false;
			values.forEach((val) => {
				if (val === "show_all") {
					found = true;
				}
				const allowedByRole =
					item.has("permissions") &&
					permissionsHelper.isRoleAllowed(val, item.get("permissions"));
				const allowedByUser =
					item.has("permissions") &&
					permissionsHelper.isUserAllowed(
						val,
						item.get("permissions"),
						usersInRoleCache,
						usersCache,
					);
				if (allowedByRole || allowedByUser) {
					found = true;
				}
			});

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return found;
		});
	},

	filterMeetingsByDays: (items, values, excludedItems) => {
		return items.filter((item) => {
			const value = values.get(0);

			if (value && item.get("startDate")) {
				const startDate = moment(item.get("startDate"));
				const filterEndDate = moment().subtract(value, "days").endOf("day");
				return startDate >= filterEndDate && startDate <= moment();
			}

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return true;
		});
	},

	includeArchived: (items, values, excludedItems) => {
		return items.filter((item) => {
			const value = values.get(0);

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return (
				!item.get("archived") ||
				(value === true && item.get("archived") === true)
			);
		});
	},

	showOnlyTrashed: (items, values, excludedItems) => {
		return items.filter((item) => {
			const value = values.get(0);

			if (excludedItems.includes(item.get("id"))) {
				return true;
			}

			return (
				(value === true && item.get("isDeleted") === true) ||
				(value === false && !item.get("isDeleted"))
			);
		});
	},
};

const sortFunctions = {
	title: (items) => {
		return items.sort((a, b) => {
			return (a.get("title", "") || "").localeCompare(
				b.get("title", "") || "",
				undefined,
				{
					numeric: true,
					sensitivty: "base",
				},
			);
		});
	},
	orderIndex: (items) => {
		return items.sort((a, b) => {
			return a.get("orderIndex") - b.get("orderIndex");
		});
	},
	datePropAsc: (items, { dateProp }) => {
		return items.sort((a, b) => {
			const aIsMeeting = a.get("isMeeting");
			const bIsMeeting = b.get("isMeeting");
			const aDate = aIsMeeting ? a.get("startDate") : a.get(dateProp);
			const bDate = bIsMeeting ? b.get("startDate") : b.get(dateProp);

			if (!aDate && bDate) {
				return -1;
			} else if (aDate && !bDate) {
				return 1;
			} else {
				return moment(aDate).diff(moment(bDate));
			}
		});
	},
	datePropDesc: (items, { dateProp }) => {
		if (dateProp === "startDate") {
			const itemWithoutStartDate = items
				.filter((obj) => !obj.get("startDate"))
				.sort((a, b) => {
					const aDate = a.get("createdAt");
					const bDate = b.get("createdAt");

					if (!aDate && bDate) {
						return 1;
					} else if (aDate && !bDate) {
						return -1;
					} else {
						return moment(bDate).diff(moment(aDate));
					}
				});

			const sortedItems = items
				.filter((obj) => obj.get("startDate"))
				.sort((a, b) => {
					const aDate = a.get(dateProp);
					const bDate = b.get(dateProp);

					if (!aDate && bDate) {
						return 1;
					} else if (aDate && !bDate) {
						return -1;
					} else {
						return moment(bDate).diff(moment(aDate));
					}
				});

			return itemWithoutStartDate.concat(sortedItems);
		}

		return items.sort((a, b) => {
			const aIsMeeting = a.get("isMeeting");
			const bIsMeeting = b.get("isMeeting");
			const aDate = aIsMeeting ? a.get("startDate") : a.get(dateProp);
			const bDate = bIsMeeting ? b.get("startDate") : b.get(dateProp);

			if (!aDate && bDate) {
				return 1;
			} else if (aDate && !bDate) {
				return -1;
			} else {
				return moment(bDate).diff(moment(aDate));
			}
		});
	},
	showByAsc: (items, { showBy }) => {
		return items.sort((a, b) => {
			if (showBy === "numOfShares") {
				return (
					a.getIn(["details", "numOfTotalShares"], 0) -
					b.getIn(["details", "numOfTotalShares"], 0)
				);
			} else if (showBy === "ownershipPercentage") {
				return (
					a.getIn(["details", "ownershipPercentage"], 0) -
					b.getIn(["details", "ownershipPercentage"], 0)
				);
			}
		});
	},
	showByDesc: (items, { showBy }) => {
		return items.sort((a, b) => {
			if (showBy === "numOfShares") {
				return (
					b.getIn(["details", "numOfTotalShares"], 0) -
					a.getIn(["details", "numOfTotalShares"], 0)
				);
			} else if (showBy === "ownershipPercentage") {
				return (
					b.getIn(["details", "ownershipPercentage"], 0) -
					a.getIn(["details", "ownershipPercentage"], 0)
				);
			}
		});
	},
	investorName: (items) => {
		return items.sort((a, b) => {
			return a
				.getIn(["investorInformation", "name"], "")
				.localeCompare(b.getIn(["investorInformation", "name"]));
		});
	},
};

export function filter(items, filters, usersCache, usersInRoleCache) {
	if (filters.size > 0) {
		const dateProp = (
			filters.find((obj) => obj.get("source") === "dateProp") || Map()
		).getIn(["values", 0]);
		filters = filters.filter(
			(obj) => obj.get("source") !== "order" && obj.get("source") !== "showBy",
		);

		filters.forEach((filter) => {
			const source = filter.get("source");
			const values = filter.get("values");

			if (values.size !== 0) {
				items = filterFunctions[source](
					items,
					values,
					List(),
					dateProp,
					usersCache,
					usersInRoleCache,
				);
			}
		});
	}

	return items;
}

export function hasAppliedAdvancedFilters(filterBy, defaultFilters) {
	// Fiter of simple filters like tags, groups/categories/folders
	filterBy = filterBy.filter((obj) => !obj.get("isSimpleFilter"));

	// Check if any advanced filters have been added or changed from default
	let advancedFiltersApplied = false;
	filterBy.forEach((filter) => {
		const defaultFilterValues = defaultFilters.get(filter.get("source"));
		if (
			!defaultFilterValues ||
			!isEqual(defaultFilterValues.toJS(), filter.get("values").toJS())
		) {
			advancedFiltersApplied = true;
		}
	});

	return advancedFiltersApplied;
}

export default class FilterAndSort {
	constructor(items, excludedItems, filters, usersCache, usersInRoleCache) {
		this.items = items;
		this.excludedItems = excludedItems || List();
		this.filters = filters;
		this.usersCache = usersCache;
		this.usersInRoleCache = usersInRoleCache;
		this.filteredItems = List();
	}

	filter() {
		if (this.filters.size > 0) {
			const dateProp = (
				this.filters.find((obj) => obj.get("source") === "dateProp") || Map()
			).getIn(["values", 0]);
			const filters = this.filters.filter(
				(obj) =>
					obj.get("source") !== "order" && obj.get("source") !== "showBy",
			);

			filters.forEach((filter) => {
				const source = filter.get("source");
				const values = filter.get("values");

				if (values.size !== 0) {
					this.items = filterFunctions[source](
						this.items,
						values,
						this.excludedItems,
						dateProp,
						this.usersCache,
						this.usersInRoleCache,
					);
				}
			});
		}

		this.filteredItems = this.items;
		return this.filteredItems;
	}

	sort() {
		const dateProp = (
			this.filters.find((obj) => obj.get("source") === "dateProp") || Map()
		).getIn(["values", 0]);
		const order = (
			this.filters.find((obj) => obj.get("source") === "order") || Map()
		).getIn(["values", 0]);
		const showBy = (
			this.filters.find((obj) => obj.get("source") === "showBy") || Map()
		).getIn(["values", 0]);
		this.filteredItems = sortFunctions[order](this.items, { dateProp, showBy });
		return this.filteredItems;
	}

	getResult() {
		return this.filteredItems;
	}
}
