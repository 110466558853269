import { useMutation } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import apiClient from "@/modules/request.module.v2";
import { addErrorNotification } from "@/components/framework/notify";

export default (companyId) => {
	const history = useHistory();

	return useMutation({
		mutationKey: ["cancelCompanyAccount", companyId],
		mutationFn: () => {
			return apiClient.put(`/companies/${companyId}/cancel`, undefined, {
				params: { companyId },
			});
		},
		onSuccess: () => {
			history.push("/dashboard");
		},
		onError: () => {
			addErrorNotification({ tid: "generic.unknown_error" });
		},
	});
};
