import React from "react";
import Avatar from "@/components/avatar";

const ProfileImageRenderer = ({ data }) => {
	console.log(data);
	return (
		<div className="flex h-full items-center">
			<Avatar
				id={data.id}
				name={data.name}
				type={data.investorTypeOfOwner === "company" ? "company" : "user"}
			/>
		</div>
	);
};

export { ProfileImageRenderer };
