import React from "react";
import { cn } from "./utils";

const ToolbarMenu = ({ children }) => {
	return (
		<div
			className={cn(
				"flex items-center gap-2 bg-background/40 backdrop-blur-xl p-2 rounded-full border border-edge ",
				"self-end",
				"first:[&>button]:rounded-l-full last:[&>button]:rounded-r-full",
			)}
		>
			{children}
		</div>
	);
};

export default ToolbarMenu;
