import React, { useState } from "react";
import DialogModal from "@/components/dialogModal";
import useShareholders from "@/hooks/shares/useShareholders";
import { ItemList, ItemListColumn, ItemListRow } from "@/components/itemList";
import { Button } from "@/components/ui/button";
import { cn } from "@/components/utils";
import LoadingPanel from "@/components/loadingPanel";
import { User, Building2, Landmark, X } from "lucide-react";
import usePagination from "@/hooks/usePagination";
import withTooltip from "@/hocs/withTooltip";
import i18n from "@/i18n";

const { t } = i18n;

const UserTT = withTooltip()(User);
const Building2TT = withTooltip()(Building2);
const LandmarkTT = withTooltip()(Landmark);

const getOwnerTypeIcon = (ownerType) => {
	switch (ownerType) {
		case "company":
			return (
				<Building2TT size={18} title={t("shares.type_of_owner.company")} />
			);
		case "companyContact":
			return <UserTT size={18} title="Company contact" />;
		case "private":
			return <UserTT size={18} title={t("shares.type_of_owner.private")} />;
		case "capitalInsurance":
			return (
				<LandmarkTT
					size={18}
					title={t("people_modal.investor_type_of_owner.capital_insurance")}
				/>
			);
		case "capital":
			return (
				<LandmarkTT
					size={18}
					title={t("people_modal.investor_type_of_owner.capital_insurance")}
				/>
			);
	}
};

const FilterBoolean = ({ label, value, onChange }) => {
	return (
		<Button
			size="sm"
			className={cn(
				"rounded-full border-primary/30 border-2 text-primary hover:bg-primary/10 hover:text-primary",
				{
					"bg-primary/80 border-primary text-background hover:bg-primary/90 hover:text-background":
						value,
				},
			)}
			variant={value ? "default" : "outline"}
			onClick={onChange}
		>
			{label}
		</Button>
	);
};

const useFilterPeople = ({ sources, filters = [], excludeIds = [] }) => {
	const [filterValues, setFilterValues] = useState(() =>
		filters.reduce((acc, filterConfig) => {
			return {
				...acc,
				[filterConfig.id]: filterConfig.defaultValue,
			};
		}, {}),
	);

	const configByFilterId = filters.reduce(
		(acc, filterConfig) => ({ ...acc, [filterConfig.id]: filterConfig }),
		{},
	);

	const toggleFilter = (filterId) => {
		const filterValue = filterValues[filterId];

		setFilterValues((prev) => ({
			...prev,
			[filterId]: !filterValue,
		}));
	};

	const addToFilter = (filterId, value) => {
		const filterConfig = configByFilterId[filterId];

		if (filterConfig.type !== "list") {
			throw new Error(`Filter ${filterId} is not a list filter`);
		}

		if (typeof value === "undefined") {
			throw new Error(`Value is required for filter ${filterId}`);
		}

		setFilterValues((prev) => ({
			...prev,
			[filterId]: [...prev[filterId], value],
		}));
	};

	const removeFromFilter = (filterId, value) => {
		const filterConfig = configByFilterId[filterId];

		if (filterConfig.type !== "list") {
			throw new Error(`Filter ${filterId} is not a list filter`);
		}

		if (typeof value === "undefined") {
			throw new Error(`Value is required for filter ${filterId}`);
		}

		setFilterValues((prev) => ({
			...prev,
			[filterId]: prev[filterId].filter((v) => v !== value),
		}));
	};

	const sourceData = Object.values(sources)
		.flatMap((source) => source())
		.filter((item) => !excludeIds.includes(item.id));

	const filteredData = Object.values(filters).reduce((acc, filterConfig) => {
		return acc.map((item) => {
			let _isFiltered;

			switch (filterConfig.type) {
				case "list": {
					_isFiltered = filterConfig.fn(item, filterValues[filterConfig.id]);
					break;
				}
				case "boolean": {
					_isFiltered = !filterValues[filterConfig.id]
						? item._isFiltered
						: filterConfig.fn(item);
					break;
				}
				default:
					throw new Error(`Unknown filter type ${filterConfig.type}`);
			}

			if (item._isFiltered) {
				_isFiltered = true;
			}

			return {
				...item,
				_isFiltered,
			};
		});
	}, sourceData);

	const getFilterValue = (filterId) => filterValues[filterId];

	const renderFilterControl = (filterId) => {
		const config = configByFilterId[filterId];

		if (!config) return null;

		if (config.type === "list") {
			const values = getFilterValue(filterId);

			const valueRenderer = config.valueRenderer ?? ((i) => i);

			return (
				<div className="flex flex-wrap gap-1">
					{values.map((v) => (
						<FilterBoolean
							key={v}
							label={
								<div className="flex items-center gap-1">
									<X size={12} />
									{valueRenderer(v)}
								</div>
							}
							value={true}
							onChange={() => removeFromFilter(filterId, v)}
						/>
					))}
				</div>
			);
		}

		if (config.type === "boolean") {
			return (
				<FilterBoolean
					label={config.label}
					value={getFilterValue(filterId)}
					onChange={() => toggleFilter(filterId)}
				/>
			);
		}
	};

	return {
		addToFilter,
		removeFromFilter,
		toggleFilter,
		getFilterValue,
		renderFilterControl,
		data: filteredData,
	};
};

const FilterPeople = ({ onCancel, onSubmit, excludeIds }) => {
	// const { data: investmentContactTags, isLoading: isLoadingContactTags } =
	//   useInvestmentContactsTags();
	// const shareholderContactTags = investmentContactTags?.data ?? [];

	const { data: investmentsQuery, isLoading } = useShareholders();
	const shareholders = investmentsQuery?.data ?? [];

	const {
		renderFilterControl,
		data: filteredPeople,
		addToFilter,
		removeFromFilter,
	} = useFilterPeople({
		excludeIds,
		filters: [
			{
				id: "ownerType_company",
				label: t("shares.type_of_owner.company"),
				type: "boolean",
				fn: (item) => item.ownerType === "company",
				defaultValue: false,
			},
			// {
			//   id: "ownerType_companyContact",
			//   label: "Company contact",
			//   type: "boolean",
			//   fn: (item) => item.ownerType === "companyContact",
			//   defaultValue: false,
			// },
			{
				id: "ownerType_private",
				label: t("people_modal.investor_type_of_owner.private"),
				type: "boolean",
				fn: (item) => item.ownerType === "private",
				defaultValue: false,
				level: 1,
			},
			{
				id: "ownerType_capitalInsurance",
				label: t("people_modal.investor_type_of_owner.capital_insurance"),
				type: "boolean",
				fn: (item) => item.ownerType === "capitalInsurance",
				defaultValue: false,
			},
			// {
			// 	id: "ownerStatus_current",
			// 	label: t("filter_people.shareholders.owner_status.current"),
			// 	type: "boolean",
			// 	fn: (item) => item.ownerStatus === "current",
			// 	defaultValue: false,
			// },
			// {
			// 	id: "ownerStatus_never",
			// 	label: t("filter_people.shareholders.owner_status.never"),
			// 	type: "boolean",
			// 	fn: (item) => item.ownerStatus === "never",
			// 	defaultValue: false,
			// },
			// {
			// 	id: "ownerStatus_former",
			// 	label: t("filter_people.shareholders.owner_status.former"),
			// 	type: "boolean",
			// 	fn: (item) => item.ownerStatus === "former",
			// 	defaultValue: false,
			// },
			{
				id: "individualIncludes",
				label: "Include individuals",
				type: "list",
				fn: (item, list) => list.includes(item.id),
				defaultValue: [],
				valueRenderer: (v) => filteredPeople.find((p) => p.id === v)?.name,
			},
			// {
			//   id: "isRegistered",
			//   label: "Invono member",
			//   type: "boolean",
			//   fn: (item) => item.isRegistered,
			//   defaultValue: false,
			// },
			// ...(shareholderContactTags ?? []).map((tag) => ({
			//   id: `hasTag_${tag}`,
			//   label: tag,
			//   type: "boolean",
			//   fn: (item) => item.contactTags?.includes(tag),
			// })),
		],
		sources: {
			shareholders: () =>
				shareholders
					?.flatMap?.((shareholder) => {
						return [
							{
								id: shareholder.id,
								investorId: shareholder.investorId,
								name: shareholder.investorInformation?.name,
								displayName: shareholder.investorInformation?.name,
								email: shareholder.investorInformation?.email,
								ownerType: shareholder.investorTypeOfOwner,
								isRegistered: shareholder.computed.isActiveUser,
								ownerStatus: shareholder.details?.hasShares
									? "current"
									: shareholder.isDeletable
									? "never"
									: "former",
								contactTags: [],
							},
							// ...(shareholder.investorInformation?.contacts?.map((contact) => ({
							// 	name: contact.name,
							// 	email: contact.email,
							// 	displayName: (
							// 		<div className="flex inline-flex items-center gap-2">
							// 			{shareholder.investorInformation?.name}{" "}
							// 			<ArrowRight size={16} />
							// 			{contact.name}
							// 		</div>
							// 	),
							// 	ownerType: "companyContact",
							// 	isRegistered: false,
							// 	contactTags: contact.tags,
							// })) ?? []),
						];
					})
					.filter((s) => s.ownerStatus === "current"),
		},
	});

	const { pageData: pagedPeople, paginationElement } =
		usePagination(filteredPeople);

	const selectedPeople = filteredPeople.filter((p) => p._isFiltered);

	return (
		<DialogModal
			open
			size="xl"
			title={t("filter_people.submit.label")}
			onCancel={onCancel}
			submitDisabled={selectedPeople.length === 0}
			submitText={t("filter_people.submit.label")}
			onSubmit={() => {
				onCancel();
				onSubmit(selectedPeople);
			}}
			bodyRenderer={() => (
				<div>
					<div className="grid grid-cols-[280px_1fr] gap-6">
						{/* {isLoadingContactTags ? (
              <LoadingPanel />
            ) : ( */}
						<div className="flex flex-col gap-8">
							<h2 className="font-semibold">
								{t("filter_people.include_individuals")}
							</h2>
							<div className="flex flex-wrap gap-2">
								{renderFilterControl("individualIncludes")}
							</div>
							<h2 className="font-semibold">{t("type_of_owner")}</h2>
							<div className="flex flex-wrap gap-2">
								{renderFilterControl("ownerType_company")}
								{renderFilterControl("ownerType_companyContact")}
								{renderFilterControl("ownerType_private")}
								{renderFilterControl("ownerType_capitalInsurance")}
							</div>
							{/* <h2 className="font-semibold">
								{t("investors.filter.label.status")}
							</h2>
							<div className="flex flex-wrap gap-2">
								{renderFilterControl("ownerStatus_current")}
								{renderFilterControl("ownerStatus_former")}
								{renderFilterControl("ownerStatus_never")}
							</div> */}
							{/* <h2 className="font-semibold">Member status</h2>
                <div className="flex flex-wrap gap-2">
                  {renderFilterControl("isRegistered")}
                </div> */}
							{/* <h2 className="font-semibold">Contact tags</h2>
                <div className="flex flex-wrap gap-2">
                  {shareholderContactTags.map((tag) =>
                    renderFilterControl(`hasTag_${tag}`),
                  )}
                </div> */}
							<div>
								{filteredPeople.reduce(
									(acc, p) => acc + Number(p._isFiltered ?? 0),
									0,
								)}{" "}
								/ {filteredPeople.length}{" "}
								{t("filter_people.people").toLocaleLowerCase()}
							</div>
						</div>
						{/* )} */}
						<div>
							{isLoading ? (
								<LoadingPanel />
							) : (
								<div>
									<ItemList
										columns={[
											{ id: "checkbox", label: "" },
											{ id: "name", label: t("name") },
											{ id: "email", label: t("email") },
											{
												id: "ownerType",
												label: t("owner_type"),
											},
										]}
										columnSizing="30px 1fr 1fr 95px"
										isSortable
										rightAlignLastColumn
									>
										{pagedPeople.map((person) => {
											const handleCheckPerson = (checked) => {
												checked
													? addToFilter("individualIncludes", person.id)
													: removeFromFilter("individualIncludes", person.id);
											};

											return (
												<ItemListRow
													key={person.name}
													isChecked={person._isFiltered}
													onClick={() => handleCheckPerson(!person._isFiltered)}
												>
													<ItemListColumn.Checkbox
														onCheckedChange={handleCheckPerson}
													/>
													<ItemListColumn>{person.displayName}</ItemListColumn>
													<ItemListColumn>{person.email}</ItemListColumn>
													<ItemListColumn>
														{getOwnerTypeIcon(person.ownerType)}
													</ItemListColumn>
												</ItemListRow>
											);
										})}
									</ItemList>
									{paginationElement}
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		/>
	);
};

export default FilterPeople;
