import React from "react";

import StarterKit from "@tiptap/starter-kit";
import { PlaceholderValue } from "./extensions/placeholder";

import { generateHTML } from "@tiptap/react";
import Underline from "@tiptap/extension-underline";
import DialogModal from "../dialogModal";

const EditorPreview = ({ editor, record, onCancel }) => {
	return (
		<DialogModal
			open
			title={`Preview for ${record?.nameFull}`}
			onCancel={onCancel}
			submitText={null}
			bodyRenderer={() => (
				<div
					className="tiptap"
					dangerouslySetInnerHTML={{
						__html: generateHtmlForRecord(editor, record),
					}}
				/>
			)}
		/>
	);
};

export const generateHtmlForRecord = (editor, record) => {
	if (!editor) return "";

	return generateHTML(editor.getJSON(), [
		StarterKit,
		Underline,
		PlaceholderValue.configure({ record }),
	]);
};

export default EditorPreview;
