import React, { PureComponent } from "react";
import { string, func, bool, number, object } from "prop-types";
import styled from "styled-components";

import ButtonBase from "../base/button-base";
import LoadingButtonInsert from "../base/loading-button-insert";
import Text from "../../../text/text";
import { lighten, darken } from "polished";

const StyledTransparentButton = styled(ButtonBase)`
	min-width: 36px !important;
	min-height: 36px;
	padding: 0 ${(props) => props.theme.spacing[4]};

	:disabled {
		span {
			color: ${(props) => lighten(0.15, props.theme.colors[props.color])};
		}
	}

	:hover:not(:disabled) {
		span {
			transition: 300ms color;
			color: ${(props) => darken(0.15, props.theme.colors[props.color])};
		}
	}
`;

export default class TransparentButton extends PureComponent {
	static propTypes = {
		tid: string,
		values: object,
		textColor: string,
		fontSize: string,
		bold: number,
		isLoading: bool,
		disabled: bool,
		onClick: func,
		hasUnderline: bool,
	};

	static defaultProps = {
		fontSize: "16px",
		bold: 600,
		textColor: "dodgerBlue",
	};

	render = () => {
		const {
			onClick,
			tid,
			values,
			isLoading,
			disabled,
			textColor,
			fontSize,
			bold,
			hasUnderline,
		} = this.props;

		const btnProps = {
			disabled: isLoading || disabled,
			onClick,
			color: textColor,
		};

		const btnLoadingInsertProps = {
			isLoading,
		};

		const textProps = {
			color: textColor,
			fontSize,
			bold,
			tid,
			values,
			hasUnderline,
			children: this.props.children,
		};

		return (
			<StyledTransparentButton {...btnProps}>
				<LoadingButtonInsert {...btnLoadingInsertProps}>
					<Text {...textProps} />
				</LoadingButtonInsert>
			</StyledTransparentButton>
		);
	};
}
