import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { func, string, object } from "prop-types";
import { Padding } from "styled-components-spacing";
import Grid from "styled-components-grid";
import Panel from "../shared/panel/panel";

import { Button } from "@/components/ui/button";

import { useTranslation } from "react-i18next";
import CouponField from "@/components/subscriptions/couponField";
import SubscriptionBillingSummary from "@/components/subscriptions/subscriptionBillingSummary";
import SubscriptionPlanSelector from "@/components/subscriptions/subscriptionPlanSelector";

import useSubscription from "@/hooks/subscriptions/useSubscription";
import useSubscriptionSchedule from "@/hooks/subscriptions/useSubscriptionSchedule";
import useCompanyFromUrl from "@/hooks/companies/useCompanyFromUrl";

const PlansPanel = (props) => {
	const {
		selectedAccountPlanId,
		subscribedAccountPlan,
		isLoading,
		onSelectAccountPlan,
		onClickPurchase,
	} = props;
	const { t } = useTranslation();

	const { data: companyRequest } = useCompanyFromUrl();
	const company = companyRequest?.data;

	const companyDueForCancellation = company?.toBeCanceled;
	const companyId = company?.id;

	const { data: subscriptionRequest } = useSubscription();
	const subscription = subscriptionRequest?.data;

	const hasChangedAccountPlan =
		selectedAccountPlanId !== subscribedAccountPlan?.id;
	const isFreePlan = subscribedAccountPlan?.id === "freeAccount";

	const { data: subscriptionScheduleRequest } =
		useSubscriptionSchedule(companyId);
	const stripeSubscription = subscription?.stripeData?.subscriptions?.data[0];
	const subscriptionSchedule = subscriptionScheduleRequest?.data;

	const hasChangeScheduled =
		subscriptionSchedule?.cancel_at > 0 ||
		stripeSubscription?.cancel_at > 0 ||
		subscriptionSchedule?.phases?.length > 1;

	const disabled =
		hasChangeScheduled ||
		companyDueForCancellation ||
		selectedAccountPlanId === subscription?.accountPlanId ||
		companyDueForCancellation ||
		!hasChangedAccountPlan;

	const submitButtonTooltip = disabled
		? hasChangeScheduled
			? t("subscriptions.plan_change_already_scheduled")
			: undefined
		: undefined;

	return (
		<Panel mode="light" title="subscriptions.select_product" marginBottom>
			<Padding all={3}>
				<div className="flex flex-col p-4 gap-6">
					<SubscriptionBillingSummary />
					<SubscriptionPlanSelector
						onChange={(selectedAccountPlanId) => {
							onSelectAccountPlan(selectedAccountPlanId);
						}}
					/>
				</div>
				<Grid>
					<Grid.Unit size={1}>
						<div className="flex flex-col items-baseline gap-4 p-2">
							<div className="flex w-full gap-4 justify-between px-2">
								<div className="flex-1">
									<CouponField />
								</div>
								<div className="grid flex-1 items-end">
									<Button
										title={submitButtonTooltip}
										className="w-full"
										size="lg"
										isLoading={isLoading}
										onClick={onClickPurchase}
										disabled={disabled}
									>
										{t(
											isFreePlan
												? "subscriptions.checkout.go_to_payment"
												: selectedAccountPlanId === "freeAccount"
												? "subscriptions.button.cancel_subscription"
												: "subscriptions.purchase.change_subscription",
										)}
									</Button>
								</div>
							</div>
						</div>
					</Grid.Unit>
				</Grid>
			</Padding>
		</Panel>
	);
};

PlansPanel.defaultProps = {
	isLoading: false,
};

PlansPanel.propTypes = {
	plans: ImmutablePropTypes.list,
	selectedPlan: string,
	onSelectPlan: func,
	onClickPurchase: func,
	onSaveSubscription: func,
	selectedAccountPlan: object,
};

export default PlansPanel;
