/**
 * MainNav
 * @module components/menu/main_nav
 */

import React, { Component, createElement } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "../../interfaces/router";
import { Map } from "immutable";
import { fetchMenu } from "../../actions/menu.actions";
import { resetCompany, fetchCompany } from "../../actions/company.actions";

import ActivityBar from "../framework/activity-bar.js";

import { openModal } from "../../actions/modals.actions";
import { COMPANY_VERIFICATION_MODAL } from "../../constants/modals";
import CompanyVerifyModalContainer from "../../containers/company/company-verify-modal.container";
import history from "../../interfaces/history";

import {
	EVENT_TYPE_COMPANY_OWNER_CHANGED,
	EVENT_TYPE_COMPANY_UPDATE,
	EVENT_TYPE_SHARES_INVESTMENT_LINKED,
} from "/shared/constants";

import withResolvedProps from "@/hocs/withResolvedProps";
import useRestrictAccess from "@/hooks/subscriptions/useRestrictAccess";
import useUserCompanies from "../../hooks/companies/useUserCompanies.js";
import CompanyAvatar from "@/components/companyAvatar";

import { icons as lucideIcons } from "lucide-react";
import i18n from "@/i18n";
import { cn } from "../utils";

const { t } = i18n;

class MainNav extends Component {
	componentDidMount() {
		this.loadMenu();
	}

	componentDidUpdate = (prevProps) => {
		const { company, userCompanies } = this.props;

		if (userCompanies && prevProps.userCompanies !== userCompanies) {
			const companyId = company && company.id;
			const exist = userCompanies.find((company) => {
				return company.get("id") === companyId && !company.get("isCanceled");
			});

			if (!exist) {
				this.props.resetCompany();
			}
		}

		if (
			userCompanies &&
			!company &&
			prevProps.userCompanies !== userCompanies
		) {
			const filteredCompanies = userCompanies.filter((company) => {
				const notDemo = !company.get("isUserDemo");
				const notCanceled = !company.get("isCanceled");
				return notDemo && notCanceled;
			});

			// User has only one ACTIVE company (excluding demo and cancelled companies)
			if (filteredCompanies.size === 1) {
				const companyId = filteredCompanies.getIn([0, "id"]);
				this.props.fetchCompany(companyId, true);
			}
		}

		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const { audit, investorAudit, company } = this.props;
		const companyUpdate = audit.get(EVENT_TYPE_COMPANY_UPDATE, Map());
		const companyOwnerChanged = audit.get(
			EVENT_TYPE_COMPANY_OWNER_CHANGED,
			Map(),
		);

		if (
			companyUpdate.get("refresh") === true &&
			companyUpdate.hasIn(["metadata", "sharesInitialized"])
		) {
			this.loadMenu();
		} else if (
			companyUpdate.get("refresh") === true &&
			!companyUpdate.hasIn(["metadata", "sharesInitialized"])
		) {
			this.props.fetchCompany(company && company.id, true);
		}

		const SHARES_INVESTMENT_LINKED = investorAudit.get(
			EVENT_TYPE_SHARES_INVESTMENT_LINKED,
		);

		if (
			SHARES_INVESTMENT_LINKED &&
			SHARES_INVESTMENT_LINKED.get("refresh") === true
		) {
			this.loadMenu();
		}

		if (companyOwnerChanged.get("refresh")) {
			this.loadMenu();
		}
	};

	loadMenu = () => {
		this.props.fetchMenu("main");
	};

	goToCompanyLandingPage = () => {
		const { company, history } = this.props;
		history.push(`/${company.urlAlias}`);
	};

	openCompanyVerifyModal = () => {
		this.props.openModal(COMPANY_VERIFICATION_MODAL);
	};

	renderMenu(item) {
		const topLevel = item.level === 0;

		const icon = topLevel
			? null
			: createElement(lucideIcons[item.icon ?? "Circle"], {
					className: !item.icon ? "invisible" : "",
					size: 14,
			  });

		const applyRestriction = (element) =>
			item.restrict
				? this.props.restrict(element, { requiredFeature: item.restrict })
				: element;

		let output = (
			<div className="flex flex-col">
				{topLevel ? (
					<div className="uppercase text-sm text-slate-500 mt-3 pb-3">
						{t(item.translationId)}
					</div>
				) : (
					applyRestriction(
						<NavLink
							to={item.url}
							activeClassName={cn({
								"!text-slate-50 bg-brand-800/50 hover:bg-brand-800/50":
									!topLevel,
							})}
							className={cn(
								"flex gap-3 items-center text-slate-300 font-semibold text-md p-3 rounded",
								{ "hover:text-slate-50 hover:bg-brand-800/10": !topLevel },
							)}
						>
							{icon} {t(item.translationId)}
						</NavLink>,
					)
				)}

				{item.children?.map?.(this.renderMenu.bind(this))}
			</div>
		);

		return output;
	}

	render() {
		const { userResources, company, companyResources, userCompaniesQuery } =
			this.props;
		const hasCompanyResources = companyResources?.length > 0;
		const companyIsVerified = company ? company.verified : true;

		const userCompanies = userCompaniesQuery?.data?.toSorted?.((a, b) => {
			return a.id === company?.id ? -1 : b.id === company?.id ? 1 : 0;
		});

		const selectedCompany = company;

		return (
			<nav className="menu">
				<Link to="/" className="flex min-h-[40px] mt-2 mb-4 overflow-hidden">
					<ActivityBar />
				</Link>

				<div className="flex flex-col gap-4 px-4 h-full">
					<div className="flex flex-col gap-2 pb-4 rounded-xl">
						{userResources?.map?.(this.renderMenu.bind(this))}
					</div>
					<div className="flex flex-col gap-3 overflow-auto h-full pb-[180px]">
						{userCompanies?.map?.((company) => {
							const isActive = selectedCompany?.id === company.id;

							return (
								<div key={company.id}>
									<div
										className={cn(
											"rounded-xl border border-slate-700 shadow-lg transition-colors",
											{ "bg-brand-800/30": isActive },
											{
												"hover:[&_*]:text-slate-100 hover:bg-brand-800/30":
													!isActive,
											},
										)}
									>
										<Link to={`/${company.urlAlias}`}>
											<div className="p-2 flex items-center gap-4 text-slate-500 font-semibold cursor-pointer">
												<CompanyAvatar
													companyId={company.id}
													companyName={company.name}
												/>
												<div className={cn({ "text-slate-100": isActive })}>
													{company.name}
												</div>
											</div>
										</Link>
										{isActive && (
											<div className="border-t border-slate-700 mt-1" />
										)}
										<div className="px-2">
											{isActive &&
												hasCompanyResources &&
												companyResources.map(this.renderMenu.bind(this))}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				{!companyIsVerified && <CompanyVerifyModalContainer />}
				{this.props.restrictDialogElement}
			</nav>
		);
	}
}

function mapStateToProps(state) {
	return {
		history: history,
		userResources: state.menu.userResources,
		companyResources: state.menu.companyResources,
		company: state.company.company,
		userCompanies: state.company.companies,
		audit: state.audit.get("company"),
		investorAudit: state.audit.get("investors"),
	};
}

const mapDispatchToProps = {
	fetchMenu,
	resetCompany,
	fetchCompany,
	openModal,
};

const MainNavResolved = withResolvedProps(({ company }) => {
	const { restrict, restrictDialogElement } = useRestrictAccess(company?.id);
	const { data: userCompaniesQuery } = useUserCompanies("me");

	return { userCompaniesQuery, restrict, restrictDialogElement };
})(MainNav);

export default connect(mapStateToProps, mapDispatchToProps)(MainNavResolved);
