// Handles form validation logic with an output
// intended for use with the `withValidation` HOC.

/* 
  const { email, setEmail } = useState("");

  const { validationFor, validationMessages, validationVariants } = useValidation({
    validators: {
      email: assert => {
        assert(email, "This field is required")
        assert(email.length > 2, "Must be at least 3 characters")
        assert(email.includes("@"), "Must include an @ symbol")
    }
  });
    
  return (
    <Field
      id="email"
      value={email}
      validationMessage={validationMessages.email}
      validationVariant={validationVariants.email}

    />
		<Button onClick={validate}>Validate</Button>
  )
*/

import { useEffect, useState } from "react";

export default function useValidation(validators = {}) {
	const [validationMessages, setValidationMessages] = useState({});
	const [isValidationInitiated, setIsValidationInitiated] = useState(false);

	// Generate a summary (string) of the validation state
	let summary =
		isValidationInitiated &&
		Object.keys(validators).reduce((acc, key) => {
			const assert = (value) => {
				acc += `${!!value},`;
			};
			acc += `${key}[`;
			validators[key](assert);
			acc += `]_`;
			return acc;
		}, "");

	// Refresh validation when the summary changes
	// (once validation has been initiated (run for the first time)
	useEffect(() => {
		if (!isValidationInitiated) return;
		validate();
	}, [summary, isValidationInitiated]);

	/**
	 * Runs the validation logic and updates `validationMessages`.
	 *
	 * @returns {boolean} - Returns true if the form is valid
	 */
	const validate = () => {
		setIsValidationInitiated(true);

		const messages = {};
		let isValid = true;

		Object.keys(validators).forEach((key) => {
			messages[key] = [];
			const assert = (value, message) => {
				if (!value) {
					isValid = false;
					messages[key].push(message);
				}
			};
			validators[key](assert);
		});

		setValidationMessages(messages);

		return isValid;
	};

	return {
		validate,
		validationMessages,
	};
}
