import DialogModal from "@/components/dialogModal";
import LoadingPanel from "@/components/loadingPanel";
import { Input } from "@/components/ui/input";
import useSubscription from "@/hooks/subscriptions/useSubscription";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUpdateSubscriptionInvoiceReiceiver from "@/hooks/subscriptions/useUpdateSubscriptionInvoiceReceiver";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import { EXTERNAL_INVOICE_RECEIVER } from "/shared/constants";
import { isRequired, validateEmail } from "@/modules/validation.module";
import useValidation from "@/hooks/useValidation";

const ExternalReferenceModal = ({ onCancel }) => {
	const { t } = useTranslation();
	const [email, setEmail] = useState();
	const [name, setName] = useState();

	const companyId = useCompanyIdFromUrl();

	const { data: subscriptionRequest, isLoading: isLoadingSubscription } =
		useSubscription();
	const subscription = subscriptionRequest?.data;

	const isLoading = !companyId || isLoadingSubscription;

	const { validate, validationMessages } = useValidation({
		name: (assert) => {
			assert(isRequired(name), t("validation.is_required"));
		},
		email: (assert) => {
			assert(validateEmail(email), t("validation.email"));
		},
	});

	useEffect(() => {
		if (!companyId) return;
		if (!subscription?.name) return;
		if (!subscription?.email) return;

		setName(subscription.name);
		setEmail(subscription.email);
	}, [companyId, subscription?.name, subscription?.email]);

	const {
		mutate: updateSubscriptionInvoiceReiceiver,
		isPending: isUpdatingSubscriptionInvoiceReceiver,
	} = useUpdateSubscriptionInvoiceReiceiver(companyId);

	const updateExternalReference = () => {
		const isValid = validate();

		if (!isValid) return;

		updateSubscriptionInvoiceReiceiver(
			{
				invoiceReceiver: EXTERNAL_INVOICE_RECEIVER,
				externalInvoiceDetails: {
					name,
					email,
				},
			},
			{ onSuccess: () => onCancel() },
		);
	};

	return (
		<DialogModal
			open
			size="sm"
			title={t("subscriptions.external_reference.modal.title")}
			bodyRenderer={() =>
				isLoading ? (
					<LoadingPanel />
				) : (
					<div className="grid grid-cols-2 gap-6">
						<div>
							<Input
								type="text"
								id="reference-name-input"
								value={name}
								label={t("generic.name")}
								onChange={(evt) => setName(evt.target.value)}
								validationMessage={validationMessages.name}
								autoFocus
							/>
						</div>
						<div>
							<Input
								type="email"
								id="reference-email-input"
								label={t("generic.email")}
								value={email}
								onChange={(evt) => setEmail(evt.target.value)}
								validationMessage={validationMessages.email}
							/>
						</div>
					</div>
				)
			}
			onSubmit={updateExternalReference}
			isSubmitting={isUpdatingSubscriptionInvoiceReceiver}
			onCancel={onCancel}
		/>
	);
};

ExternalReferenceModal.propTypes = {
	onCancel: DialogModal.propTypes.onCancel,
};

ExternalReferenceModal.defaultProps = {
	onCancel: DialogModal.defaultProps.onCancel,
};

export default ExternalReferenceModal;
