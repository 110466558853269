import React from "react";
import useUserComm from "@/hooks/notifications/useUserComm";
import DialogModal from "@/components/dialogModal";
import useCreateSendEmailJobs from "@/hooks/notifications/useCreateSendEmailJobs";
import useSendEmailJobPreviews from "../../hooks/notifications/useSendEmailJobPreviews";

const UserCommSendPanel = ({ userCommId, onCancel, onComplete, editor }) => {
	const { data: userCommQuery, isLoading: isLoadingUserComm } =
		useUserComm(userCommId);
	const userComm = userCommQuery?.data;

	const { mutateAsync: createSendEmailJobs } = useCreateSendEmailJobs(
		"userComm",
		userCommId,
	);

	const {
		data: sendEmailJobPreviews,
		isLoading: isLoadingUseSendEmailJobPreviews,
	} = useSendEmailJobPreviews(userComm.recipients, editor);

	const isLoading = isLoadingUserComm || isLoadingUseSendEmailJobPreviews;

	const sendableEmailJobs = sendEmailJobPreviews.filter(
		(preview) => preview.email?.length > 1,
	);
	const sendableEmailCount = sendableEmailJobs.length;

	return (
		<DialogModal
			open
			size="sm"
			onCancel={onCancel}
			isLoading={isLoading}
			title="Send Emails"
			submitText={`Send ${sendableEmailCount} emails`}
			onSubmit={async () => {
				const jobs = sendableEmailJobs.map((preview) => ({
					email: preview.email,
					subject: userComm.subject,
					body: preview.body,
				}));

				await createSendEmailJobs(jobs);

				onComplete();
			}}
			bodyRenderer={() => {
				return (
					<div>
						Are you sure you want to send {sendableEmailCount} emails. Note that
						emails will only be sent to recipients with valid email addresses.
					</div>
				);
			}}
		/>
	);
};

export default UserCommSendPanel;
