import React, { Component } from "react";
import { connect } from "react-redux";

import { number } from "prop-types";
import ViaGuiPanel from "../../dumb-components/shares/excel-via-gui-export-import/via-gui-panel";
import Text from "../../dumb-components/shared/text/text";
import Button from "../../dumb-components/shared/button/button";
import styled from "styled-components";

import { SHARE_ISSUE_GRID_MODAL } from "../../constants/modals";
import { openModal } from "../../actions/modals.actions";
import { listInvestors } from "../../actions/investors.actions";
import EmissionGridContainer from "./grids/emission-grid.container";
import NewEmissionAlert from "../../dumb-components/shares/excel-via-gui-export-import/new-emission-alert";

const StyledChildrenWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing[4]};
`;

const VIA_GUI_PANEL_TIDS = {
	headerLeftComponentTid: "new_share_issue.dark_panel.via_gui.header.info",
	footerTid: "new_share_issue.dark_panel.via_gui.footer.info",
	footerButtonTid: "new_share_issue.dark_panel.button.open_grid",
	editButtonTid: "new_share_issue.dark_panel.via_gui.header.info.edit.btn",
};

const editBtn = (
	<Text tid={VIA_GUI_PANEL_TIDS.editButtonTid} bold={700} color="white" />
);

class ViaGuiContainer extends Component {
	static propTypes = {
		sharesRemaining: number,
	};
	componentDidMount = () => {
		const { investors, listInvestors } = this.props;

		if (!investors || investors.size === 0) {
			listInvestors(true);
		}
	};

	openGridModal = () => {
		const { openModal } = this.props;
		openModal(SHARE_ISSUE_GRID_MODAL);
	};

	renderHeaderLeftComponent = () => {
		return (
			<div>
				<Text
					tid={VIA_GUI_PANEL_TIDS.headerLeftComponentTid}
					color="white"
					values={{ editBtn }}
				/>
			</div>
		);
	};

	renderFooterComponent = () => {
		return (
			<div>
				<Text
					tid={VIA_GUI_PANEL_TIDS.footerTid}
					color="white"
					values={{ editBtn }}
				/>
				<StyledChildrenWrapper>
					<Button
						tid={VIA_GUI_PANEL_TIDS.footerButtonTid}
						mode="primary"
						onClick={() => {
							return this.openGridModal();
						}}
					/>
				</StyledChildrenWrapper>
			</div>
		);
	};

	renderViaGuiPanel = () => {
		return (
			<ViaGuiPanel
				headerLeftComponent={this.renderHeaderLeftComponent}
				footerComponent={this.renderFooterComponent}
			/>
		);
	};

	renderNewEmissionAlert = () => {
		const { sharesRemaining } = this.props;

		return <NewEmissionAlert sharesRemaining={sharesRemaining} />;
	};

	render = () => {
		const { modalIsOpen } = this.props;

		return (
			<>
				{this.renderViaGuiPanel()}
				{this.renderNewEmissionAlert()}
				{modalIsOpen && <EmissionGridContainer gridModalIsOpen />}
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		modalIsOpen:
			store.modals.getIn(["activeModal", "name"]) === SHARE_ISSUE_GRID_MODAL,
		investors: store.investors.get("list"),
	};
};
const mapActionsToProps = {
	openModal,
	listInvestors,
};
export default connect(mapStoreToProps, mapActionsToProps)(ViaGuiContainer);
