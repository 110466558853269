import Cookies from "universal-cookie";
import req from "../modules/request.module";
import { isImmutable } from "immutable";
import Moment from "../modules/moment.module";
import history from "../interfaces/history";
import queryClient from "../queryClient";

import {
	AUTH_ERROR,
	NOTIFY_RETURN_URL,
	CREDENTIALS_RESPONSE,
	CREDENTIALS_SET_AUTENTICATED,
	CREDENTIALS_RESET,
	CREDENTIALS_SIGNOUT,
	CREDENTIALS_SET_TID,
	CREDENTIALS_RESET_TIDS,
	CREDENTIALS_RESET_FORGOT_PASSWORD,
} from "./types";

export function authBankId(mode, sessionId, strategy, formData, callback) {
	return function (dispatch, getState) {
		if (isImmutable(formData)) {
			formData = formData.toJS();
		}

		let url;
		if (mode === "SIGNIN") {
			url = "/users/public/signin";
		} else {
			url = "/users/public/signup";
		}

		const data = {
			sessionId,
			strategy,
			...formData,
		};

		return req
			.post(url, data)
			.then((response) => {
				const {
					accessToken,
					status,
					sessionId,
					detailsToRemeber,
					changeStrategy,
					hintCode,
				} = response.data;

				if (hintCode === "invalidParameters") {
					dispatch(resetCredentials());
					dispatch(setBankIDText(null));
					throw new Error();
				}

				if (changeStrategy === "SIGNUP-BANKID") {
					const payload = {
						...response.data,
						ssn: formData.ssn,
						sessionId: null,
					};

					dispatch({ type: CREDENTIALS_RESPONSE, payload });
					return history.push("/signup?ref=signin");
				}

				if (accessToken) {
					const state = getState();
					const returnUrl = state.notify.returnUrl || "/";
					new Cookies().set("accessToken", accessToken, { path: "/" });

					if (detailsToRemeber && strategy === "SIGNIN-BANKID") {
						new Cookies().set("rememberDetailsBankId", detailsToRemeber, {
							path: "/",
							expires: Moment().add(1, "year").toDate(),
						});
						new Cookies().remove("rememberDetailsEmail");
					} else if (formData.rememberDetails && strategy === "SIGNIN-BASIC") {
						new Cookies().set(
							"rememberDetailsEmail",
							{
								email: formData.email,
								rememberDetails: true,
								useRememberDetails: true,
							},
							{ path: "/", expires: Moment().add(1, "year").toDate() },
						);
						new Cookies().remove("rememberDetailsBankId");
					}

					dispatch(setAuthenticated(true));
					dispatch(resetCredentials());
					dispatch({ type: NOTIFY_RETURN_URL, payload: null });
					history.push(returnUrl);
					callback();
				} else {
					dispatch({ type: CREDENTIALS_RESPONSE, payload: response.data });
					if (status === "pending") {
						setTimeout(() => {
							dispatch(
								authBankId(mode, sessionId, strategy, formData, callback),
							);
						}, 1000);
					} else {
						callback();
					}
				}
			})
			.catch((err) => {
				callback(err);
			});
	};
}

export function forgotPassword(sessionId, strategy, formData, callback) {
	return function (dispatch) {
		if (isImmutable(formData)) {
			formData = formData.toJS();
		}

		const data = {
			sessionId,
			strategy,
			...formData,
		};

		return req
			.post("/users/public/forgot-password", data)
			.then((response) => {
				const { strategyCompleted } = response.data;
				dispatch({ type: CREDENTIALS_RESPONSE, payload: response.data });

				if (strategyCompleted) {
					resetForgotPassword();
					history.push("/signin");
				}

				callback();
			})
			.catch((err) => {
				callback(err);
			});
	};
}

export function resetForgotPassword() {
	return function (dispatch) {
		dispatch({ type: CREDENTIALS_RESET_FORGOT_PASSWORD });
	};
}

export function resetCredentials() {
	return {
		type: CREDENTIALS_RESET,
	};
}

export function setBankIDText(tid) {
	return {
		type: CREDENTIALS_SET_TID,
		payload: tid,
	};
}

export function cancelBankId(orderRef) {
	return function (_dispatch) {
		return req.post("/users/public/bankid/cancel", { orderRef });
	};
}

export function refreshToken() {
	return function (dispatch) {
		return req
			.post("/users/auth-refresh")
			.then((response) => {
				new Cookies().set("accessToken", response.data.accessToken, {
					path: "/",
				});
			})
			.catch((err) => {
				dispatch(authError(err.response.data));
				return new Error();
			});
	};
}

export function setAuthenticated(isAuthenticated) {
	return {
		type: CREDENTIALS_SET_AUTENTICATED,
		payload: isAuthenticated,
	};
}

export function signout() {
	new Cookies().remove("accessToken", { path: "/" });
	new Cookies().remove("showDangerBorders", { path: "/" });

	// Make sure that the 'me' query is cleared from the cache
	queryClient.resetQueries({ queryKey: ["me"] });

	return { type: CREDENTIALS_SIGNOUT };
}

export function resetTids() {
	return function (dispatch) {
		dispatch({ type: CREDENTIALS_RESET_TIDS });
	};
}

function authError(error) {
	return {
		type: AUTH_ERROR,
		payload: error,
	};
}
