import { connect } from "react-redux";
import history from "../../interfaces/history";
import useSubscriptionHelper from "@/hooks/subscriptions/useSubscriptionHelper";

function GoToBillingComponent({ companyUrlAlias, onDone, renderComponent }) {
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	const isSubscriptionAdmin = !!subscriptionHelper?.isSubscriptionAdmin;

	const goToSubscription = () => {
		history.push(`/${companyUrlAlias}/subscriptions/`);
		onDone && onDone();
	};

	if (!isSubscriptionAdmin) {
		return null;
	}

	return renderComponent(goToSubscription);
}

const mapStateToProps = (state) => {
	return {
		history: history,
		companyUrlAlias: state.company.company?.urlAlias,
	};
};

export default connect(mapStateToProps, {})(GoToBillingComponent);
