import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AddPaymentMethodModal, {
	AddPaymentMethodButton,
} from "./addPaymentMethodModal";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent } from "@/components/ui/card";
import { Trash2 } from "lucide-react";

import usePaymentMethods from "@/hooks/subscriptions/usePaymentMethods";
import useSubscription from "@/hooks/subscriptions/useSubscription";
import useUpdateStripeCustomer from "@/hooks/subscriptions/useUpdateStripeCustomer";
import useDeletePaymentMethod from "@/hooks/subscriptions/useDeletePaymentMethod";
import useQueryParams from "@/hooks/useQueryParams";
import useDialogModal from "@/hooks/useDialogModal";

import iconVisa from "@/assets/images/payment-providers/visa.svg";
import iconMastercard from "@/assets/images/payment-providers/mastercard.svg";
import iconAmex from "@/assets/images/payment-providers/amex.svg";
import iconDiners from "@/assets/images/payment-providers/diners.svg";
import iconJcb from "@/assets/images/payment-providers/jcb.svg";
import iconDiscover from "@/assets/images/payment-providers/discover.svg";
import iconUnknown from "@/assets/images/payment-providers/unknown.svg";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import LoadingPanel from "../loadingPanel";
import { ItemList, ItemListColumn, ItemListRow } from "../itemList";

const PaymentMethods = ({ validationMessage }) => {
	const companyId = useCompanyIdFromUrl();
	const { t } = useTranslation();
	const [isAddPaymentDetailsOpen, setIsAddPaymentDetailsOpen] = useState(false);
	const { confirm, dialogElement } = useDialogModal();

	const { data: paymentMethodsRequest, isLoading } = usePaymentMethods();

	const paymentMethods = paymentMethodsRequest?.data;

	const {
		mutate: updateStripeCustomer,
		isPending: isUpdatingCustomerPending,
		variables: updatingCustomerVariables,
	} = useUpdateStripeCustomer(companyId);

	const {
		mutate: deletePaymentMethod,
		isPending: isDeletingPaymentMethod,
		variables: deletePaymentMethodId,
	} = useDeletePaymentMethod(companyId);

	const { data: subscriptionRequest } = useSubscription();

	const [getQueryParam, setQueryParam] = useQueryParams();

	const setupIntentQueryParam = getQueryParam("setup_intent");
	const setupIntentClientSecretQueryParam = getQueryParam(
		"setup_intent_client_secret",
	);
	const redirectStatusQueryParam = getQueryParam("redirect_status");

	const paymentMethodWasAdded =
		setupIntentQueryParam &&
		setupIntentClientSecretQueryParam &&
		redirectStatusQueryParam === "succeeded";

	const isInvoiced =
		updatingCustomerVariables?.metadata?.isInvoiced === "true" ||
		subscriptionRequest?.data?.stripeData?.metadata?.isInvoiced === "true";

	const selectPaymentMethod = (value) => {
		if (!["card", "invoice"].includes(value)) return;

		const invoiceSelected = value === "invoice";

		updateStripeCustomer({
			invoice_settings: {
				default_payment_method: invoiceSelected
					? null
					: paymentMethods?.[0]?.id,
			},
			metadata: {
				isInvoiced: String(invoiceSelected),
			},
		});
	};

	// Auto select the latest payment method as default
	useEffect(() => {
		if (paymentMethods && paymentMethodWasAdded) {
			setQueryParam("setup_intent", null);
			setQueryParam("setup_intent_client_secret", null);
			setQueryParam("redirect_status", null);

			const latestPaymentMethod = paymentMethods[0];

			updateStripeCustomer({
				invoice_settings: {
					default_payment_method: latestPaymentMethod.id,
				},
			});
		}
	}, [paymentMethodWasAdded, paymentMethods]);

	const onDeletePaymentMethod = async (paymentMethodId) => {
		if (
			await confirm(
				t("subscriptions.payment_methods.delete_payment_method"),
				t("subscriptions.payment_methods.confirm_delete.body"),
				{
					submitText: t("subscriptions.payment_methods.delete_payment_method"),
					variant: "destructive",
				},
			)
		) {
			deletePaymentMethod(paymentMethodId);
		}
	};

	if (isLoading) return <LoadingPanel />;

	return (
		<>
			<Card
				isHighlighted={!isInvoiced}
				validationMessage={!isInvoiced && validationMessage}
			>
				<CardContent className="pt-6">
					<div className="flex justify-between items-start gap-4">
						<input
							id="card-payment-input"
							type="radio"
							onChange={() => selectPaymentMethod("card")}
							checked={!isInvoiced}
						/>
						<div className="flex-1">
							<div className="flex gap-4 flex-col">
								<label
									htmlFor="card-payment-input"
									className="flex gap-2 items-center text-lg font-bold cursor-pointer mb-0"
								>
									{t("subscriptions.payment_methods.pay_by_card")}
								</label>
								{!isInvoiced && paymentMethods?.length > 0 && (
									<ItemList columnSizing="1fr 200px" rightAlignLastColumn>
										{paymentMethods?.map((paymentMethod) => {
											const { card } = paymentMethod;

											const isDefault =
												paymentMethod.id ===
												subscriptionRequest.data?.stripeData?.invoice_settings
													?.default_payment_method;

											const iconUrl =
												{
													visa: iconVisa,
													mastercard: iconMastercard,
													amex: iconAmex,
													diners: iconDiners,
													jcb: iconJcb,
													discover: iconDiscover,
												}[card?.brand?.toLowerCase()] || iconUnknown;

											return (
												<ItemListRow key={paymentMethod.id}>
													<ItemListColumn>
														<div className="flex gap-4">
															<div className="flex items-center">
																<img
																	className="rounded-md"
																	src={iconUrl}
																	width={40}
																/>
															</div>
															<div className="flex-1">
																<div>
																	<strong>**** {card.last4}</strong>
																</div>
																<div>
																	{t(
																		"subscriptions.payment_methods.card_expiration",
																		{
																			month: card.exp_month,
																			year: card.exp_year,
																		},
																	)}
																</div>
															</div>
														</div>
													</ItemListColumn>
													<ItemListColumn>
														<div className="flex items-center justify-center">
															{isDefault ? (
																<Badge>
																	{t("subscriptions.payment_methods.default")}
																</Badge>
															) : (
																<Button
																	variant="link"
																	disabled={isInvoiced}
																	onClick={() =>
																		updateStripeCustomer({
																			invoice_settings: {
																				default_payment_method:
																					paymentMethod.id,
																			},
																			metadata: {
																				isInvoiced: "false",
																			},
																		})
																	}
																	isLoading={
																		isUpdatingCustomerPending &&
																		updatingCustomerVariables?.invoice_settings
																			?.default_payment_method ===
																			paymentMethod.id
																	}
																>
																	{t(
																		"subscriptions.payment_methods.set_default",
																	)}
																</Button>
															)}
														</div>
														<div className="flex items-center justify-end">
															<Button
																variant="ghost"
																onClick={() =>
																	onDeletePaymentMethod(paymentMethod.id)
																}
																isLoading={
																	isDeletingPaymentMethod &&
																	deletePaymentMethodId === paymentMethod.id
																}
															>
																<Trash2 size={20} />
															</Button>
														</div>
													</ItemListColumn>
												</ItemListRow>
											);
										})}
									</ItemList>
								)}
							</div>
						</div>
					</div>
					{!isInvoiced && (
						<div className="grid place-items-center">
							<AddPaymentMethodButton
								disabled={isInvoiced}
								onClick={() => setIsAddPaymentDetailsOpen(true)}
							/>
						</div>
					)}
					{isAddPaymentDetailsOpen && (
						<AddPaymentMethodModal
							onCancel={() => setIsAddPaymentDetailsOpen(false)}
						/>
					)}
				</CardContent>
			</Card>
			<Card
				isHighlighted={isInvoiced}
				validationMessage={isInvoiced && validationMessage}
			>
				<CardContent className="pt-6 flex justify-between items-start gap-4">
					<input
						id="invoice-payment-input"
						type="radio"
						onChange={() => selectPaymentMethod("invoice")}
						checked={isInvoiced}
					/>
					<div className="flex-1">
						<div className="flex gap-4 flex-col">
							<label
								htmlFor="invoice-payment-input"
								className="flex gap-2 items-center text-lg font-bold cursor-pointer mb-0"
							>
								{t("subscriptions_payment_methods.pay_by_invoice")}
							</label>
							{isInvoiced &&
								t("subscriptions_payment_methods.pay_by_invoice.hint")}
						</div>
					</div>
				</CardContent>
			</Card>
			{dialogElement}
		</>
	);
};

export default PaymentMethods;
