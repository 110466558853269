import { useQuery } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";

export default () => {
	return useQuery({
		queryKey: ["unsubscribeGroups"],
		queryFn: () => {
			return apiClient.get(`notifications/marketing/unsubscribe-groups`);
		},
	});
};
