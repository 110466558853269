import React, { useState } from "react";
import investmentApi from "/shared/helpers/investment.helper";
import { AddPersonModal } from "../../../../../containers/shared/add-person-modal/add-person-modal";
import { array, func } from "prop-types";
import { useDispatch } from "react-redux";
import { addErrorNotification } from "../../../../../actions/notify.actions";
import uuid from "uuid/v4";
import SimpleDarkInfoPanelWithControls from "../../../../../dumb-components/shared/dark-info-panel/simple-dark-info-panel-with-controls";
import { OutlinedButton } from "../../../../../dumb-components/shared/button-v2";
import { ItemListColumn, ItemList, ItemListRow } from "@/components/itemList";
import { Mail, Pencil, Phone, Trash2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import useDialogModal from "@/hooks/useDialogModal";

function InvestorsContactBook({ onChange, values }) {
	const [isOpen, setIsOpen] = useState(false);
	const [contact, setContact] = useState();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { confirm, dialogElement } = useDialogModal();

	const handleContact = (person) => {
		person.id = person.id || uuid();

		try {
			investmentApi.validateContact(person);
		} catch (err) {
			dispatch(addErrorNotification({ text: err.message }));
			return;
		}

		const index = values.findIndex((value) => value.id === person.id);

		if (index >= 0) {
			values[index] = person;
			setContact(null);
		} else {
			values.push(person);
		}

		onChange(values);
		setIsOpen(false);
	};

	const deleteContact = async (id) => {
		const isConfirmed = await confirm(undefined, undefined, {
			variant: "destructive",
			submitText: t("remove"),
		});

		if (!isConfirmed) return;

		values = values.filter((value) => value.id !== id);
		onChange(values);
	};

	const editContact = (id) => {
		const person = values.find((value) => value.id === id);
		setContact({ ...person });
		setIsOpen(true);
	};

	return (
		<div className="flex flex-col gap-6">
			<SimpleDarkInfoPanelWithControls
				tid={"investors-contact-book.container_info"}
				rightControlsComponent={
					<OutlinedButton icon="faPlus" onClick={() => setIsOpen(true)} />
				}
			/>

			<ItemList columnSizing="140px 200px 120px 40px" rightAlignLastColumn>
				{values.map(({ id, name, email, phone, tags }) => (
					<ItemListRow key={id}>
						<ItemListColumn className="font-semibold">{name}</ItemListColumn>
						<ItemListColumn>
							<div className="flex flex-col">
								<div className="flex gap-2 items-center">
									<Mail size={14} className="text-slate-400" />
									{email}
								</div>
								{phone && (
									<div className="flex gap-2 items-center">
										<Phone size={14} className="text-slate-400" />
										{phone}
									</div>
								)}
							</div>
						</ItemListColumn>
						<ItemListColumn.Badges
							data={tags?.map?.((t) => ({
								id: t,
								name: t,
								variant: "outline",
							}))}
						/>
						<ItemListColumn.Actions
							options={[
								{
									label: t("generic.edit"),
									icon: <Pencil />,
									onClick: () => editContact(id),
								},
								{
									label: t("generic.remove"),
									icon: <Trash2 />,
									onClick: () => deleteContact(id),
								},
							]}
						/>
					</ItemListRow>
				))}
			</ItemList>

			<AddPersonModal
				isOpen={isOpen}
				onSave={handleContact}
				onCancel={() => setIsOpen(false)}
				person={contact}
				showCategoryField={false}
				showTagsField
			/>
			{dialogElement}
		</div>
	);
}

InvestorsContactBook.propTypes = {
	onChange: func.isRequired,
	values: array.isRequired,
};

export { InvestorsContactBook };
