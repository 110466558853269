import { func, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAdverts } from "../../../actions/adverts.actions";
import { CompanyAdvertsModal } from "./CompanyAdvertsModal/CompanyAdvertsModal";

function CompanyAdverts({ forView, children }) {
	const [adverts, setAdverts] = useState([]);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedAdvert, setSelectedAdvert] = useState();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			fetchAdverts(forView, (error, response) => {
				if (error) {
					return;
				}

				setAdverts(response);
			}),
		);
	}, []);

	const openAdvertModal = (advertId) => {
		setModalIsOpen(true);
		setSelectedAdvert(adverts.find(({ id }) => id === advertId));
	};

	const closeAdvertModal = () => {
		setModalIsOpen(false);
		setSelectedAdvert(null);
	};

	return (
		<>
			{children(adverts, openAdvertModal)}
			{modalIsOpen && (
				<CompanyAdvertsModal
					advert={selectedAdvert}
					closeModal={closeAdvertModal}
				/>
			)}
		</>
	);
}

CompanyAdverts.propTypes = {
	forView: string.isRequired,
	children: func.isRequired,
};

export { CompanyAdverts };
