import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "../modules/moment.module";
import Cookies from "universal-cookie";
import AppNavBar from "./menu/nav-bar";
import MainNav from "./menu/main-nav";
import Notify from "./framework/notify";
import ProcessingBar from "./framework/processing-bar";
import IdleTimer from "react-idle-timer";
import DropzoneFullscreenDetector from "../containers/app/dropzone-fullscreen-detector.container";
import ScreenMessageContainer from "../containers/shared/screen-message.container";
import SimpleConfirmationModalContainer from "../containers/shared/simple-confirmation-modal.container";
import TaskMoveModalContainer from "../containers/tasks/tasks-move-task-modal.container";
import ChatVisibilityControllerContainer from "../containers/app/chat-visibility-controller.container";
import DocumentDownloadSignedModalContainer from "../containers/documents/document-download-signed-modal.container";
import BuildVersionAlert from "@/components/buildVersionAlert";
import OnboardingControllerContainer from "../containers/notifications/onboarding/controller.container";

import { refreshToken } from "../actions/credentials.actions";
import { fetchUserCompanies } from "../actions/company.actions";

import Routes from "../routes";
import { withRouter } from "../interfaces/router";

const cookies = new Cookies();

class App extends Component {
	state = {
		timer: null,
		tokenVaildMin: 120,
		checkTokenEveryMin: 1,
		isBrowserRefresh: false,
		userCompaniesFetched: false,
	};

	idleTimerRef = React.createRef();

	componentDidMount = () => {
		const timer = setInterval(
			this.tick,
			this.state.checkTokenEveryMin * 60 * 1000,
		);
		this.setState({ timer });
	};

	componentDidUpdate = (prevProps) => {
		const { user, userLanguage, fetchUserCompanies } = this.props;
		const { userCompaniesFetched } = this.state;

		if (prevProps.userLanguage !== userLanguage && userLanguage) {
			cookies.set("userLanguage", userLanguage, {
				path: "/",
				expires: Moment().add(1, "year").toDate(),
			});
		}

		if (prevProps.user && !user) {
			// Re-fetch user companies after a user has logged out
			this.setState({ userCompaniesFetched: false });
		}

		if (!user || userCompaniesFetched) return;

		fetchUserCompanies(user.get("id")).catch((err) => {
			console.error(err);
			this.setState({ userCompaniesFetched: false });
		});
		this.setState({ userCompaniesFetched: true });
	};

	tick = () => {
		if (this.idleTimerRef.current) {
			const idleTime =
				this.state.tokenVaildMin * 60 * 1000 -
				this.idleTimerRef.current.getRemainingTime();
			if (idleTime < this.state.checkTokenEveryMin * 60 * 1000) {
				this.props.refreshToken();
			}
		}
	};

	/**
	 * The render function
	 */
	render() {
		const {
			appMenusShow,
			location: { pathname },
		} = this.props;

		if (!this.props.authenticated) {
			//

			return <Routes />;
		}

		const showDangerBorders = cookies.get("showDangerBorders") === "true";

		return (
			<DropzoneFullscreenDetector>
				<BuildVersionAlert />
				<ScreenMessageContainer />
				<SimpleConfirmationModalContainer />
				<TaskMoveModalContainer />
				<ChatVisibilityControllerContainer />
				<OnboardingControllerContainer />
				<DocumentDownloadSignedModalContainer />

				<div className="base-layout">
					{showDangerBorders && (
						<div
							style={{
								position: "fixed",
								top: 0,
								left: 0,
								zIndex: 9999,
								width: "100vw",
								height: "100vh",
								pointerEvents: "none",
								boxShadow: "0 0 15px 15px rgba(255, 0, 0, 0.8) inset",
							}}
						/>
					)}
					{appMenusShow && <MainNav pathName={pathname} />}
					<div className="base-layout__body">
						<div className="base-layout__body__nav-bar">
							{appMenusShow && (
								<AppNavBar isCompanyPage={this.props.match.params.company} />
							)}
						</div>
						{/* <div style={{ padding: 10 }}>
							<Alert mode="warning" icon="faWrench">
								<Text
									color={"white"}
									tid="generic.scheduled_maintenance"
									bold={700}
									values={{
										from: moment(new Date("April 29, 2024 18:00")).format(
											"LLLL",
										),
										hours: 2,
									}}
								/>
							</Alert>
						</div> */}
						<div className="base-layout__body__content">
							<Routes />
						</div>
					</div>
					<ProcessingBar />
					<Notify />
					<IdleTimer
						ref={this.idleTimerRef}
						element={document}
						timeout={this.state.tokenVaildMin * 60 * 1000}
					></IdleTimer>
				</div>
			</DropzoneFullscreenDetector>
		);
	}
}

function mapStoreToProps(state) {
	return {
		authenticated: state.credentials.get("authenticated"),
		appMenusShow: state.notify.appMenusShow,
		messages: state.i18n.messages,
		userLanguage: state.user.getIn(["userObj", "siteSettings", "language"]),
		user: state.user.get("userObj"),
	};
}

const mapActionToProps = {
	refreshToken,
	fetchUserCompanies,
};

export default connect(mapStoreToProps, mapActionToProps)(withRouter(App));
