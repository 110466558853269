import React, { useContext, useEffect, useState } from "react";

import {
	MY_RECORDS_ADD_TRANSACTION_MODAL,
	MY_RECORDS_EDIT_TRANSACTION_MODAL,
	PERSONAL_ATTACHMENTS_MODAL,
	MY_RECORDS_CONFIGURE_PORTFOLIO_TABLE_DATA_MODAL,
	CAP_TABLE_READ_MORE_MODAL,
} from "../../../constants/modals";
import { useDispatch, useSelector } from "react-redux";
import AddTransactionModalContainer from "./add-transaction-modal.container";
import MyTransactionsGridContainer from "../../../containers/investments/my-records/my-transactions-grid.container";
import { Grid } from "@mui/material";
import {
	listManyPersonalAttachments,
	resetPersonalAttachments,
} from "../../../actions/personal-attachments.actions";
import { PersonalAttachments } from "./personal-attachments/PersonalAttachments";
import DarkPanel from "../../../mui-components/dark-panel/dark-panel";
import { InvestmentsContext } from "../investment.container";
import { openModal } from "../../../actions/modals.actions";
import { copyAllCompayTransactions } from "../../../actions/my-records.actions";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ConfigurePortfolioTableDataModal from "./modals/configure-portfolio-table-data-modal";
import { INVESTOR_TYPE_OF_OWNER_COMPANY } from "/shared/constants";
import { ReadMoreModal } from "../modals/read-more-modal";
import Link from "@mui/material/Link";

export default function InvestmentMyRecordsContainer() {
	const { selectedPersonalInvestment, selectedInvestment } =
		useContext(InvestmentsContext);
	const selectedPersonalInvestmentId = selectedPersonalInvestment.get("id");
	const isAddTransactionModalOpen = useSelector((state) => {
		return (
			state.modals.getIn(["activeModal", "name"]) ===
				MY_RECORDS_ADD_TRANSACTION_MODAL ||
			(state.modals.get("modalsInModal") &&
				state.modals
					.get("modalsInModal")
					.find(
						(modal) => modal.get("name") === MY_RECORDS_ADD_TRANSACTION_MODAL,
					)) ||
			state.modals.getIn(["activeModal", "name"]) ===
				MY_RECORDS_EDIT_TRANSACTION_MODAL
		);
	});

	const isAttachmentsModalOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) ===
			PERSONAL_ATTACHMENTS_MODAL,
	);
	const isReadMoreModalOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) === CAP_TABLE_READ_MORE_MODAL,
	);
	const isConfigurePortfolioTableDataModalOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) ===
			MY_RECORDS_CONFIGURE_PORTFOLIO_TABLE_DATA_MODAL,
	);

	const name = useSelector(
		(state) =>
			state.capTableDetails.get("aliasOfTheInvestedCompany") ||
			state.capTableDetails.getIn([
				"combinedSingleInvestmentAndAllTransactions",
				"investedInCompanyInformation",
				"name",
			]),
	);

	const [isDarkPanelTooltipShowing, setIsDarkPanelTooltipShowing] =
		useState(false);

	useEffect(() => {
		if (isAddTransactionModalOpen) {
			setIsDarkPanelTooltipShowing(false);
		}
	}, [isAddTransactionModalOpen]);

	const openDarkPanelTooltip = () => {
		setIsDarkPanelTooltipShowing(true);
	};

	const { t } = useTranslation();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(listManyPersonalAttachments("personalAttachments"));

		return () => dispatch(resetPersonalAttachments("personalAttachments"));
	}, []);

	useEffect(() => {
		const shouldAutoUpdate =
			selectedPersonalInvestment.get("autoSynchCompanyData") &&
			selectedInvestment;
		if (shouldAutoUpdate) {
			populateMyRecords();
		}
	}, [selectedPersonalInvestmentId]);

	const openTransactionModal = () => {
		dispatch(openModal(MY_RECORDS_ADD_TRANSACTION_MODAL));
	};

	const openReadMoreModal = () => {
		dispatch(openModal(CAP_TABLE_READ_MORE_MODAL));
	};

	const openConfigurePortfolioTableDataModal = () => {
		dispatch(openModal(MY_RECORDS_CONFIGURE_PORTFOLIO_TABLE_DATA_MODAL));
	};

	const populateMyRecords = () => {
		const selectedInvestmentId = selectedInvestment.get("id");
		const selectedPersonalInvestmentId = selectedPersonalInvestment.get("id");
		dispatch(
			copyAllCompayTransactions(
				selectedPersonalInvestmentId,
				selectedInvestmentId,
			),
		);
	};

	// const renderConfigureCard = !(
	// 	selectedPersonalInvestment.get('autoSynchCompanyData') === true ||
	// 	(selectedPersonalInvestment.get('isRegisteredInvonoCompany') === false &&
	// 		selectedPersonalInvestment.getIn(['personalInvestmentType']) === INVESTOR_TYPE_OF_OWNER_COMPANY)
	// )
	let renderConfigureCard =
		(selectedPersonalInvestment.get("isRegisteredInvonoCompany") === true &&
			selectedPersonalInvestment.get("autoSynchCompanyData") === false) ||
		(selectedPersonalInvestment.get("isRegisteredInvonoCompany") === false &&
			selectedPersonalInvestment.getIn(["personalInvestmentType", "type"]) ===
				INVESTOR_TYPE_OF_OWNER_COMPANY);
	const firstSecondaryCardWidth = renderConfigureCard ? 6 : 9;

	return (
		<>
			<Grid container spacing={2} pb={2}>
				<Grid
					item
					lg={
						selectedPersonalInvestment.get("transactions") &&
						selectedPersonalInvestment.get("transactions").size > 0
							? firstSecondaryCardWidth
							: 12
					}
					md={12}
				>
					<Card borderradius={"true"}>
						<CardContent>
							<Typography variant="body1">{name}</Typography>
							<Typography variant="body2">
								{/* <FormattedHTMLMessage id='investments.my_records.information_panel.body' /> */}

								<FormattedMessage
									id={"investments.my_records.information_panel.body"}
									// Helpscout solution
									//values={{
									//	link: <InfoModal article={HELPSCOUT_OWN_RECORDS_READ_MORE} />
									//}}
									values={{
										link: (
											<Link
												sx={{ pb: 0.3 }}
												onClick={openReadMoreModal}
												component={"button"}
												color={"positive.main"}
											>
												<FormattedMessage
													id={"investments.information_panel.link.read_more"}
												/>
											</Link>
										),
									}}
								/>
							</Typography>
						</CardContent>
					</Card>
				</Grid>

				{selectedPersonalInvestment.get("transactions") &&
					selectedPersonalInvestment.get("transactions").size > 0 && (
						<>
							{renderConfigureCard && (
								<Grid item lg={3} md={12}>
									<DarkPanel
										titleTid="investments.my_records.dark_panel.display_own_records.title"
										bodyTid="investments.my_records.dark_panel.display_own_records.paragraph"
										icon="faCog"
										onClick={openConfigurePortfolioTableDataModal}
										showNotificationBadge={selectedPersonalInvestment.get(
											"showInPortfolio",
										)}
										/*//todo only show after first manually added transaction*/
										tooltipTid={{
											id: "investments.my_records.dark_panel.display_own_records.tooltip",
											values: { companyName: name },
										}}
										onCloseTooltip={() => {
											setIsDarkPanelTooltipShowing(false);
										}}
										isTooltipOpen={isDarkPanelTooltipShowing}
									/>
								</Grid>
							)}

							<Grid item lg={3} md={12}>
								<DarkPanel
									titleTid="investments.my_records.dark_panel.add_transaction.title"
									icon="faPlus"
									onClick={openTransactionModal}
								/>
							</Grid>
						</>
					)}

				{!selectedPersonalInvestment.get("transactions") ||
					(selectedPersonalInvestment.get("transactions").size < 1 && (
						<Grid item lg={12} md={12}>
							<Card>
								<CardHeader
									variant="noBorder"
									title={t("investments.my_records.no_records_panel.title")}
								/>
								<CardContent>
									<Typography gutterBottom component="p" variant="body">
										{
											<FormattedHTMLMessage id="investments.my_records.no_records_panel.paragraph1" />
										}
									</Typography>
									<Typography gutterBottom component="p" variant="body">
										{
											<FormattedHTMLMessage id="investments.my_records.no_records_panel.paragraph2" />
										}
									</Typography>
								</CardContent>
								<CardActions
									sx={{ flexDirection: "column", alignItems: "flex-start" }}
								>
									<Box>
										{selectedInvestment && (
											<Button variant="primary" onClick={populateMyRecords}>
												{t("my_records.copy.company.investments")}
											</Button>
										)}

										<Button variant="default" onClick={openTransactionModal}>
											{t(
												"investments.my_records.no_records_panel.button.manually_add",
											)}
										</Button>
									</Box>
									{/*
									// This a helpscout solution that might be used.
									<InfoSidebar
										article={HELPSCOUT_OWN_RECORDS_MORE_HELP}
										link
										linkTid={t('investments.own_records.link.need_help')}
									/>*/}
								</CardActions>
							</Card>
						</Grid>
					))}

				<Grid item xs={12}>
					<MyTransactionsGridContainer />
				</Grid>
			</Grid>

			{isAddTransactionModalOpen && (
				<AddTransactionModalContainer onChangeCallback={openDarkPanelTooltip} />
			)}
			{isAttachmentsModalOpen && <PersonalAttachments />}
			{isReadMoreModalOpen && (
				<ReadMoreModal
					title={"investments.my_records.information.modal.title"}
					contentFile="my-records.txt"
				/>
			)}
			{isConfigurePortfolioTableDataModalOpen && (
				<ConfigurePortfolioTableDataModal />
			)}
		</>
	);
}
