import { useQuery } from "@tanstack/react-query";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import apiClient from "@/modules/request.module.v2";

/**
 * @param {object} queryOptions
 * @param {boolean} queryOptions.enabled
 * @return {object}
 */
export default (queryOptions) => {
	const companyId = useCompanyIdFromUrl();

	const query = useQuery({
		...queryOptions,
		queryKey: ["countInvestors", companyId],
		queryFn: () => {
			return apiClient.get("/shares/company/investors/count", {
				headers: {
					"x-company-id": companyId,
				},
			});
		},
	});

	return query;
};
