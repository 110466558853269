import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";
import {
	addErrorNotification,
	addInfoNotification,
} from "@/components/framework/notify";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";

export default (userCommId) => {
	const companyId = useCompanyIdFromUrl();
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ["duplicateUserComm", userCommId],
		mutationFn: () => {
			return apiClient.post(
				`notifications/user-comms/${userCommId}/duplicate`,
				{ headers: { "x-company-id": companyId } },
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries("userComms");

			addInfoNotification({
				tid: "success",
			});
		},
		onError: () => {
			addErrorNotification({ tid: "generic.unknown_error" });
		},
	});
};
