import { useQuery } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";

export default (companyIdFromCaller) => {
	const companyIdFromUrl = useCompanyIdFromUrl();
	const companyId = companyIdFromCaller || companyIdFromUrl; // This is here due to the navigation menu loading the company ID in cases where the user only has access to a single company

	return useQuery({
		enabled: !!companyId,
		queryKey: ["subscriptionHelper", companyId],
		queryFn: () => {
			return apiClient.get(`subscriptions/helper`, {
				params: {
					companyId,
				},
			});
		},
		staleTime: 1000 * 60 * 1, // 1 minute,
	});
};
