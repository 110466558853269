import React, { useEffect, useState } from "react";
import { Hint, Input } from "@/components/ui/input";
import { bool, func, object } from "prop-types";
import {
	validateEmail as validateEmailFunc,
	isRequired,
} from "../../../modules/validation.module";
import { useTranslation } from "react-i18next";
import DialogModal from "@/components/dialogModal";
import TagsSelect from "@/dumb-components/shared/tags-select/tags-select";
import useInvestmentContactsTags from "@/hooks/shares/useInvestmentContactsTags";
import { fromJS } from "immutable";

function AddPersonModal({
	isOpen,
	onSave,
	onCancel,
	person: initialPerson,
	showCategoryField = true,
	showTagsField = false,
}) {
	const [person, setPerson] = useState({});
	const [errors, setErrors] = useState({});
	const isEditing = Boolean(initialPerson);
	const { t } = useTranslation();

	const { data: contactsTagsQuery } = useInvestmentContactsTags({
		enabled: isOpen,
	});
	const contactsTagsOptions = contactsTagsQuery?.data || [];

	useEffect(() => {
		if (initialPerson) {
			setPerson(initialPerson);
		}

		return () => {};
	}, [initialPerson]);

	const onChange = (field, val) => {
		setPerson({ ...person, [field]: val });
	};

	const validateName = () => {
		const { name } = person;

		if (!isRequired(name)) {
			setErrors({
				...errors,
				name: "people_modal.add_person_modal.name.error.required",
			});
			return false;
		}

		return true;
	};

	const validateEmail = () => {
		const { email } = person;

		if (!isRequired(email)) {
			setErrors({
				...errors,
				email: "people_modal.add_person_modal.email.error.required",
			});
			return false;
		} else if (!validateEmailFunc(email)) {
			setErrors({
				...errors,
				email: "people_modal.add_person_modal.email.error.not_valid",
			});
			return false;
		}

		return true;
	};

	const validate = () => {
		setErrors({});
		return validateName() && validateEmail();
	};

	const _onSave = () => {
		if (validate()) {
			resetStates();
			onSave(person);
		}
	};

	const _onCancel = () => {
		resetStates();
		onCancel();
	};

	const resetStates = () => {
		setPerson({});
		setErrors({});
	};

	if (!isOpen) {
		return null;
	}

	return (
		<DialogModal
			open
			title={t(
				isEditing
					? "people_modal.add_person_modal.title.edit"
					: "people_modal.add_person_modal.title.create",
			)}
			size="sm"
			onCancel={_onCancel}
			onSubmit={_onSave}
			submitText={t("generic.modal.save_and_close")}
			bodyRenderer={() => (
				<div className="flex flex-1 flex-col gap-4">
					<div>
						<Input
							id="name"
							label={t("generic.name")}
							onChange={(evt) => onChange("name", evt.target.value)}
							value={person.name}
							error={t(errors.name)}
						/>
					</div>
					<div>
						<Input
							id="email"
							label={t("generic.email")}
							onChange={(evt) => onChange("email", evt.target.value)}
							value={person.email}
							error={t(errors.email)}
						/>
					</div>
					<div>
						<Input
							id="phone"
							label={t("people_modal.add_person.phone")}
							onChange={(evt) => onChange("phone", evt.target.value)}
							value={person.phone}
							error={t(errors.phone)}
						/>
					</div>
					{showCategoryField && (
						<div>
							<label htmlFor="tags">{t("people_modal.category")}</label>
							<Input
								id="tags"
								onChange={(evt) => onChange("tags", evt.target.value)}
								value={person.tags}
							/>
						</div>
					)}
					{showTagsField && (
						<div>
							<label htmlFor="tags">
								{t("people_modal.grid.headers.tags")}
							</label>
							<Hint>{t("people_modal.add_person_modal.tags.hint")}</Hint>
							<TagsSelect
								value={fromJS(
									person.tags?.map((tag) => ({ id: tag, name: tag })),
								)}
								options={fromJS(
									contactsTagsOptions?.map((tag) => ({ id: tag, name: tag })),
								)}
								onChange={(newVal) => onChange("tags", newVal.toJS())}
								onCreate={(newVal) => onChange("tags", newVal.toJS())}
							/>
						</div>
					)}
				</div>
			)}
		/>
	);
}

AddPersonModal.propTypes = {
	isOpen: bool,
	onSave: func.isRequired,
	onCancel: func.isRequired,
	person: object,
	showCategoryField: bool,
};

export { AddPersonModal };
