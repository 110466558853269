/* 
  This HOC is used to make components display tooltips based on their `title` prop.

  Usage:

	const MyComponent = <button />

	export default withTooltip()(MyComponent);

	// ...

	<MyComponent title="This is a tooltip" />
*/

import React from "react";

import {
	Tooltip,
	TooltipTrigger,
	TooltipContent,
	TooltipPortal,
} from "@/components/ui/tooltip";

const withTooltip = () => {
	return (Component) => {
		const ComponentWithTooltip = (props, ref) => {
			if (!props.title) {
				return <Component {...props} ref={ref} />;
			}

			const { title, ...rest } = props;

			return (
				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<div>
							<Component {...rest} />
						</div>
					</TooltipTrigger>
					<TooltipPortal>
						<TooltipContent>{title}</TooltipContent>
					</TooltipPortal>
				</Tooltip>
			);
		};
		ComponentWithTooltip.displayName = `withTooltip(${Component.displayName})`;

		return React.forwardRef(ComponentWithTooltip);
	};
};

export default withTooltip;
