import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import { DatePicker, TextField } from "mui-rff";
import Button from "../../../../mui-components/button/button";
import StepsModal from "../../../../mui-components/steps-modal/steps-modal";
import { FormGroup } from "../../../../mui-components/form-block/FromGroup";
import { ADVERT_SECTIONS } from "/shared/constants";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { isRequired } from "../../../../modules/validation.module";
import Tooltip from "../../../../mui-components/tooltip/tooltip";
import { bool, func, string } from "prop-types";
import {
	createAdvert,
	fetchAdvert,
	updateAdvert,
} from "../../../../actions/adverts.actions";
import Checkbox from "../../../../mui-components/checkbox/checkbox";
import styled from "styled-components";
import RichTextEditor from "../../../../dumb-components/shared/editorv2/rich-text-editor";
import { LogoSelector } from "./logo-selector/LogoSelector";
import { AdvertCardView } from "../../company-adverts/views/AdvertCardView";
import { CompanyAdvertsModal } from "../../company-adverts/CompanyAdvertsModal/CompanyAdvertsModal";
import IconButton from "../../../../mui-components/button/icon-button";
import { openAdvertPreviewPdf } from "../../../../services/adverts";
import useSubscriptionHelper from "../../../../hooks/subscriptions/useSubscriptionHelper";

const StyledTextarea = styled("div")((props) => ({
	width: "100%",

	"& .MuiFormControl-root": {
		height: `${props.height} !important`,
	},
	"& .MuiOutlinedInput-root": {
		height: `${props.height} !important`,
	},
}));

const StyledCounter = styled("div")`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
`;

const TOOLTIP_STATES = {
	noName: { title: "advertising.administration.modal.tooltip.missing_name" },
	noTagline: {
		title: "advertising.administration.modal.tooltip.missing_tagline",
	},
	noSummary: {
		title: "advertising.administration.modal.tooltip.missing_summary",
	},
	shortSummary: {
		title: "advertising.administration.modal.tooltip.short_summary",
	},
	longSummary: {
		title: "advertising.administration.modal.tooltip.long_summary",
	},
	noText: { title: "advertising.administration.modal.tooltip.missing_text" },
	shortText: { title: "advertising.administration.modal.tooltip.short_text" },
	noSection: {
		title: "advertising.administration.modal.tooltip.missing_section",
	},
	noDateStart: {
		title: "advertising.administration.modal.tooltip.missing_date",
	},
	noDuration: {
		title: "advertising.administration.modal.tooltip.missing_duration",
	},
	noContact: {
		title: "advertising.administration.modal.tooltip.missing_contact",
	},
	noPhone: { title: "advertising.administration.modal.tooltip.missing_phone" },
	longTagline: {
		title: "advertising.administration.modal.tooltip.long_tagline",
	},
	printPreviewAsPdf: {
		title: "advertising.administration.modal.tooltip.print_preview_as_pdf",
	},
	advertPublished: {
		title: "advertising.administration.modal.tooltip.advert_published",
	},
};

let ALLOWED_N_OF_CHARACTERS = 205;
const TAGLINE_ALLOWED_NUMBER_OF_CHARACTERS = 90;

function CreateAdvertModal({ isOpen, closeModal, advertId, preview }) {
	const [isPublished, setIsPublished] = useState(false);
	const [previewModalOpen, setPreviewModalOpen] = useState(false);
	const { t } = useTranslation();
	const company = useSelector((state) => state.company.company);

	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionsHelper = subscriptionHelperQuery?.data;

	const dispatch = useDispatch();
	const [initialValues, setInitialValues] = useState({
		logotype:
			company.image?.main?.filename ?? company.image?.thumbnail?.filename,
	});

	useEffect(() => {
		if (advertId) {
			dispatch(
				fetchAdvert(advertId, (error, response) => {
					if (error) {
						return;
					}

					const displayedInAllSections = Object.values(ADVERT_SECTIONS).every(
						(section) => response.sections.includes(section),
					);
					if (displayedInAllSections) {
						response.sections = "ALL";
					} else {
						response.sections = response.sections[0];
					}

					if (!response.logotype || response.logotype === "") {
						response.logotype =
							company.image?.main?.filename ??
							company.image?.thumbnail?.filename;
					}

					setInitialValues(response);
					setIsPublished(
						response.confirmationRequestSent && response?.controller?.active,
					);
				}),
			);
		}
	}, [advertId]);

	const validateFirstSlide = (values) => {
		const errors = {};

		if (!isRequired(values?.name)) {
			errors.name = t("validation.is_required");
		}

		if (!isRequired(values?.tagline)) {
			errors.tagline = t("validation.is_required");
		}

		if (!isRequired(values?.summary)) {
			errors.summary = t("validation.is_required");
		}

		if (values.summary && values.summary.length <= 8) {
			errors.summary = t(
				"advertising.administration.modal.tooltip.short_summary",
			);
		}

		if (values.summary && values.summary.length > ALLOWED_N_OF_CHARACTERS) {
			errors.summary = t(
				"advertising.administration.modal.tooltip.long_summary",
			);
		}

		if (
			values.tagline &&
			values.tagline.length > TAGLINE_ALLOWED_NUMBER_OF_CHARACTERS
		) {
			errors.tagline = t(TOOLTIP_STATES.longTagline.title);
		}

		return errors;
	};

	const validateSecondSlide = (values) => {
		const errors = {};

		if (!isRequired(values?.text)) {
			errors.text = t("validation.is_required");
		}

		if (values.text && values.text.length <= 8) {
			errors.text = t("advertising.administration.modal.tooltip.short_text");
		}

		return errors;
	};

	const validateThirdSlide = (values) => {
		const errors = {};

		if (!isRequired(values?.sections)) {
			errors.sections = t("validation.is_required");
		}

		if (!isRequired(values?.dateStart)) {
			errors.dateStart = t("validation.is_required");
		}

		if (!isRequired(values?.duration)) {
			errors.duration = t("validation.is_required");
		}

		if (!isRequired(values?.contact?.name)) {
			errors.contact = { name: t("validation.is_required") };
		}

		if (!isRequired(values?.contact?.phone)) {
			if (!errors.contact) {
				errors.contact = {};
			}

			errors.contact.phone = t("validation.is_required");
		}

		return errors;
	};

	const validate = (values) => {
		const errors = {
			...validateFirstSlide(values),
			...validateSecondSlide(values),
			...validateThirdSlide(values),
		};

		return errors;
	};

	const getTooltipActiveState = (errors, values) => {
		if (errors?.name) {
			return "noName";
		}
		if (values?.tagline?.length > TAGLINE_ALLOWED_NUMBER_OF_CHARACTERS) {
			return "longTagline";
		}
		if (errors?.tagline) {
			return "noTagline";
		}
		if (values?.summary?.length <= 8) {
			return "shortSummary";
		}
		if (values?.summary?.length > ALLOWED_N_OF_CHARACTERS) {
			return "longSummary";
		}
		if (!values?.summary) {
			return "noSummary";
		}
		if (!values?.text) {
			return "noText";
		}
		if (values?.text?.length <= 8) {
			return "shortText";
		}
		if (errors.sections) {
			return "noSection";
		}
		if (errors.dateStart) {
			return "noDateStart";
		}
		if (errors.duration) {
			return "noDuration";
		}
		if (!values?.contact) {
			return "noContact";
		}
		if (!values?.contact?.phone) {
			return "noPhone";
		}
	};

	const onSubmit = (values) => {
		if (values.sections === "ALL") {
			values.sections = Object.values(ADVERT_SECTIONS);
		} else {
			values.sections = [values.sections];
		}

		const callback = (error) => {
			if (error) {
				return;
			}

			closeModal();
		};

		if (!advertId) {
			dispatch(createAdvert(values, callback));
		} else {
			dispatch(updateAdvert(advertId, values, callback));
		}
	};

	const printPreview = async (formValues) => {
		await openAdvertPreviewPdf({
			name: formValues.name,
			tagline: formValues.tagline,
			summary: formValues.summary,
			text: formValues.text,
			logotype: formValues.logotype,
			companyId: company.id,
		});
	};

	const renderFirstSlide = () => {
		return (
			<Grid container spacing={8}>
				<Grid item lg={6}>
					<Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
						<TextField
							required
							name="name"
							label={t(
								"advertising.administration.create_advert_modal.input.advert_title.label",
							)}
						/>
						<Field name="logotype">
							{({ input }) => <LogoSelector {...input} />}
						</Field>
						<Typography variant="h4">{company.name}</Typography>

						<Field name={"tagline"}>
							{({ input }) => {
								let backspace = false;
								const remaining =
									TAGLINE_ALLOWED_NUMBER_OF_CHARACTERS - input.value?.length;

								document.addEventListener("keydown", ({ key }) => {
									if (key === "Backspace") {
										backspace = true;
									}
								});

								return (
									<Box>
										<StyledCounter>
											<Typography variant="body2">
												{t(
													"advertising.administration.create_advert_modal.max_characters",
													{
														maxLength: TAGLINE_ALLOWED_NUMBER_OF_CHARACTERS,
													},
												)}
											</Typography>
											<Typography variant="body2">
												{t(
													"advertising.administration.create_advert_modal.remaining_characters",
													{ remaining: remaining },
												)}
											</Typography>
										</StyledCounter>
										<TextField
											required
											name="tagline"
											multiline
											rows={2}
											value={input.value}
											onChange={({ target }) => {
												if (
													input.value?.length <
														TAGLINE_ALLOWED_NUMBER_OF_CHARACTERS ||
													backspace
												) {
													input.onChange(target.value);
												}
											}}
											label={t(
												"advertising.administration.create_advert_modal.input.advert_tag_line.label",
											)}
										/>
									</Box>
								);
							}}
						</Field>

						<Field name={"summary"}>
							{({ input }) => {
								let backspace = false;
								const remaining = ALLOWED_N_OF_CHARACTERS - input.value?.length;

								document.addEventListener("keydown", ({ key }) => {
									if (key === "Backspace") {
										backspace = true;
									}
								});

								return (
									<Box>
										<StyledCounter>
											<Typography variant="body2">
												{t(
													"advertising.administration.create_advert_modal.max_characters",
													{
														maxLength: ALLOWED_N_OF_CHARACTERS,
													},
												)}
											</Typography>
											<Typography variant="body2">
												{t(
													"advertising.administration.create_advert_modal.remaining_characters",
													{ remaining: remaining },
												)}
											</Typography>
										</StyledCounter>

										<StyledTextarea height="142px">
											<TextField
												required
												name="summary"
												multiline
												rows={6}
												value={input.value}
												onChange={({ target }) => {
													if (
														input.value?.length < ALLOWED_N_OF_CHARACTERS ||
														backspace
													) {
														input.onChange(target.value);
													}
												}}
												label={t(
													"advertising.administration.create_advert_modal.advert_overview.label",
												)}
											/>
										</StyledTextarea>
									</Box>
								);
							}}
						</Field>
					</Box>
				</Grid>
				<Grid item lg={6}>
					<Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
						<Typography variant="body2">
							{t(
								"advertising.administration.create_advert_modal.information_text.process.paragraph1",
							)}
						</Typography>
						<Typography variant="body2">
							{t(
								"advertising.administration.create_advert_modal.information_text.process.paragraph2",
							)}
						</Typography>
						<Typography variant="body2">
							{t(
								"advertising.administration.create_advert_modal.information_text.process.paragraph3",
							)}
						</Typography>
						<Typography variant="body2">
							{t(
								"advertising.administration.create_advert_modal.information_text.process.paragraph4",
							)}
						</Typography>
						<Typography variant="body2">
							{t(
								"advertising.administration.create_advert_modal.information_text.process.paragraph5",
							)}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		);
	};

	const renderSecondSlide = () => {
		return (
			<Grid container spacing={2} columnSpacing={8}>
				<Grid item lg={12}>
					<Typography variant="body2">
						{t(
							"advertising.administration.create_advert_modal.information_text.model",
						)}
					</Typography>
				</Grid>
				<Grid item lg={3} md={12}>
					<Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
						<Field name="logotype">
							{({ input }) => <LogoSelector {...input} disabledChangeButton />}
						</Field>
						<Typography variant="h4">{company.name}</Typography>
					</Box>
				</Grid>
				<Grid item lg={9} md={12}>
					<Field name={"text"}>
						{({ input }) => {
							return (
								<RichTextEditor
									label={t(
										"advertising.administration.create_advert_modal.advert_add_information.label",
									)}
									value={input.value}
									onChange={input.onChange}
									height="382px"
									required
								/>
							);
						}}
					</Field>
				</Grid>
			</Grid>
		);
	};

	const renderPreview = ({ formProps }) => {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
					backgroundColor: "#ecf0f5",
					overflow: "auto",
				}}
			>
				<Box sx={{ width: "542px", padding: "32px" }}>
					<AdvertCardView
						advert={{ ...formProps.values, company }}
						openAdvertModal={() => setPreviewModalOpen(true)}
						onFollow={() => {}}
						followBtnDisabled={false}
					/>
					{previewModalOpen && (
						<CompanyAdvertsModal
							advert={{ ...formProps.values, company }}
							closeModal={() => setPreviewModalOpen(false)}
						/>
					)}
				</Box>
			</Box>
		);
	};

	const renderThirdSlide = ({ formProps: values }) => {
		let startDate, endDate;

		const dateSet = values?.dateStart && values?.duration;
		if (dateSet) {
			startDate = values.dateStart.toLocaleString(DateTime.DATE_MED);
			endDate = values.dateStart
				.plus({ months: values.duration })
				.toLocaleString(DateTime.DATE_MED);
		}

		const getCheckboxLabel = () => {
			if (values.confirmationRequestSent === true) {
				return t(
					"advertising.administration.create_advert_modal.input.request.label.checked",
				);
			} else {
				return t(
					"advertising.administration.create_advert_modal.input.request.label.unchecked",
				);
			}
		};

		const periodOptions = [
			{
				tid: "advertising.administration.create_advert_modal.advert_period.select.menu_item1",
				value: 1,
			},
			{
				tid: "advertising.administration.create_advert_modal.advert_period.select.menu_item2",
				value: 2,
			},
			{
				tid: "advertising.administration.create_advert_modal.advert_period.select.menu_item3",
				value: 3,
			},
			{
				tid: "advertising.administration.create_advert_modal.advert_period.select.menu_item6",
				value: 6,
			},
			{
				tid: "advertising.administration.create_advert_modal.advert_period.select.menu_item12",
				value: 12,
			},
			{
				tid: "advertising.administration.create_advert_modal.advert_period.select.menu_item36",
				value: 36,
			},
		];

		const menuItems = [];
		if (subscriptionsHelper?.account === "enterprise_plus") {
			for (let i = 0; i < periodOptions.length; i++) {
				if (subscriptionsHelper?.interval === "month") {
					menuItems.push(
						<MenuItem key={i} value={periodOptions[i].value}>
							{t(periodOptions[i].tid)}
						</MenuItem>,
					);
					break;
				} else {
					if (i < 5) {
						menuItems.push(
							<MenuItem key={i} value={periodOptions[i].value}>
								{t(periodOptions[i].tid)}
							</MenuItem>,
						);
					} else {
						break;
					}
				}
			}
		}

		return (
			<Grid container spacing={2} columnSpacing={8}>
				<Grid item lg={6} md={12}>
					<Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
						<Tooltip
							title={TOOLTIP_STATES.advertPublished.title}
							hideTooltip={!isPublished}
						>
							<Box>
								<TextField
									select
									required
									name="sections"
									label={t(
										"advertising.administration.create_advert_modal.advert_section.select.label",
									)}
									SelectProps={{
										MenuProps: {
											transitionDuration: 0,
										},
									}}
									disabled={isPublished}
								>
									<MenuItem value={ADVERT_SECTIONS.DEALFLOW}>
										{t(
											"advertising.administration.create_advert_modal.advert_section.select.menu_item1",
										)}
									</MenuItem>
									<MenuItem value={ADVERT_SECTIONS.MY_PORTFOLIO}>
										{t(
											"advertising.administration.create_advert_modal.advert_section.select.menu_item2",
										)}
									</MenuItem>
									<MenuItem value="ALL">
										{t(
											"advertising.administration.create_advert_modal.advert_section.select.menu_item3",
										)}
									</MenuItem>
								</TextField>
							</Box>
						</Tooltip>
						<Box sx={{ display: "flex", gap: "16px" }}>
							<Tooltip
								title={TOOLTIP_STATES.advertPublished.title}
								hideTooltip={!isPublished}
							>
								<Box sx={{ flex: 1 }}>
									<DatePicker
										required
										name="dateStart"
										label={t(
											"advertising.administration.create_advert_modal.select.date.label",
										)}
										disabled={isPublished}
									/>
								</Box>
							</Tooltip>
							<Tooltip
								title={TOOLTIP_STATES.advertPublished.title}
								hideTooltip={!isPublished}
							>
								<Box sx={{ flex: 1 }}>
									<TextField
										required
										select
										name="duration"
										label={t(
											"advertising.administration.create_advert_modal.select.period.label",
										)}
										SelectProps={{
											MenuProps: {
												transitionDuration: 0,
											},
										}}
										disabled={isPublished}
									>
										{menuItems.length > 0
											? menuItems
											: periodOptions.map((option, index) => {
													return (
														<MenuItem key={index} value={option.value}>
															{t(option.tid)}
														</MenuItem>
													);
											  })}
									</TextField>
								</Box>
							</Tooltip>
						</Box>
						<Typography variant={"body2"}>
							{!dateSet &&
								t(
									"advertising.administration.create_advert_modal.information_text.date_range.date_not_set",
								)}
							{dateSet &&
								t(
									"advertising.administration.create_advert_modal.information_text.date_range.date_set",
									{
										startDate,
										endDate,
									},
								)}
						</Typography>
						<TextField
							required
							type="text"
							name="contact.name"
							label={t(
								"advertising.administration.create_advert_modal.input.contact.label",
							)}
						/>
						<TextField
							required
							type="tel"
							name="contact.phone"
							label={t(
								"advertising.administration.create_advert_modal.input.phone.label",
							)}
						/>
						{!isPublished ? (
							<FormGroup
								sx={{ "& .MuiFormControlLabel-root": { marginBottom: "0px" } }}
							>
								<Checkbox
									disableRipple
									sx={{ height: "20px" }}
									name="confirmationRequestSent"
									data={{ value: true, label: getCheckboxLabel() }}
								/>
								<Typography variant="body2">
									{t(
										"advertising.administration.create_advert_modal.input.request.information.paragraph1",
									)}
								</Typography>
								<Typography variant="body2">
									{t(
										"advertising.administration.create_advert_modal.input.request.information.paragraph2",
									)}
								</Typography>
							</FormGroup>
						) : (
							<Card variant="dark">
								<CardContent>
									<Typography variant={"body1"}>
										{t(
											"advertising.administration.create_advert_modal.dark_card.title",
										)}
									</Typography>
									<Typography variant={"body2"}>
										{t(
											"advertising.administration.create_advert_modal.dark_card.paragraph",
										)}
									</Typography>
								</CardContent>
							</Card>
						)}
					</Box>
				</Grid>
				<Grid item lg={6} md={12}>
					<Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
						<Typography variant="body2">
							{t(
								"advertising.administration.create_advert_modal.terms_n_conditions.paragraph4",
							)}
						</Typography>
						<Typography variant="body2">
							{t(
								"advertising.administration.create_advert_modal.terms_n_conditions.paragraph5",
							)}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		);
	};

	const steps = [
		{
			slots: {
				title: "advertising.administration.create_advert_modal.title",
				body: renderFirstSlide,
				footerRightActions: ({ changeStep, formProps: { values } }) => {
					const errors = validateFirstSlide(values);
					const buttonDisabled = Object.keys(errors).length > 0;
					return (
						<>
							<Tooltip
								states={TOOLTIP_STATES}
								activeState={
									buttonDisabled ? getTooltipActiveState(errors, values) : ""
								}
								hideTooltip={!buttonDisabled}
							>
								<Button
									variant="positive"
									onClick={() => changeStep(1)}
									disabled={buttonDisabled}
								>
									{t(
										"advertising.administration.create_advert_modal.button.next",
									)}
								</Button>
							</Tooltip>
							<Button onClick={closeModal}>
								{t(
									"advertising.administration.create_advert_modal.button.cancel",
								)}
							</Button>
						</>
					);
				},
			},
		},
		{
			slots: {
				title: "advertising.administration.create_advert_modal.title",
				body: renderSecondSlide,
				footerLeftActions: ({ changeStep }) => {
					return (
						<Button onClick={() => changeStep(0)}>
							{t(
								"advertising.administration.create_advert_modal.button.previous",
							)}
						</Button>
					);
				},
				footerRightActions: ({ changeStep, formProps: { values } }) => {
					const errors = validateSecondSlide(values);
					const buttonDisabled = Object.keys(errors).length > 0;

					return (
						<>
							<Tooltip
								states={TOOLTIP_STATES}
								activeState={
									buttonDisabled ? getTooltipActiveState(errors, values) : ""
								}
								hideTooltip={!buttonDisabled}
							>
								<Button
									variant="positive"
									onClick={() => changeStep(2)}
									disabled={buttonDisabled}
								>
									{t(
										"advertising.administration.create_advert_modal.button.next",
									)}
								</Button>
							</Tooltip>
							<Button onClick={closeModal}>
								{t(
									"advertising.administration.create_advert_modal.button.cancel",
								)}
							</Button>
						</>
					);
				},
			},
		},
		{
			slots: {
				header: (changeStep, activeStep, formProps) => (
					<DialogTitle component="div">
						<Box sx={{ display: "flex" }}>
							<Typography
								component="h2"
								variant="h6"
								sx={{ fontSize: "18px", flex: 1 }}
							>
								{t(
									"advertising.administration.create_advert_modal.preview.title",
								)}
							</Typography>
							<Box>
								<Tooltip title={TOOLTIP_STATES.printPreviewAsPdf.title}>
									<IconButton
										icon="faPrint"
										size="normal"
										noBorder
										onClick={() => printPreview(formProps.values)}
									/>
								</Tooltip>
							</Box>
						</Box>
					</DialogTitle>
				),
				body: renderPreview,
				footerLeftActions: !preview
					? ({ changeStep }) => {
							return (
								<Button onClick={() => changeStep(1)}>
									{t(
										"advertising.administration.create_advert_modal.button.previous",
									)}
								</Button>
							);
					  }
					: undefined,
				footerRightActions: ({ changeStep }) => {
					return (
						<>
							{!preview ? (
								<Button variant="positive" onClick={() => changeStep(3)}>
									{t(
										"advertising.administration.create_advert_modal.button.next",
									)}
								</Button>
							) : null}
							<Button onClick={closeModal}>
								{t(
									"advertising.administration.create_advert_modal.button.cancel",
								)}
							</Button>
						</>
					);
				},
			},
		},
		{
			slots: {
				title: "advertising.administration.create_advert_modal.title",
				body: renderThirdSlide,
				footerLeftActions: ({ changeStep }) => {
					return (
						<Button onClick={() => changeStep(2)}>
							{t(
								"advertising.administration.create_advert_modal.button.previous",
							)}
						</Button>
					);
				},
				footerRightActions: ({
					formProps: { values, handleSubmit, submitting, invalid },
				}) => {
					const submittingDisabled = submitting || invalid;
					const errors = validateThirdSlide(values);
					const someRequiredFieldsIncomplete = Object.keys(errors).length > 0;
					const buttonDisabled =
						submittingDisabled || someRequiredFieldsIncomplete;

					const getButtonTitle = () => {
						if (isPublished) {
							return t(
								"advertising.administration.create_advert_modal.button.save",
							);
						}
						if (values.confirmationRequestSent === true) {
							return t(
								"advertising.administration.create_advert_modal.button.save_and_request_to_publish",
							);
						} else {
							return t(
								"advertising.administration.create_advert_modal.button.save_as_draft",
							);
						}
					};

					return (
						<>
							<Tooltip
								states={TOOLTIP_STATES}
								activeState={
									buttonDisabled ? getTooltipActiveState(errors, values) : ""
								}
								hideTooltip={!submittingDisabled}
							>
								<Button
									variant="positive"
									onClick={handleSubmit}
									disabled={buttonDisabled}
								>
									{getButtonTitle()}
								</Button>
							</Tooltip>
							<Button onClick={closeModal}>
								{t(
									"advertising.administration.create_advert_modal.button.cancel",
								)}
							</Button>
						</>
					);
				},
			},
		},
	];

	if (advertId && !initialValues) {
		return null;
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={validate}
			initialValues={initialValues}
			render={(formProps) => {
				const { handleSubmit, values, submitting, pristine, invalid } =
					formProps;
				return (
					<form onSubmit={handleSubmit}>
						<StepsModal
							modalSize="xl"
							isOpen={isOpen}
							steps={steps}
							overrideIndex={preview ? 2 : undefined}
							formProps={{
								values,
								handleSubmit,
								submitting,
								pristine,
								invalid,
							}}
						/>
					</form>
				);
			}}
		/>
	);
}

CreateAdvertModal.propTypes = {
	isOpen: bool,
	closeModal: func,
	advertId: string,
	preview: bool,
};

export default CreateAdvertModal;
