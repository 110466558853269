import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";
import {
	addErrorNotification,
	addInfoNotification,
} from "@/components/framework/notify";

export default (companyId) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ["updateStripeCustomer", companyId],
		mutationFn: (customerData) => {
			return apiClient.put(
				`subscriptions/customers`,
				{ customer: customerData },
				{ params: { companyId } },
			);
		},
		onSuccess: (murationResponse, payload) => {
			queryClient.setQueryData(["subscription", companyId], murationResponse);

			// Refetch payment methods if they were updated
			if (payload?.invoice_settings?.default_payment_method) {
				queryClient.invalidateQueries(["paymentMethods", companyId]);
				addInfoNotification({
					tid: "subscriptions.notifications.success.default_payment_method_updated",
				});
			} else {
				addInfoNotification({
					tid: "subscriptions.notifications.success.subscription_updated",
				});
			}
		},
		onError: () => {
			addErrorNotification({ tid: "generic.unknown_error" });
		},
	});
};
