import React, { useState } from "react";
import { Eye, TriangleAlert, UserPlus, X } from "lucide-react";
import { Button } from "../ui/button";
import { ItemList, ItemListColumn, ItemListRow } from "../itemList";

import FilterPeopleModal from "@/components/filterPeople";
import EditorPreview from "@/components/tiptap-editor/editorPreview";

import useUpdateUserComm from "@/hooks/notifications/useUpdateUserComm";
import useUserComm from "@/hooks/notifications/useUserComm";
import usePagination from "@/hooks/usePagination";
import useDialogModal from "@/hooks/useDialogModal";
import useSendEmailJobPreviews from "@/hooks/notifications/useSendEmailJobPreviews";

import i18n from "@/i18n";

const { t } = i18n;

const UserCommRecipientList = ({ editor, userCommId }) => {
	const { confirm, dialogElement } = useDialogModal();

	const { data: userCommQuery } = useUserComm(userCommId);
	const userComm = userCommQuery?.data;
	const recipientIds = userComm?.recipients.map((r) => r.id) ?? [];

	const { mutateAsync: updateUserComm } = useUpdateUserComm(userCommId);

	const [showFilterPeopleModal, setShowFilterPeopleModal] = useState(false);
	const [previewRecord, setPreviewRecord] = useState();

	const { pageData: pagedRecipients, paginationElement } = usePagination(
		userComm.recipients,
		{ initialPageSize: 8, disablePageSizeSelector: true },
	);

	const { data: sendEmailJobPreviews } = useSendEmailJobPreviews(
		pagedRecipients,
		editor,
	);
	const sendEmailJobPreviewsByRecipientId = sendEmailJobPreviews.reduce(
		(acc, p) => ({ ...acc, [p.id]: p }),
		{},
	);

	const isLocked = userComm?.status !== "draft";

	return (
		<div className="flex flex-col gap-4">
			{showFilterPeopleModal && (
				<FilterPeopleModal
					excludeIds={recipientIds}
					onCancel={() => setShowFilterPeopleModal(false)}
					onSubmit={(people) => {
						const recipients = people.map((p) => ({
							id: p.id,
							type: "investor",
							email: p.email,
						}));

						updateUserComm({
							recipients: [...userComm.recipients, ...recipients],
						});
					}}
				/>
			)}
			{previewRecord && (
				<EditorPreview
					editor={editor}
					record={previewRecord}
					onCancel={() => setPreviewRecord(null)}
				/>
			)}
			<div className="flex gap-2 w-full">
				<Button
					className="flex-1"
					disabled={isLocked}
					variant="secondary"
					onClick={() => setShowFilterPeopleModal(true)}
				>
					<UserPlus /> {t("user_comms.actions.add_recipients.title")}
				</Button>
				<Button
					className="flex-1"
					variant="secondary"
					onClick={async () => {
						const confirmClear = await confirm();
						if (confirmClear) {
							updateUserComm({ recipients: [] });
						}
					}}
					disabled={isLocked || userComm.recipients.length === 0}
				>
					<X /> {t("user_comms.actions.clear_recipients.title")}
				</Button>
			</div>

			<ItemList columnSizing="1fr 60px">
				{pagedRecipients.map((r) => {
					const sendEmailJobPreview =
						sendEmailJobPreviewsByRecipientId[r.id] ?? {};

					return (
						<ItemListRow key={r.id}>
							<ItemListColumn.Avatar
								id={sendEmailJobPreview.avatarId}
								name={sendEmailJobPreview.name}
								extra={
									sendEmailJobPreview.email ?? (
										<div className="flex gap-1 items-center text-warning text-semibold">
											<TriangleAlert size={16} />{" "}
											{t("user_comms.email_missing")}
										</div>
									)
								}
								avatarType={r.ownerType}
							/>
							<ItemListColumn>
								<Button
									variant="ghost"
									size="sm"
									onClick={() => setPreviewRecord(sendEmailJobPreview.record)}
								>
									<Eye />
								</Button>
								<Button
									variant="ghost"
									size="sm"
									disabled={isLocked}
									onClick={() => {
										updateUserComm({
											recipients: userComm.recipients.filter(
												(rec) => rec.id !== r.id,
											),
										});
									}}
								>
									<X />
								</Button>
							</ItemListColumn>
						</ItemListRow>
					);
				})}
			</ItemList>
			{paginationElement}
			{dialogElement}
		</div>
	);
};

export default UserCommRecipientList;
