import React from "react";
import { cn } from "@/components/utils";

import { Card, CardContent } from "@/components/ui/card";
import { Trans, useTranslation } from "react-i18next";
import { Badge } from "@/components/ui/badge";

const SubscriptionAccountPlanCard = ({
	plan,
	isCurrent,
	isHighlighted,
	isRequired,
	isCurrentPlanBillingMonthly,
	onClick,
}) => {
	const { t } = useTranslation();

	return (
		<Card
			isHighlighted={isHighlighted}
			className="min-h-[70px]"
			onClick={onClick}
		>
			<CardContent
				className={cn("flex justify-between pt-4 gap-4 items-start", {
					"cursor-pointer": !!onClick,
				})}
			>
				{onClick && <input type="radio" checked={isHighlighted} readOnly />}
				<div className="flex flex-col flex-1 gap-2">
					<div className="flex gap-2 items-center text-lg font-bold">
						{t(`subscriptions.plans.company-account.${plan.metadata.plan}`)}
						{isCurrent && (
							<Badge variant="outline">
								{plan.id === "freeAccount"
									? t("people_modal.grid.investor_status.current")
									: `${t("people_modal.grid.investor_status.current")} - ${
											isCurrentPlanBillingMonthly
												? t("subscriptions.plans.monthly")
												: t("subscriptions.plans.yearly")
									  }`}
							</Badge>
						)}
						{isRequired && (
							<Badge variant="outline-orange">{t("generic.required")}</Badge>
						)}
					</div>
					<p>
						<Trans
							i18nKey={`subscriptions.plans.company-account.${plan.metadata.plan}.description`}
						/>
					</p>
				</div>
				<div className="align-right">
					<div className="text-lg font-bold">{`SEK ${Math.ceil(
						(plan.interval === "month" ? +plan.amount : +plan.amount / 12) /
							100,
					)}:-`}</div>
					{plan.id !== "freeAccount" && (
						<div>/ {t("subscriptions.interval.month")}</div>
					)}
				</div>
			</CardContent>
		</Card>
	);
};

export default SubscriptionAccountPlanCard;
