import React from "react";
import { TiptapEditorToolbar } from "@/components/tiptap-editor/toolbar";
import { EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { Placeholder } from "./tiptap-editor/extensions/placeholder";

const EmailEditor = ({ readOnly, onCreate, onUpdate, initialContent }) => {
	return (
		<div>
			<div className="border border-input">
				<EditorProvider
					editable={!readOnly}
					onCreate={onCreate}
					onUpdate={onUpdate}
					extensions={[
						StarterKit,
						Underline,
						Placeholder.configure({ readOnly }),
					]}
					content={initialContent}
					slotBefore={<TiptapEditorToolbar readOnly={readOnly} />}
				/>
			</div>
			<div className="py-2">
				<strong>Hint:</strong> Use forward slash{" "}
				<span className="px-2 bg-slate-100 font-bold border border-slate-200 rounded">
					/
				</span>{" "}
				to insert placeholder variables.
			</div>
		</div>
	);
};

export default EmailEditor;
