import React, { PureComponent } from "react";
import { func } from "prop-types";
import styled from "styled-components";
import { list, map } from "react-immutable-proptypes";
import { Map } from "immutable";

import Modal from "../../shared/modal/modal";
import Text from "../../shared/text/text";
import List from "../../shared/list/list";
import ListItem from "../../shared/list-item/list-item";
import { Switch } from "../../../components/ui/switch";
import Tooltip from "../../shared/tooltip/tooltip";
import FooterRightControls from "../../shared/modal/footer-right-controls";
import { TransparentButton } from "../../shared/button-v2";
import ProfileImage from "../../shared/profile-image/profile-image";

import { getUserName } from "../../../components/helpers/users";
import { isAttendingByProxy } from "../../../components/helpers/meeting.helper";
import ColoredContentWrapper from "../../shared/colored-content-wrapper/colored-content-wrapper";
import Icon from "../../shared/icon/icon";

const StyledList = styled(List)`
	display: flex;
	margin-top: ${(props) => props.theme.spacing[[4]]};
	margin-bottom: ${(props) => props.theme.spacing[[5]]};
`;

const StyledListItem = styled(ListItem)`
	display: flex;
	align-items: center;
	padding-right: ${(props) => props.theme.spacing[3]};
	border-top: 1px solid ${(props) => props.theme.colors.border};
	margin-bottom: 0;

	:last-child {
		border-bottom: 1px solid ${(props) => props.theme.colors.border};
	}
`;

const StyledUserImageWrapper = styled.div`
	min-width: 50px;
	height: 100%;
	margin-right: ${(props) => props.theme.spacing[4]};
`;

const NameWrapper = styled.div`
	display: flex;
	flex: 1;
`;

const SwitchWrapper = styled.div`
	display: flex;
`;

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[5]};
`;

const StyledIconWrapper = styled.div`
	padding-bottom: ${(props) => props.theme.spacing[3]};
`;

export default class SelectModeratorModal extends PureComponent {
	state = {
		footerTid: "generic.form.close",
	};

	static propTypes = {
		teamMembers: map,
		moderatorsIds: list,
		moderatorsLoading: map,
		usersCache: map,
		proxies: map,
		onChange: func,
		onClose: func,
	};

	footerRightCloseComponent = () => {
		const { onClose } = this.props;
		const { footerTid } = this.state;

		return (
			<FooterRightControls>
				<TransparentButton
					tid={footerTid}
					textColor="midGrey"
					onClick={onClose}
				/>
			</FooterRightControls>
		);
	};

	getProfileImage = (userId) => {
		const { usersCache } = this.props;
		return usersCache.getIn([userId, "image", "filename"]);
	};

	renderAlertComponent = () => {
		const { moderatorsIds } = this.props;
		const hasModerators = moderatorsIds.size > 0;
		let firstRowTid;
		let secondRowTid;

		if (hasModerators) {
			firstRowTid = "meetings.standard.manage_moderators.has_mods.row_1";
			secondRowTid = "meetings.standard.manage_moderators.has_mods.row_2";
		} else {
			firstRowTid = "meetings.standard.manage_moderators.no_mods.row_1";
			secondRowTid = "meetings.standard.manage_moderators.no_mods.row_2";
		}

		return (
			<StyledColoredContentWrapper type="secondary">
				<StyledIconWrapper>
					<Icon
						icon="faExclamationTriangle"
						size="normal"
						color="yellowMid"
						type="solid"
					/>
				</StyledIconWrapper>
				<Text tid={firstRowTid} />
				<Text tid={secondRowTid} />
			</StyledColoredContentWrapper>
		);
	};

	_onChange = (userId, shouldBeModerator) => {
		const { onChange } = this.props;
		onChange(userId, shouldBeModerator);
		this.setState({ footerTid: "save_and_close" });
	};

	renderListItem = (member, index) => {
		const { usersCache, proxies, moderatorsLoading, moderatorsIds } =
			this.props;
		const userId = member.get("userId");
		const name = getUserName(member, Map(), usersCache);
		const isModerator = moderatorsIds.includes(userId);
		const attendingByProxy = isAttendingByProxy(userId, proxies);
		const isLoading = moderatorsLoading.get(userId);
		const profileImage = this.getProfileImage(userId);

		return (
			<StyledListItem key={index}>
				<StyledUserImageWrapper>
					<ProfileImage
						image={
							profileImage
								? `/api/users/public/images/${profileImage}-80x80?userId=${userId}`
								: null
						}
					/>
				</StyledUserImageWrapper>

				<NameWrapper>{name}</NameWrapper>

				<SwitchWrapper>
					<Tooltip
						active={attendingByProxy}
						tid="meetings.agm.manage_attendee.moderator.tooltip.toggleswitch.disabled_if_attending_by_proxy"
						delayShow="instant"
						maxWidth="250px"
						place="left"
					>
						<Switch
							checked={isModerator}
							onCheckedChange={this._onChange.bind(null, userId)}
							disabled={isLoading || attendingByProxy}
						/>
					</Tooltip>
				</SwitchWrapper>
			</StyledListItem>
		);
	};

	render = () => {
		const { teamMembers } = this.props;

		return (
			<Modal
				isOpen={true}
				hSize="md"
				title="meetings.standard.manage_moderators.modal.title"
				footerComponent={this.footerRightCloseComponent()}
			>
				{this.renderAlertComponent()}
				<StyledList>
					{" "}
					{teamMembers.map(this.renderListItem).toList()}
				</StyledList>
			</Modal>
		);
	};
}
