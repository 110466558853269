import React from "react";
import { useDispatch, useSelector } from "react-redux";

import GenericInfoPanel from "../../../dumb-components/company/comapny-dashboard/generic-info-panel/generic-info-panel";
import GenericInfoListItem from "../../../dumb-components/company/comapny-dashboard/generic-info-panel/generic-info-list-item";
import RemindUsersModalContainer from "../../notify-by-templates/modals/users/remind-users-modal.container";
import { openModal } from "../../../actions/modals.actions";
import { INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL } from "../../../constants/modals";
import { getHasAccessToSection } from "../../../components/helpers/resources.helper";

import { RESOURCE_USER_MANAGEMENT } from "/shared/constants";
import { Users } from "lucide-react";
import useCountInvestors from "@/hooks/shares/useCountInvestors";

const TeamMembersPanelContainer = () => {
	const { isLoading, data } = useCountInvestors();
	const counter = data ?? {};

	const menuCompanyResources = useSelector(
		(store) => store.menu.companyResources,
	);
	const hasAccessToUserManagement = getHasAccessToSection(
		menuCompanyResources,
		RESOURCE_USER_MANAGEMENT,
	);

	const hasUnregisteredUsers = parseInt(counter.unregisteredUsers) > 0;
	const showRemindLink = hasAccessToUserManagement && hasUnregisteredUsers;

	const dispatch = useDispatch();

	const remindUnregisteredUsers = () => {
		dispatch(openModal(INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL));
	};

	return (
		<>
			<GenericInfoPanel
				icon={<Users />}
				title="company.dashboard.team_members_panel.title"
				isLoading={isLoading}
			>
				<GenericInfoListItem
					label="company.dashboard.team_members.panel.total_num_company_members"
					value={counter.totalNumOfUsers}
				/>
				<GenericInfoListItem
					label="company.dashboard.team_members.panel.num_of_reg_members"
					value={counter.registeredUsers}
				/>
				<GenericInfoListItem
					label="company.dashboard.team_members.panel.num_of_unreg_members"
					linkText="company.dashboard.team_members.panel.num_of_unreg_members.link_text"
					onLinkClick={remindUnregisteredUsers}
					showLink={showRemindLink}
					value={counter.unregisteredUsers}
				/>
			</GenericInfoPanel>
			<RemindUsersModalContainer />
		</>
	);
};

export default TeamMembersPanelContainer;
