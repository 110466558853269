import {
	ADD_NEW_EMISSION,
	DELETE_EMISSION_MARKETING_FILE,
	SET_ALL_EMISSIONS,
	SET_EMISSION_BASIC_SHARE_DATA,
	SET_EMISSION_CLOSURE_REPORT_FILE_INFO,
	SET_EMISSION_DOCUMENT_MAP,
	SET_EMISSION_INVITEE_NOTES,
	SET_EMISSION_INVITEES,
	SET_EMISSION_MARKETING_DIAGRAM_PERMISSIONS,
	SET_EMISSION_MARKETING_DIAGRAM_WHEN_TO_SHARE,
	SET_EMISSION_MARKETING_FILE_PERMISSIONS,
	SET_EMISSION_MARKETING_FILES,
	SET_EMISSION_NUM_OF_INVITED_DEALFLOWS,
	SET_EMISSION_SHARE_TYPES,
	SET_EMISSION_STATISTICS,
	SET_EMISSION_SUBMISSION_FORM_OPTIONS,
	SET_EMISSION_SUBMISSIONS,
	SET_EMISSION_SUBSCRIPTION_SLIP,
	SET_SINGLE_EMISSION,
	SET_PREPARATION_ERROR,
	SET_EMISSION_MARKETING_DEALFLOW_DISPLAY_STATUS,
	SET_ANY_ACTIVE_EMISSION,
} from "./types";
import emissions from "../services/emissions";
import { addErrorNotification, addInfoNotification } from "./notify.actions";
import uuid from "uuid";
import { DateTime } from "luxon";

export const getAllEmissions = (companyId) => async (dispatch) => {
	const allEmissions = await emissions.getAllEmissions(companyId);

	dispatch({
		type: SET_ALL_EMISSIONS,
		payload: { allEmissions },
	});
};

export const anyActiveEmissions = (companyId) => async (dispatch) => {
	const anyActiveEmissions = await emissions.anyActiveEmissions(companyId);

	dispatch({
		type: SET_ANY_ACTIVE_EMISSION,
		payload: { anyActiveEmissions },
	});

	return anyActiveEmissions;
};

export const generatePdfFromEmission =
	(emission, calculations) => async (_, getState) => {
		await emissions.generatePdf(
			{
				emission,
				...calculations,
			},
			getState().i18n.language,
		);
	};

export const generateBoardSuggestionPdf =
	(emission, calculations) => async (_, getState) => {
		await emissions.generateBoardSuggestionPdf(
			{
				emission,
				...calculations,
			},
			getState().i18n.language,
		);
	};

export const saveEmission = (emission) => async (dispatch) => {
	try {
		const start = emission.subscriptionStartDate;
		const end = emission.subscriptionEndDate;
		const payment = emission.paymentDueDate;
		const proRata = emission.proRataDueDate;

		const updatedEmission = await emissions.save({
			...emission,
			subscriptionStartDate: start
				? typeof start === "string"
					? start
					: start.toUTC().toISO()
				: null,
			subscriptionEndDate: end
				? typeof end === "string"
					? end
					: end.toUTC().toISO()
				: null,
			paymentDueDate: payment
				? typeof payment === "string"
					? payment
					: payment.toUTC().toISO()
				: null,
			proRataDueDate: proRata
				? typeof proRata === "string"
					? proRata
					: proRata.toUTC().toISO()
				: null,
			createdDate:
				typeof emission.createdDate === "string"
					? emission.createdDate
					: emission.createdDate.toUTC().toISO(),
		});

		dispatch({
			type: SET_SINGLE_EMISSION,
			payload: { emission: updatedEmission },
		});

		// eslint-disable-next-line no-empty
	} catch {
		// ignore server validation error.
		// this is handled by the form.
	}
};

export const getSingleEmission =
	(companyId, emissionId) => async (dispatch) => {
		const [emission] = await Promise.all([
			emissions.get(companyId, emissionId),
			dispatch(getShareTypes(companyId)),
			dispatch(getBasicShareData(companyId)),
			dispatch(getDocumentMap(companyId, emissionId)),
		]);

		dispatch({
			type: SET_SINGLE_EMISSION,
			payload: { emission },
		});
	};

const getShareTypes = (companyId) => async (dispatch) => {
	const shareTypes = await emissions.shareTypes(companyId);

	dispatch({
		type: SET_EMISSION_SHARE_TYPES,
		payload: { shareTypes },
	});
};

const getBasicShareData = (companyId) => async (dispatch) => {
	const basicShareData = await emissions.basicShareData(companyId);

	dispatch({
		type: SET_EMISSION_BASIC_SHARE_DATA,
		payload: { basicShareData },
	});
};
const getDocumentMap = (companyId, emissionId) => async (dispatch) => {
	const documentMap = await emissions.documentMap(companyId, emissionId);

	dispatch({
		type: SET_EMISSION_DOCUMENT_MAP,
		payload: { documentMap },
	});
};

export const createEmission =
	(companyId, emission, onEmissionCreated) => async (dispatch) => {
		try {
			const newEmission = await emissions.createEmission(companyId, emission);

			await dispatch({
				type: ADD_NEW_EMISSION,
				payload: { emission: newEmission },
			});
			await dispatch({
				type: SET_SINGLE_EMISSION,
				payload: { emission: newEmission },
			});
			if (typeof onEmissionCreated === "function") {
				onEmissionCreated(newEmission.id);
			}
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
			throw e;
		}
	};

export const renameEmission =
	(companyId, emissionId, nameObj) => async (dispatch) => {
		try {
			const renamedEmission = await emissions.renameEmission(
				companyId,
				emissionId,
				nameObj,
			);

			dispatch({
				type: SET_SINGLE_EMISSION,
				payload: { emission: renamedEmission },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
			throw e;
		}
	};

export const sendEmissionCancellationEmail =
	(companyId, emissionId, subject, template) => async (dispatch, getState) => {
		try {
			await emissions.sendEmissionCancellationEmail(
				companyId,
				emissionId,
				subject,
				template,
				getState().i18n.language,
			);
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const cancelEmission = (companyId, emissionId) => async (dispatch) => {
	try {
		await emissions.cancelEmission(companyId, emissionId);
		dispatch(getAllEmissions(companyId));
	} catch (e) {
		dispatch(addErrorNotification({ text: e.message }));
	}
};

export const permanentlyCancelEmission =
	(companyId, emissionId) => async (dispatch) => {
		try {
			await emissions.cancelEmission(companyId, emissionId);
			dispatch(getAllEmissions(companyId));
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const uploadDocument =
	(companyId, emissionId, fileKey, file) => async (dispatch) => {
		try {
			await emissions.uploadDocument(companyId, emissionId, fileKey, file);
			await dispatch(getDocumentMap(companyId, emissionId));
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const deleteDocument =
	(companyId, emissionId, fileKey) => async (dispatch) => {
		try {
			await emissions.deleteDocument(companyId, emissionId, fileKey);
			await dispatch(getDocumentMap(companyId, emissionId));
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const getMarketingFiles =
	(companyId, emissionId) => async (dispatch) => {
		try {
			const files = await emissions.getMarketingFiles(companyId, emissionId);
			dispatch({
				type: SET_EMISSION_MARKETING_FILES,
				payload: { files },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const setMarketingFilePermissions =
	(companyId, emissionId, fileId, permissions) => async (dispatch) => {
		try {
			await emissions.setMarketingFilePermissions(
				companyId,
				emissionId,
				fileId,
				permissions,
			);
			dispatch({
				type: SET_EMISSION_MARKETING_FILE_PERMISSIONS,
				payload: { fileId, permissions },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const deleteMarketingFile =
	(companyId, emissionId, fileId) => async (dispatch) => {
		try {
			await emissions.deleteMarketingFile(companyId, emissionId, fileId);
			dispatch({
				type: DELETE_EMISSION_MARKETING_FILE,
				payload: { fileId },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const uploadMarketingFiles =
	(companyId, emissionId, files, callback) => async (dispatch) => {
		try {
			await Promise.all(
				files.map((f) =>
					emissions.uploadMarketingFile(companyId, emissionId, f),
				),
			);
			await dispatch(getMarketingFiles(companyId, emissionId));
			callback && callback();
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const getInvitees = (companyId, emissionId) => async (dispatch) => {
	try {
		const invitees = await emissions.getInvitees(companyId, emissionId);
		dispatch({
			type: SET_EMISSION_INVITEES,
			payload: { invitees },
		});
	} catch (e) {
		dispatch(addErrorNotification({ text: e.message }));
	}
};

export const deleteInvitee =
	(companyId, emissionId, inviteeId) => async (dispatch, getState) => {
		try {
			await emissions.deleteInvitee(companyId, emissionId, inviteeId);

			dispatch({
				type: SET_EMISSION_INVITEES,
				payload: {
					invitees: getState().emissions.invitees.filter(
						(i) => i.id !== inviteeId,
					),
				},
			});

			if (getState().emissions.invitees.length === 0) {
				await dispatch(getSingleEmission(companyId, emissionId));
			}
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const getNumOfDealflows =
	(companyId, emissionId) => async (dispatch) => {
		try {
			const numOfDealflows = await emissions.getNumOfDealflows(
				companyId,
				emissionId,
			);
			dispatch({
				type: SET_EMISSION_NUM_OF_INVITED_DEALFLOWS,
				payload: numOfDealflows,
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const getNotes =
	(companyId, emissionId, inviteeId) => async (dispatch) => {
		try {
			const notes = await emissions.getNotes(companyId, emissionId, inviteeId);
			dispatch({
				type: SET_EMISSION_INVITEE_NOTES,
				payload: { notes, inviteeId },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const createNote =
	(companyId, emissionId, inviteeId, noteText) =>
	async (dispatch, getState) => {
		try {
			const notes = getState().emissions.notes.raw;
			const note = {
				text: noteText,
				id: uuid(),
				date: DateTime.now().toUTC().toISO(),
				important: false,
				completed: false,
			};
			const newNotes = [...notes, note];
			await emissions.updateNotes(companyId, emissionId, inviteeId, newNotes);
			dispatch({
				type: SET_EMISSION_INVITEE_NOTES,
				payload: { notes: newNotes, inviteeId },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const updateNote =
	(companyId, emissionId, inviteeId, newNote) => async (dispatch, getState) => {
		try {
			const notes = getState().emissions.notes.raw;
			const newNotes = notes.map((n) => (n.id === newNote.id ? newNote : n));
			await emissions.updateNotes(companyId, emissionId, inviteeId, newNotes);
			dispatch({
				type: SET_EMISSION_INVITEE_NOTES,
				payload: { notes: newNotes, inviteeId },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const deleteNote =
	(companyId, emissionId, inviteeId, noteToDeleteId) =>
	async (dispatch, getState) => {
		try {
			const notes = getState().emissions.notes.raw;
			const remainingNotes = notes.filter((n) => n.id !== noteToDeleteId);
			await emissions.updateNotes(
				companyId,
				emissionId,
				inviteeId,
				remainingNotes,
			);
			dispatch({
				type: SET_EMISSION_INVITEE_NOTES,
				payload: { notes: remainingNotes, inviteeId },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const updateInterested =
	(companyId, emissionId, inviteeId, interested) => async (dispatch) => {
		try {
			await emissions.updateInterested(
				companyId,
				emissionId,
				inviteeId,
				interested,
			);
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const setDisplayStatusInUserDealflow =
	(displayInUserDealflow) => async (dispatch) => {
		try {
			await dispatch({
				type: SET_EMISSION_MARKETING_DEALFLOW_DISPLAY_STATUS,
				payload: { displayInUserDealflow },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const sendInvestorInvite =
	(companyId, emissionId, invitees, template) => async (dispatch, getState) => {
		try {
			await emissions.sendInvestorInvite(
				companyId,
				emissionId,
				invitees,
				template,
				getState().i18n.language,
			);
			await dispatch(getInvitees(companyId, emissionId));
			await dispatch(getSingleEmission(companyId, emissionId));
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const sendDealflowInvite =
	(companyId, orgNumber, emissionId, typeOfInvitees, template) =>
	async (dispatch, getState) => {
		try {
			await emissions.sendDealflowInvite(
				companyId,
				orgNumber,
				emissionId,
				typeOfInvitees,
				template,
				getState().i18n.language,
			);
			dispatch(getNumOfDealflows(companyId, emissionId));
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const sendInvitationReminder =
	(companyId, emissionId, inviteeId) => async (dispatch, getState) => {
		try {
			await emissions.sendInvitationReminder(
				companyId,
				emissionId,
				inviteeId,
				getState().i18n.language,
			);
			dispatch(
				addInfoNotification({
					tid: "emissions.invitation.notifications.reminder_sent",
				}),
			);

			dispatch(getInvitees(companyId, emissionId)); // Refresh invitees
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const sendSettlementNotes =
	(companyId, emissionId, closureId, subject, template) =>
	async (dispatch, getState) => {
		try {
			await emissions.sendSettlementNotes(
				companyId,
				emissionId,
				closureId,
				subject,
				template,
				getState().i18n.language,
			);
			await dispatch(getSingleEmission(companyId, emissionId));
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const sendReceipts =
	(
		companyId,
		emissionId,
		closureId,
		subject,
		template,
		investmentIdDocIdList,
	) =>
	async (dispatch, getState) => {
		try {
			await emissions.sendReceipts(
				companyId,
				emissionId,
				closureId,
				subject,
				template,
				investmentIdDocIdList,
				getState().i18n.language,
			);
			await dispatch(getSingleEmission(companyId, emissionId));
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};
export const getSubscriptionSlip =
	(companyId, emissionId) => async (dispatch) => {
		try {
			let subscriptionSlip = await emissions.getSubscriptionSlip(
				companyId,
				emissionId,
			);

			if (!subscriptionSlip.texts_sv) {
				subscriptionSlip = {
					...subscriptionSlip,
					texts_sv: subscriptionSlip.texts,
					texts_en: subscriptionSlip.texts,
				};
			}

			dispatch({
				type: SET_EMISSION_SUBSCRIPTION_SLIP,
				payload: { subscriptionSlip },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const updateSubscriptionSlip =
	(companyId, emissionId, data) => async (dispatch) => {
		try {
			const subscriptionSlip = await emissions.updateSubscriptionSlip(
				companyId,
				emissionId,
				data,
			);
			dispatch({
				type: SET_EMISSION_SUBSCRIPTION_SLIP,
				payload: { subscriptionSlip },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const updateSubscriptionSlipPaymentInfo =
	(companyId, emissionId, data) => async (dispatch) => {
		try {
			await emissions.updateSubscriptionSlipPaymentInfo(
				companyId,
				emissionId,
				data,
			);
			const subscriptionSlip = await emissions.getSubscriptionSlip(
				companyId,
				emissionId,
			);
			await dispatch(getSingleEmission(companyId, emissionId));
			dispatch({
				type: SET_EMISSION_SUBSCRIPTION_SLIP,
				payload: { subscriptionSlip },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const getSubmissions = (companyId, emissionId) => async (dispatch) => {
	try {
		const submissions = await emissions.getSubmissions(companyId, emissionId);
		dispatch({
			type: SET_EMISSION_SUBMISSIONS,
			payload: { submissions },
		});
	} catch (e) {
		dispatch(addErrorNotification({ text: e.message }));
	}
};

export const getSubmissionFormOptions =
	(companyId, emissionId) => async (dispatch) => {
		try {
			const [insuranceOptions, depositoryOptions] = await Promise.all([
				emissions.getInsuranceOptions(companyId, emissionId),
				emissions.getDepositoryOptions(companyId, emissionId),
			]);
			dispatch({
				type: SET_EMISSION_SUBMISSION_FORM_OPTIONS,
				payload: {
					submissionFormOptions: {
						insuranceOptions,
						depositoryOptions,
					},
				},
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const updateSubmission =
	(companyId, emissionId, inviteeId, submission, callback) =>
	async (dispatch, getState) => {
		try {
			await emissions.updateSubmission(
				companyId,
				emissionId,
				inviteeId,
				submission,
				getState().i18n.language,
			);
			callback && callback();
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
			throw e;
		}
	};

export const releaseSubmission =
	(companyId, emissionId, inviteeId) => async (dispatch, getState) => {
		try {
			await emissions.releaseSubmission(companyId, emissionId, inviteeId);
			dispatch({
				type: SET_EMISSION_SUBMISSIONS,
				payload: {
					submissions: getState().emissions.submissions.filter(
						(s) => s.inviteeId !== inviteeId,
					),
				},
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
			throw e;
		}
	};

export const getEmissionStatistics =
	(companyId, emissionId) => async (dispatch) => {
		try {
			const statistics = await emissions.getEmissionStatistics(
				companyId,
				emissionId,
			);
			dispatch({
				type: SET_EMISSION_STATISTICS,
				payload: { statistics },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const setMarketingDiagramPermissions =
	(permissions) => async (dispatch) => {
		try {
			await dispatch({
				type: SET_EMISSION_MARKETING_DIAGRAM_PERMISSIONS,
				payload: { permissions },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const updateMarketingDiagramWhenToShare =
	(settings) => async (dispatch) => {
		try {
			settings = { ...settings, value: settings.value };
			await dispatch({
				type: SET_EMISSION_MARKETING_DIAGRAM_WHEN_TO_SHARE,
				payload: { settings },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const updateAllocationForSubmission =
	(companyId, emissionId, allocation) => async (dispatch, getState) => {
		try {
			await emissions.updateAllocationForSubmission(
				companyId,
				emissionId,
				allocation.inviteeId,
				allocation.allocation,
			);

			const updatedSubmissions = getState().emissions.submissions.map((s) => {
				if (s.inviteeId === allocation.inviteeId) {
					return {
						...s,
						allocation: { ...s.allocation, allocation: allocation.allocation },
					};
				}
				return s;
			});

			dispatch({
				type: SET_EMISSION_SUBMISSIONS,
				payload: { submissions: updatedSubmissions },
			});
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const createInvestmentForInvitee =
	(companyId, emissionId, inviteeId, responseText) => async (dispatch) => {
		try {
			await emissions.createInvestmentForInvitee(
				companyId,
				emissionId,
				inviteeId,
			);
			await dispatch(getSubmissions(companyId, emissionId));
			dispatch(addInfoNotification({ text: responseText }));
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const createEmissionClosure =
	(companyId, emissionId, closeType) => async (dispatch, getState) => {
		try {
			await emissions.createEmissionClosure(
				companyId,
				emissionId,
				getState().emissions.current.closures?.length + 1 || 1,
				closeType,
				getState().emissions.submissions,
			);
			await dispatch(getSubmissions(companyId, emissionId));
			await dispatch(getSingleEmission(companyId, emissionId));
		} catch (e) {
			dispatch(addErrorNotification({ text: e.message }));
		}
	};

export const updateShareRegister =
	(companyId, emissionId) => async (dispatch) => {
		const updatedEmission = await emissions.updateShareRegisterForLatestClosure(
			companyId,
			emissionId,
		);

		dispatch({
			type: SET_SINGLE_EMISSION,
			payload: { emission: updatedEmission },
		});
	};

export const deleteSettlementNotesForClosure =
	(companyId, emissionId, closureId) => async (dispatch) => {
		await emissions.deleteAllSettlementNotesForClosureClosure(
			companyId,
			emissionId,
			closureId,
		);
		await dispatch(getSingleEmission(companyId, emissionId));
	};

export const generateReceipts =
	(emissionId, companyId, inviteeId, closureId) => async (dispatch) => {
		await emissions.generateReceipt(
			emissionId,
			companyId,
			inviteeId,
			closureId,
		);
		await dispatch(getSingleEmission(companyId, emissionId));
	};

export const deleteReceipts =
	(emissionId, companyId, inviteeId, closureId) => async (dispatch) => {
		await emissions.deleteReceipt(emissionId, companyId, inviteeId, closureId);
		await dispatch(getSingleEmission(companyId, emissionId));
	};

export const updateClosureStatus =
	(companyId, emissionId, status) => async (dispatch) => {
		await emissions.updateClosureStatus(companyId, emissionId, status);
		await dispatch(getSingleEmission(companyId, emissionId));
	};

export const fetchClosureReportFileBasicInfo =
	(companyId, emissionId, documentId) => async (dispatch) => {
		const info = await emissions.getBasicDocumentInformation(
			companyId,
			emissionId,
			documentId,
		);

		dispatch({
			type: SET_EMISSION_CLOSURE_REPORT_FILE_INFO,
			payload: { info },
		});
	};

export const closeEmission = (companyId, emissionId) => async (dispatch) => {
	await emissions.closeEmission(companyId, emissionId);
	await dispatch(getSingleEmission(companyId, emissionId));
};

export const registerPartialClosure =
	(companyId, emissionId, closureId) => async (dispatch) => {
		await emissions.registerPartialClosure(companyId, emissionId, closureId);
		await dispatch(getSingleEmission(companyId, emissionId));
	};

export const removeClosure =
	(companyId, emissionId, closureId) => async (dispatch) => {
		await emissions.removeClosure(companyId, emissionId, closureId);
		await dispatch(getSingleEmission(companyId, emissionId));
	};

export const updateContactPerson =
	(companyId, emissionId, inviteeId, contactPersonId) => async () => {
		await emissions.updateContactPerson(
			companyId,
			emissionId,
			inviteeId,
			contactPersonId,
		);
	};

export const setPreparationError = (hasError) => async (dispatch) => {
	dispatch({
		type: SET_PREPARATION_ERROR,
		payload: { hasError },
	});
};

export const updateEmissionStatus =
	(companyId, emissionId, status, callback) => async (dispatch) => {
		const emission = await emissions.updateEmissionStatus(
			companyId,
			emissionId,
			status,
		);

		dispatch({
			type: SET_SINGLE_EMISSION,
			payload: { emission: emission },
		});
		callback && callback();
	};
